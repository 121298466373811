import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "./strore";
import { taskIdLogged } from "src/constants/constants";
import axios from "axios";
import { api } from "../constants/api";
import { userTaskAuthSuccess, userWorkerAuthSuccess } from "src/helpers/metric";
import httpErrorHandler from "./httpErrorHandler";
import { setTokenTask, setTokenWorker, clearToken } from "./authSlice";

export interface logState {
  lastIndex: number;
  messages: Message[];
  autoLogin: {
    isLoading: boolean;
    isWorker: boolean | null;
    isTask: boolean | null;
    fail: boolean | null;
    redirectTo: null | number;
    utm: null | string;
    openResponse: boolean;
    chat: {
      channelUrl: string;
      id: number;
      taskIdSendbird: string;
      tutorIdSendbird: string;
    } | null;
  };
  eventError: boolean;
}

export interface IAutoLogin {
  isLoading: boolean;
  isWorker: boolean | null;
  isTask: boolean | null;
  fail: boolean | null;
  redirectTo: null | number;
  utm: null | string;
  openResponse: boolean;
  chat: {
    channelUrl: string;
    id: number;
    taskIdSendbird: string;
    tutorIdSendbird: string;
  } | null;
}

interface Message {
  id: number;
  type: "success" | "warn" | "error";
  message: string;
  logIn?: boolean;
}

const initialState: logState = {
  lastIndex: 0,
  messages: [],
  autoLogin: {
    isLoading: false,
    isWorker: null,
    isTask: null,
    fail: null,
    redirectTo: null,
    utm: null,
    openResponse: false,
    chat: null,
  },
  eventError: false,
};

export const logSlice = createSlice({
  name: "log",
  initialState,
  reducers: {
    addMessage: (state: logState, { payload }) => {
      if (state.messages.map((m) => m.message).includes(payload.message)) {
        return;
      }

      state.messages = [
        ...state.messages,
        {
          id: state.lastIndex,
          ...payload,
        },
      ];
      state.lastIndex += 1;
    },
    removeMessage: (state: logState, { payload }) => {
      state.messages = state.messages.filter((msg) => msg.id !== payload);
    },

    handleAutoLogin: (state: logState) => {
      /* TODO: should be initialState? */
      state.autoLogin = {
        isLoading: false,
        isWorker: null,
        isTask: null,
        fail: null,
        redirectTo: null,
        utm: null,
        openResponse: false,
        chat: null,
      };
      state.autoLogin.isLoading = true;
    },
    handleAutoLoginSuccess: (state: logState, { payload }) => {
      state.autoLogin.isLoading = false;
      if (payload.whom === "task") {
        state.autoLogin.isTask = true;
        state.autoLogin.openResponse = !!payload.response;
      }
      if (payload.whom === "tutor") {
        state.autoLogin.isWorker = true;
      }
      state.autoLogin.redirectTo = payload.redirect;
      state.autoLogin.utm = payload.utm;
      state.autoLogin.chat = payload.chat;
    },
    handleAutoLoginFailure: (state: logState) => {
      state.autoLogin.isLoading = false;
      state.autoLogin.fail = true;
    },

    resetAutologin: (state: logState) => {
      state.autoLogin = initialState.autoLogin;
    },

    eventErrorOpen: (state: logState) => {
      state.eventError = true;
    },
    eventErrorClose: (state: logState) => {
      state.eventError = false;
    },
  },
});

export const {
  addMessage,
  removeMessage,
  handleAutoLogin,
  handleAutoLoginSuccess,
  handleAutoLoginFailure,
  resetAutologin,
  eventErrorOpen,
  eventErrorClose,
} = logSlice.actions;
export const logSelector = (state: RootState) => state.log;

export default logSlice.reducer;

export const sendAutoLogin =
  (code: string) => async (dispatch: AppDispatch) => {
    dispatch(clearToken());
    dispatch(handleAutoLogin());

    try {
      const { data } = await axios.post(`${api}/autologin/`, { code });

      if (data.auth) {
        if (data.whom === "task") {
          dispatch(setTokenTask(data.auth.token));
          localStorage.setItem(taskIdLogged, data.task);

          dispatch(
            handleAutoLoginSuccess({
              redirect: data.task,
              whom: data.whom,
              response: data.responses,
              utm: data.utm,
              chat: data.chat,
            })
          );
          userTaskAuthSuccess(data.task);
        } else if (data.whom === "tutor") {
          dispatch(setTokenWorker(data.auth.token));

          dispatch(
            handleAutoLoginSuccess({
              redirect: data.task,
              whom: data.whom,
              utm: data.utm,
              chat: data.chat,
            })
          );
          userWorkerAuthSuccess(data.idWorker);
        }
      } else {
        handleAutoLoginFailure();

        if (data.errors && typeof data.errors === "string") {
          dispatch(addMessage({ type: "error", message: data.errors }));
        }
      }
    } catch (error) {
      httpErrorHandler(error, dispatch);
      dispatch(handleAutoLoginFailure());
    }
  };
