import { FC, useEffect, useState } from "react";
import css from "./Dropdown.module.scss";
import cn from "classnames";
import Flag from "../Flag/Flag";
import { DropdownProps } from "./DropdownTypes";
import { CountryCode } from "libphonenumber-js";

const Dropdown: FC<DropdownProps> = ({
  show,
  names,
  codes,
  selectCountry,
  className,
  ...props
}) => {
  const [shouldRender, setRender] = useState<boolean>(show);
  const [filtersCodes, setFiltersCodes] = useState<CountryCode[]>(codes);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const handleAnimationEnd = () => {
    if (!show) {
      setRender(false);
      setFiltersCodes(codes);
    }
  };

  const handleSearch = (value: string) => {
    if (!value) {
      setFiltersCodes(codes);
      return;
    }

    setFiltersCodes(
      codes.filter((c) => {
        return names[c].toLowerCase().indexOf(value.toLowerCase()) !== -1;
      })
    );
  };

  if (!shouldRender) return <></>;

  return (
    <div
      className={cn(className, css.dropdown, {
        [css.dropdown_show]: show,
        [css.dropdown_hide]: !show,
      })}
      {...props}
    >
      <div
        className={css.dropdown__wrapper}
        onAnimationEnd={handleAnimationEnd}
      >
        <div className={css.dropdown__body}>
          <input
            type="text"
            onChange={(event) => handleSearch(event.target.value)}
            className={css.dropdown__search}
            placeholder="Buscar..."
          />
          {filtersCodes.length ? (
            filtersCodes.map((code) => (
              <div
                onClick={() => selectCountry(code)}
                className={css.dropdown__item}
                key={code}
              >
                <Flag code={code} className={css.dropdown__icon} />
                <span className={css.dropdown__text}>{names[code]}</span>
              </div>
            ))
          ) : (
            <p className={css.dropdown__empty}>No hay coincidencia</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
