import css from "./Chat.styles.module.scss";
import cn from "classnames";

import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "src/store/authSlice";
import {
  createChat,
  getWorkerAuthorized,
  getWorkerUnauthorized,
  resetCreateChannel,
  resetWorkerClient,
  resetWorkers,
  setChannelFirstMessage,
  stripeResponceReset,
  taskSelector,
} from "src/store/taskSlice";
import Preloader from "src/components/Preloader/Preloader";
import { Routes } from "src/constants/routes";
import {
  setVisibleFooter,
  setVisibleHeaderToCatalog,
} from "src/store/layoutSlice";
import Header from "./components/Header/Header";
import Pay from "./components/Pay/Pay";

import FakeChat from "./components/FakeChat/FakeChat";
import RealChat from "src/components/Chat/Chat";
import Registration from "./components/Registration/Registration";
import {
  clientCreateChat,
  clientOpenChat,
  ecommerce,
  naChatSendMessage,
  naOpenChat,
} from "src/helpers/metric";
import {
  eventErrorOpen,
  logSelector,
  resetAutologin,
} from "src/store/logSlice";
import { stripeResponceStatuses } from "src/types/Task";
import { refundInfoSelector } from "src/store/createNewTaskSlice";
import { IMessage } from "src/types/Chat";

const Chat = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { workerId } = useParams<{ workerId: string }>();
  const { isAuthTask, isAuthWorker } = useSelector(authSelector);
  const {
    task,
    channel,
    worker,
    workerNotFound,
    isLoading,
    isLoaded,
    stripeResponce,
    error,
  } = useSelector(taskSelector);

  const [openRegistration, setOpenRegistration] = useState<boolean>(false);
  const { autoLogin } = useSelector(logSelector);
  const { shownPopup, shownBanner } = useSelector(refundInfoSelector);
  const [fakeSendMessage, setFakeSendMessage] = useState<IMessage | null>(null);
  const [initialValueMessage, setInitialValueMessage] = useState<string>(() => {
    if (!channel.firstMessage || channel.firstMessage.workerId !== workerId) {
      return "";
    }

    return channel.firstMessage.message;
  });
  const [disabled, setDisabled] = useState<boolean>(task?.status.id === 5);

  const isBannerShow = shownPopup && !shownBanner && !isAuthWorker;

  useEffect(() => {
    if (isAuthWorker) return;

    if (isAuthTask) {
      clientOpenChat();
    } else {
      naOpenChat();
    }
  }, [isAuthTask, isAuthWorker]);

  useEffect(() => {
    dispatch(setVisibleFooter(false));
    dispatch(setVisibleHeaderToCatalog(true));

    if (!isAuthTask) {
      dispatch(getWorkerUnauthorized(workerId));
    } else {
      dispatch(getWorkerAuthorized(workerId));
    }

    return () => {
      dispatch(resetWorkers());
      dispatch(resetWorkerClient());
      dispatch(resetCreateChannel());
      dispatch(stripeResponceReset());
      dispatch(setVisibleFooter(true));
      dispatch(setVisibleHeaderToCatalog(false));
    };
  }, [dispatch, isAuthTask, workerId]);

  useEffect(() => {
    if (channel.isLoaded && worker) {
      ecommerce("add", {
        id: worker.id,
        name: `${worker.firstName} ${worker.lastName} ${worker.lastNameTwo}`,
        sex: worker.sex,
        category: worker.range,
        price: worker.consultation.costConsultation,
      });
    }
  }, [channel, worker]);

  useEffect(() => {
    if (channel.error) {
      setDisabled(channel.error);
    }
  }, [channel.error]);

  useEffect(() => {
    if (autoLogin.chat?.channelUrl) {
      dispatch(resetAutologin());
    }
  }, [autoLogin, dispatch]);

  // Обработка ошибок
  useEffect(() => {
    if (!isLoaded || error?.code === 401) return;

    if (
      workerNotFound ||
      channel.error ||
      stripeResponce.status === stripeResponceStatuses.FAILURE
    ) {
      dispatch(eventErrorOpen());
      history.push(Routes.client.create.intro);
    }
  }, [
    dispatch,
    history,
    isLoaded,
    workerNotFound,
    channel.error,
    error,
    stripeResponce.status,
  ]);

  useEffect(() => {
    if (worker && stripeResponce.status === stripeResponceStatuses.SUCCESS) {
      ecommerce("purchase", {
        id: worker.id,
        name: `${worker.firstName} ${worker.lastName} ${worker.lastNameTwo}`,
        sex: worker.sex,
        category: worker.range,
        price: worker.consultation.costConsultation,
      });
    }
  }, [dispatch, history, stripeResponce.status, worker]);

  const handleSendFakeMessage = (message: string) => {
    dispatch(
      setChannelFirstMessage({
        workerId,
        message,
      })
    );
    setInitialValueMessage(message || "");

    setFakeSendMessage((oldMessage) =>
      !oldMessage
        ? {
            createdAt: Date.now(),
            data: null,
            isCurrentUser: true,
            isReading: false,
            message,
            messageId: 1,
            sender: { userId: "1" },
            sendingStatus: "pending",
            type: "user",
          }
        : { ...oldMessage }
    );

    if (isAuthTask) {
      clientCreateChat();
      dispatch(createChat(Number(workerId)));
    } else {
      naChatSendMessage();
      setOpenRegistration(true);
    }
  };

  const handleCloseRegistration = () => {
    setOpenRegistration(false);
    setFakeSendMessage(null);
  };

  if (isLoading || !worker) {
    return <Preloader />;
  }

  return (
    <div
      className={cn(css.chat, {
        [css.chat_withBanner]: isBannerShow,
      })}
    >
      <div className={css.chat__header}>
        <div className="container">
          <Header
            worker={worker}
            showSubtitle={!worker.channelUrl}
            showPrivacyPolicy={!!worker.channelUrl}
            workerLogged={isAuthWorker}
            taskLogged={isAuthTask}
          />

          {!!worker.channelUrl && (
            <Pay
              className={css.chat__pay}
              disabled={task?.status.id === 5}
              worker={worker}
              stripeResponce={stripeResponce}
            />
          )}
        </div>
      </div>

      <div className={cn("container", css.chat__dialog)}>
        {!worker.channelUrl ? (
          <FakeChat
            nickname={`${worker.firstName} ${worker.lastName} ${worker.lastNameTwo}`}
            message={worker.textFirstMessagePsychologists}
            sendMessage={fakeSendMessage}
            disabled={disabled}
            onSendMessage={handleSendFakeMessage}
            initialValue={!openRegistration ? initialValueMessage : ""}
            isAuth={isAuthTask}
          />
        ) : (
          <RealChat
            channelUrl={worker.channelUrl}
            disabled={task?.status.id === 5}
            className={css.client__chat}
            disableAnimateFirstUserMessage={channel.isLoaded}
          />
        )}
      </div>

      {openRegistration && (
        <Registration
          workerId={workerId}
          onClose={handleCloseRegistration}
          workerName={worker.firstName}
        />
      )}
    </div>
  );
};

export default Chat;
