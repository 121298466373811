import { FC, useEffect, useState } from "react";
import Modal from "src/components/Modal/Modal";
import { MessageMoney } from "src/assets/graphIcons";
import { useDispatch } from "react-redux";
import { hideRefoundPopup } from "src/store/createNewTaskSlice";
import { naPopupRefound } from "src/helpers/metric";

const Refund: FC = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (!open) return null;

  return (
    <Modal
      active={true}
      setActive={() => {
        naPopupRefound();
        dispatch(hideRefoundPopup());
        setOpen(false);
      }}
      type={"info"}
      modalConfig={{
        icon: <MessageMoney />,
        title:
          "te devolvemos el dinero si no quedas satisfecho con tu 1a sesión",
        desc: "Confiamos plenamente en los psicólogos que recomendamos",
        submitText: "¡Genial!",
      }}
    />
  );
};

export default Refund;
