export interface IAboutTask {
  additional_info?: string;
  age: number;
  forWhom: string;
  isFirstTherapy: 0 | 1 | null;
  sex: number;
  specialization: string;
}

export interface Task extends IAboutTask {
  id: string;
  datetime: string;
  idSendbird: string;
  name: string;
  phone: string;
  channelUrl: string;
  paid: number;
  paidDateTime: string | null;
  paidDateTimeInt: number | null;
  smsAlert: boolean;
  status: {
    id: number;
    name: string;
    front_name: string;
    description: string;
  };
}

export enum stripeResponceStatuses {
  SUCCESS = "success",
  FAILURE = "failure",
}

export interface IFilters {
  forWhomId: string;
  specializationId: string;
  sex: string;
}

export interface ICardWorkerReviews {
  clientName: string;
  date: string;
  grade: number;
  review: string;
}

export interface ICardWorkerCat {
  frontText: string;
  id: number;
  order: number;
  tutors: ICardWorker[];
}

export interface ICardWorker {
  id: number;
  collegiate_number: string | null;
  costConsultation: number;
  durationConsultation: number;
  experience: number;
  firstName: string;
  lastName: string;
  lastNameTwo: string;
  patrName: null;
  photoPath: string;
  photoPathSquare: string | null;
  methodsOfConsultation: { id: number; name: string }[];
  grade: number | null;
  sex: number;
  reviews: {
    count: number;
    data: ICardWorkerReviews[];
  };
  paid?: boolean;
  chat?: {
    channelUrl: string;
    taskIdSendbird: string;
    tutorIdSendbird: string;
  };
}
