import { Switch, Route, Redirect } from "react-router-dom";
import { Routes } from "src/constants/routes";

import { useSelector } from "react-redux";
import { authSelector } from "src/store/authSlice";

import Main from "src/pages/Main/Main";

/*import TaskCreateIntro from "src/pages/Task/Create/Intro/Intro";
import TaskCreateFilters from "src/pages/Task/Create/Filters/Filters";*/
import TaskCreateVerification from "src/pages/Client/Create/Verification/Verification";
import TaskLogin from "src/pages/Client/TaskLogin/TaskLogin";
import TaskWaiting from "src/pages/Client/Waiting/Waiting";
import WorkerList from "src/pages/Client/WorkerList/WorkerList";
import Worker from "src/pages/Client/Worker/Worker";

import WorkerRegister from "src/pages/Worker/Register/Main/Main";
import WorkerRegisterForm from "src/pages/Worker/Register/Form/Form";
import WorkerRegisterVerification from "src/pages/Worker/Register/Verification/Verification";
import WorkerLogin from "src/pages/Worker/WorkerLogin/WorkerLogin";
import WorkerTasks from "src/pages/Worker/TasksList/TasksList";
import WorkerTask from "src/pages/Worker/Chat/Chat";
import Profile from "src/pages/Worker/Profile/Profile";
import Onboarding from "./pages/Worker/Register/Onboarding/Onboarding";
import Questionnaire from "./pages/Worker/Register/Questionnaire/Questionnaire";
import Callback from "./pages/Worker/Register/Callback/Callback";
import LastStep from "./pages/Worker/Register/LastStep/LastStep";
import Interview from "./pages/Interview/Interview";
import Chat from "./pages/Client/Chat/Chat";

const PrivateRoute: React.FC<{
  children: any;
  isWorker?: boolean;
  isTask?: boolean;
  [key: string]: any;
}> = ({ children, path, isWorker, isTask, ...rest }) => {
  const { isAuthWorker, isAuthTask } = useSelector(authSelector);

  let loginPath = Routes.home;
  if (isWorker) loginPath = Routes.worker.login;
  if (isTask) loginPath = Routes.client.login;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        (isAuthWorker && isWorker) ||
        (isAuthTask && isTask) ||
        (isWorker && isTask && (isAuthWorker || isAuthTask)) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: loginPath,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const Routing = () => {
  return (
    <Switch>
      <Route path={Routes.home} component={Main} exact />
      <Route path={Routes.interview} component={Interview} exact />
      <Route path={`${Routes.home}:autologin`} component={Main} exact />

      {/* TASK */}
      <Route path={Routes.client.login} component={TaskLogin} />
      <Route
        path={`${Routes.client.show}/:workerId`}
        component={Worker}
        exact
      />
      <Route path={Routes.client.create.intro} component={WorkerList} exact />

      <Route
        path={Routes.client.create.verification}
        component={TaskCreateVerification}
        exact
      />

      <Route path={`${Routes.client.chat}/:workerId`} component={Chat} exact />

      <PrivateRoute isTask path={`${Routes.client.waiting}`}>
        <TaskWaiting />
      </PrivateRoute>

      {/*<Route path={Routes.client.create.intro} component={WorkerList} exact />
      <Route
        path={Routes.client.create.intro}
        component={TaskCreateIntro}
        exact
      />
      <Route
        path={Routes.client.create.filter}
        component={TaskCreateFilters}
        exact
      />*/}

      {/* TUTOR */}
      <Route path={Routes.worker.login} component={WorkerLogin} />

      <Route
        exact
        path={Routes.worker.register.main}
        component={WorkerRegister}
      />

      <Route
        exact
        path={Routes.worker.register.form}
        component={WorkerRegisterForm}
      />

      <Route
        exact
        path={Routes.worker.register.verification}
        component={WorkerRegisterVerification}
      />

      <PrivateRoute isWorker path={Routes.worker.register.onboarding}>
        <Onboarding />
      </PrivateRoute>
      <PrivateRoute
        isWorker
        path={`${Routes.worker.register.questionnaire}/:step`}
      >
        <Questionnaire />
      </PrivateRoute>
      <PrivateRoute isWorker path={Routes.worker.register.callback}>
        <Callback />
      </PrivateRoute>
      <PrivateRoute isWorker path={Routes.worker.register.final}>
        <LastStep />
      </PrivateRoute>
      <PrivateRoute isWorker path={Routes.worker.taskList}>
        <WorkerTasks />
      </PrivateRoute>
      <PrivateRoute isWorker path={`${Routes.worker.task}/:taskId`}>
        <WorkerTask />
      </PrivateRoute>
      <PrivateRoute isWorker path={`${Routes.worker.profile}`}>
        <Profile />
      </PrivateRoute>

      {/* <Route
        exact
        path={Routes.worker.register.onboarding}
        component={Onboarding}
      /> */}

      {/* Регистрация.Анкета */}
      {/* <Route
        exact
        path={`${Routes.worker.register.questionnaire}/:step`}
        component={Questionnaire}
      /> */}

      {/* Регистрация.Обратная связь */}
      {/* <Route
        exact
        path={Routes.worker.register.callback}
        component={Callback}
      /> */}

      {/* Регистрация.Финал */}
      {/* <Route
        exact
        path={Routes.worker.register.final}
        component={LastStep}
      /> */}

      {/* REDIRECT*/}
      <Route path={"*"} component={Main} />
    </Switch>
  );
};

export default Routing;
