import { IMessage, IUser, SendBirdMessagesTypes } from "src/types/Chat";

export const reduceMessages = (
  channel: SendBird.GroupChannel,
  messageList: SendBirdMessagesTypes[],
  currentUserID: string
): IMessage[] => {
  return messageList.reduce<IMessage[]>((_newListMessasge, message) => {
    if (message.messageType === "user") {
      const readingMembers = channel
        .getReadMembers(message, true)
        .filter((member) => member.userId !== currentUserID);

      return [
        ..._newListMessasge,
        conversionMessage({
          message,
          isCurrentUser: currentUserID === message.sender?.userId,
          isReading: !!readingMembers.length,
        }),
      ];
    }

    if (message.messageType === "admin") {
      return [
        ..._newListMessasge,
        {
          type: message.messageType,
          createdAt: message.createdAt,
          data: message.data,
          isCurrentUser: false,
          isReading: true,
          message: message.message,
          messageId: message.messageId,
          sendingStatus: message.sendingStatus,
          sender: "admin",
        },
      ];
    }

    return _newListMessasge;
  }, []);
};

export const conversionMessage = ({
  message,
  isCurrentUser,
  isReading,
}: {
  message: SendBird.UserMessage | SendBird.AdminMessage;
  isCurrentUser: boolean;
  isReading: boolean;
}): IMessage => {
  return {
    type: message.messageType,
    createdAt: message.createdAt,
    data: message.data,
    isCurrentUser: isCurrentUser,
    isReading: isReading,
    message: message.message,
    messageId: message.messageId,
    sendingStatus: message.sendingStatus,
    sender:
      message.messageType === "user"
        ? conversionToUser(message.sender)
        : "admin",
  };
};

export const conversionToUser = (
  user: SendBird.User | SendBird.Member | null
): IUser => ({
  userId: user?.userId,
  nickname: user?.nickname,
  plainProfileUrl: user?.plainProfileUrl,
});

const check = (root: HTMLDivElement, sp: number) => {
  const run = () => {
    const offset = 20;
    const scrollTop = root.scrollTop;

    if (scrollTop - offset >= sp && scrollTop + offset <= sp) {
      setTimeout(() => {
        root.scrollTo({ top: sp, behavior: "smooth" });
        run();
      }, 10);
    }
  };

  run();
};

export const scrollTo = (
  scrollingParent: HTMLDivElement,
  element: HTMLDivElement
) => {
  const { height: eh, top: et } = element.getBoundingClientRect();
  const { height: ph, top: pt } = scrollingParent.getBoundingClientRect();

  const top = et + scrollingParent.scrollTop - pt;

  scrollingParent.scrollTo({
    top: eh > ph ? top : top - ph + eh,
    behavior: "smooth",
  });

  setTimeout(() => {
    check(scrollingParent, eh > ph ? top : top - ph + eh);
  }, 100);
};

export const updatedUsers = (
  instance: SendBird.SendBirdInstance,
  ids: string[],
  cb: (users: SendBird.User[]) => void
) => {
  const query = instance.createApplicationUserListQuery();

  query.userIdsFilter = ids;

  query.next(function (users) {
    cb(users);
  });
};
