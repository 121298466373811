import { FC, useState } from "react";
import { BurgerIcon } from "src/assets/icons";
import { Routes } from "src/constants/routes";
import Menu from "../Menu/Menu";
import css from "./Unauthorized.module.scss";
import cn from "classnames";

const Unauthorized: FC<{ className?: string }> = ({ className }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen((state) => !state);
  };

  const controls = [
    {
      id: 1,
      order: 1,
      label: "Iniciar sesión",
      link: Routes.worker.login,
    },
    {
      id: 2,
      order: 2,
      label: "Crear perfil de psicólogo",
      link: Routes.worker.register.main,
    },
    {
      id: 3,
      order: 3,
      label: "Abrir solicitud como cliente",
      link: Routes.client.login,
    },
    {
      id: 4,
      order: 4,
      label: "Encontrar un psicólogo",
      link: Routes.client.create.intro,
    },
  ];

  return (
    <>
      <button
        className={cn(className, css.burger)}
        aria-label={menuOpen ? "Close menu" : "Open the menu"}
        onClick={handleMenuToggle}
      >
        <BurgerIcon className={css.burger__icon} />
      </button>

      {menuOpen && <Menu controls={controls} onClose={handleMenuToggle} />}
    </>
  );
};

export default Unauthorized;
