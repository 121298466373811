import { useEffect } from "react";
import css from "./Worker.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getWorkerAuthorized,
  getWorkerUnauthorized,
  resetWorkerClient,
  resetWorkers,
  taskSelector,
} from "src/store/taskSlice";
import Preloader from "src/components/Preloader/Preloader";
import {
  clientOpenProfile,
  naOpenProfile,
  userShowPageTask,
} from "src/helpers/metric";
import { resetWorker } from "src/store/workerSlice";
import { Routes } from "src/constants/routes";
import { useHistory, useParams } from "react-router-dom";

import Profile from "./components/Profile/Profile";

import { refundInfoSelector } from "src/store/createNewTaskSlice";
import { authSelector } from "src/store/authSlice";

import Refund from "./components/Refund/Refund";
import { setVisibleHeaderToCatalog } from "src/store/layoutSlice";

const Worker = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { workerId } = useParams<{
    workerId: string;
  }>();

  const taskData = useSelector(taskSelector);
  const { shownPopup: shownRefundPopup } = useSelector(refundInfoSelector);
  const { isAuthTask, isAuthWorker } = useSelector(authSelector);

  useEffect(() => {
    if (isAuthWorker) return;

    if (isAuthTask) {
      clientOpenProfile();
    } else {
      naOpenProfile();
    }
  }, [isAuthTask, isAuthWorker]);

  useEffect(() => {
    if (!isAuthTask) {
      dispatch(getWorkerUnauthorized(workerId));
    } else {
      dispatch(getWorkerAuthorized(workerId));
    }

    dispatch(setVisibleHeaderToCatalog(true));

    return () => {
      dispatch(resetWorkers());
      dispatch(resetWorkerClient());
      dispatch(setVisibleHeaderToCatalog(false));

      if (isAuthTask) {
        dispatch(resetWorker());
      }
    };
  }, [dispatch, history]); // eslint-disable-line

  // Если работник не найден
  useEffect(() => {
    if (taskData.isLoaded && taskData.workerNotFound) {
      history.push(Routes.home);
    }
  }, [taskData]); // eslint-disable-line

  // Метрика
  useEffect(() => {
    if (taskData.task?.id) {
      userShowPageTask(taskData.task.id);
    }
  }, [taskData.task]);

  if (taskData.workerNotFound || !taskData.worker) {
    return <Preloader />;
  }

  return (
    <div className={css.client}>
      <Profile
        worker={taskData.worker}
        className={css.client__profile}
        scrollBlockId={null}
        isLogged={!!isAuthTask}
      />

      {!shownRefundPopup && !isAuthTask && <Refund />}
    </div>
  );
};

export default Worker;
