import { specWorkerType } from "../types";
import css from "./Blocks.module.scss";
import cn from "classnames";

const Spec: React.FC<specWorkerType> = ({
  forWhom,
  specialization,
  approach,
  lgbt,
}) => {
  return (
    <div className={css.block}>
      <div className={css.block__title}>Especialización</div>

      {!!forWhom?.length && (
        <div className={cn(css.block__item, css.block__item_col_2)}>
          <div className={css.block__subtitle}>Dirigido a </div>
          <div className={css.block__desc}>
            {forWhom.map((method) => method.name).join(", ")}
          </div>
        </div>
      )}

      {!!specialization?.length && (
        <div className={cn(css.block__item, css.block__item_col_2)}>
          <div className={css.block__subtitle}>Áreas de especialización</div>
          <div className={css.block__desc}>
            {specialization.map((method) => method.name).join(", ")}
          </div>
        </div>
      )}

      {!!approach?.length && (
        <div className={cn(css.block__item, css.block__item_col_2)}>
          <div className={css.block__subtitle}>Tipo de terapia</div>
          <div className={css.block__desc}>
            {approach.map((method) => method.name).join(", ")}
          </div>
        </div>
      )}

      {typeof lgbt === "number" && (
        <div className={cn(css.block__item, css.block__item_col_2)}>
          <div className={css.block__subtitle}>Experiencia con LGBT+</div>
          <div className={css.block__desc}>{lgbt === 1 ? "Si" : "No"}</div>
        </div>
      )}
    </div>
  );
};

export default Spec;
