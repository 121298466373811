import { RefObject, useEffect } from "react";

export const useEventListener = <K extends keyof HTMLElementEventMap>(
  target: HTMLDivElement | RefObject<HTMLDivElement | undefined>,
  type: K,
  listener: (this: HTMLDivElement, ev: HTMLElementEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
) => {
  useEffect(() => {
    if (!target) return;

    const currentTarget = "current" in target ? target.current : target;

    if (currentTarget) {
      currentTarget.addEventListener(type, listener, options);
    }

    return () => {
      if (currentTarget) {
        currentTarget.removeEventListener(type, listener, options);
      }
    };
  }, [target, type, listener, options]);
};
