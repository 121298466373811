import { FC, useState } from "react";
import { Worker } from "src/types/Worker";

import css from "./Header.styles.module.scss";
import cn from "classnames";

import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";

import {
  clientLinkChatToProfile,
  naLinkChatToProfile,
  policyPopup,
} from "src/helpers/metric";
import MiniCardWorker from "src/components/MiniCardWorker/MiniCardWorker";

interface IHeader {
  className?: string;
  worker: Worker;
  showSubtitle: boolean;
  showPrivacyPolicy: boolean;
  workerLogged: boolean;
  taskLogged: boolean;
}

const Header: FC<IHeader> = ({
  worker,
  showSubtitle,
  showPrivacyPolicy,
  workerLogged,
  taskLogged,
  className,
}) => {
  const [openedPrivacyPolicy, setOpenedPrivacyPolicy] =
    useState<boolean>(false);

  const metrikToPerfil = () => {
    if (workerLogged) return;

    if (!taskLogged) {
      naLinkChatToProfile();
    } else {
      clientLinkChatToProfile();
    }
  };

  return (
    <div className={cn(css.header, className)}>
      <MiniCardWorker
        onLink={metrikToPerfil}
        worker={worker}
        showSubtitle={showSubtitle}
        showStatus
      />

      {showPrivacyPolicy && (
        <div className={cn(css.header__privacyPolicy, css.privacyPolicy)}>
          <button
            className={css.privacyPolicy__button}
            onClick={() => {
              setOpenedPrivacyPolicy(true);
              policyPopup();
            }}
          >
            Política&nbsp;de cancelación&nbsp;y reembolso
          </button>
        </div>
      )}

      {openedPrivacyPolicy && (
        <PrivacyPolicy setActive={() => setOpenedPrivacyPolicy(false)} />
      )}
    </div>
  );
};

export default Header;
