import axios from "axios";
import { api } from "src/constants/api";
import { STATISTIC_PSYCHOLOGISTS } from "src/constants/constants";
import { storageInteraction } from "src/helpers/storageInteraction";

interface ILocalDataStatistic {
  minicards: number[];
  chat: number[];
  profile: number[];
}

export const statisticPsychologists = (
  type: "minicards" | "chat" | "profile",
  id: string | number
) => {
  const localDataStatistic = (storageInteraction(STATISTIC_PSYCHOLOGISTS) || {
    minicards: [],
    chat: [],
    profile: [],
  }) as ILocalDataStatistic;

  let isUnique: boolean = false;

  const numberId = Number(id);
  if (!numberId) return;

  switch (type) {
    case "minicards":
      if (!localDataStatistic.minicards.includes(numberId)) {
        localDataStatistic.minicards.push(numberId);
        isUnique = true;
      }
      break;
    case "chat":
      if (!localDataStatistic.chat.includes(numberId)) {
        localDataStatistic.chat.push(numberId);
        isUnique = true;
      }
      break;
    case "profile":
      if (!localDataStatistic.profile.includes(numberId)) {
        localDataStatistic.profile.push(numberId);
        isUnique = true;
      }
  }

  const sendData = {
    id: numberId,
    type,
    unique: isUnique ? 1 : 0,
  };

  storageInteraction(STATISTIC_PSYCHOLOGISTS, localDataStatistic);
  axios.post(`${api}/task/statistic-psychologists/`, sendData);
};
