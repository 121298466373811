import { AppDispatch, AsyncRequest, RootState } from "./strore";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMessage, IUser } from "src/types/Chat";
import { sendBird } from "src";
import {
  conversionMessage,
  conversionToUser,
  reduceMessages,
} from "src/helpers/chat";
import { clientSendMessage } from "src/helpers/metric";

export interface ChatState {
  connect: {
    currentUserId: string | null;
  } & AsyncRequest;
  channel: {
    url: string | null;
  } & AsyncRequest;
  messages: {
    data: IMessage[];
    prevMessageId: number | undefined;
    nextMessageId: number | undefined;
    atUpdated: boolean;
    directionUpdate: "prev" | "next" | "center";
  } & AsyncRequest;
  lastMessages: AsyncRequest;
  sendMessages: {
    data: IMessage[];
  } & AsyncRequest;
  readingChannel: AsyncRequest;
  typingUsers: IUser[];
  firstUnreadMessage: {
    data: IMessage | undefined;
    searchСompleted: boolean;
  };
  list: {
    limit: number;
    total: number | null;
  };
  toNewMessage: boolean;
}

const initialState: ChatState = {
  connect: {
    currentUserId: null,
    isLoading: true,
    isLoaded: false,
    error: null,
  },
  channel: {
    url: null,
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  messages: {
    data: [],
    prevMessageId: undefined,
    nextMessageId: undefined,
    atUpdated: false,
    directionUpdate: "center",
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  list: {
    limit: 40,
    total: null,
  },
  sendMessages: {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  readingChannel: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  typingUsers: [],
  firstUnreadMessage: {
    data: undefined,
    searchСompleted: false,
  },
  lastMessages: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  toNewMessage: false,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    connectUser: (state: ChatState) => {
      state.connect.isLoading = true;
      state.connect.isLoaded = false;
      state.connect.currentUserId = null;
      state.connect.error = null;
    },
    connectUserSuccess: (
      state: ChatState,
      { payload }: PayloadAction<{ currentUserId: string }>
    ) => {
      state.connect.isLoading = false;
      state.connect.isLoaded = true;
      state.connect.currentUserId = payload.currentUserId;
    },
    connectUserFailure: (state: ChatState, { payload }) => {
      state.connect.isLoading = false;
      state.connect.isLoaded = true;
      state.connect.error = payload;
    },

    setChannel: (state: ChatState, { payload }: PayloadAction<string>) => {
      state.channel.isLoading = true;
      state.channel.isLoaded = false;
      state.channel.error = null;
      state.channel.url = payload;
    },
    setChannelSuccess: (state: ChatState) => {
      state.channel.isLoading = false;
      state.channel.isLoaded = true;
    },
    setChannelFailure: (state: ChatState, { payload }) => {
      state.channel.isLoading = false;
      state.channel.isLoaded = true;
      state.channel.error = payload;
      state.channel.url = null;
    },

    setMessages: (state: ChatState) => {
      state.messages.isLoading = true;
      state.messages.isLoaded = false;
      state.messages.error = null;
    },
    setMessagesSuccess: (
      state: ChatState,
      { payload }: PayloadAction<IMessage[]>
    ) => {
      state.messages.isLoading = false;
      state.messages.isLoaded = true;
      state.messages.data = payload;
    },
    setMessagesFailure: (state: ChatState, { payload }) => {
      state.messages.isLoading = false;
      state.messages.isLoaded = true;
      state.messages.error = payload;
    },

    removeMessage: (state: ChatState, { payload }: PayloadAction<number>) => {
      state.messages.data = state.messages.data.filter(
        (m) => m.messageId !== payload
      );
    },

    findUnreadMessage: (state: ChatState) => {
      const {
        messages: { data: messages },
        connect: { currentUserId },
      } = state;

      state.firstUnreadMessage.data = messages.find(
        (message) =>
          message.sender !== "admin" &&
          currentUserId !== message.sender.userId &&
          !message.isReading
      );
      state.firstUnreadMessage.searchСompleted = true;
    },

    removeFirstUnreadMessage: (state: ChatState) => {
      state.firstUnreadMessage = initialState.firstUnreadMessage;
    },

    readingChannel: (state: ChatState) => {
      state.messages.data = state.messages.data.map((message) => {
        return { ...message, isReading: true };
      });
    },

    sendMessage: (state: ChatState, { payload }: PayloadAction<IMessage>) => {
      state.sendMessages.isLoading = true;
      state.sendMessages.isLoaded = false;
      state.sendMessages.data = [payload, ...state.sendMessages.data];
      state.sendMessages.error = null;
    },
    sendMessageSuccess: (
      state: ChatState,
      { payload }: PayloadAction<number>
    ) => {
      state.sendMessages.isLoading = false;
      state.sendMessages.isLoaded = true;
      state.sendMessages.data = state.sendMessages.data.filter(
        (m) => m.messageId !== payload
      );
    },
    sendMessageFailure: (
      state: ChatState,
      {
        payload,
      }: PayloadAction<{
        id: number;
        error: any;
      }>
    ) => {
      state.sendMessages.isLoading = false;
      state.sendMessages.isLoaded = true;
      state.sendMessages.error = payload.error;
      state.sendMessages.data = state.sendMessages.data.filter(
        (m) => m.messageId !== payload.id
      );
    },

    setTypingUsers: (state: ChatState, { payload }: PayloadAction<IUser[]>) => {
      state.typingUsers = payload;
    },

    updatePrevMessage: (
      state: ChatState,
      { payload }: PayloadAction<number>
    ) => {
      state.messages.prevMessageId = payload;
    },

    updateNextMessage: (
      state: ChatState,
      { payload }: PayloadAction<number>
    ) => {
      state.messages.nextMessageId = payload;
    },

    setUpdateMessages: (
      state: ChatState,
      { payload }: PayloadAction<"prev" | "next" | "center">
    ) => {
      state.messages.atUpdated = true;
      state.messages.directionUpdate = payload;
    },
    setUpdateMessagesSuccess: (
      state: ChatState,
      { payload }: PayloadAction<IMessage[]>
    ) => {
      state.messages.atUpdated = false;
      state.messages.directionUpdate = "center";
      state.messages.data = payload;
    },
    setUpdateMessagesFailure: (state: ChatState, { payload }) => {
      state.messages.atUpdated = false;
      state.messages.directionUpdate = "center";
      state.messages.error = payload;
    },

    addMessage: (state: ChatState, { payload }: PayloadAction<IMessage>) => {
      state.messages.data = [...state.messages.data, payload];
      state.messages.nextMessageId = payload.messageId;
    },

    setLastMessages: (state: ChatState) => {
      state.lastMessages.isLoading = true;
      state.lastMessages.isLoaded = false;
      state.lastMessages.error = null;
    },
    setLastMessagesSuccess: (
      state: ChatState,
      { payload }: PayloadAction<IMessage[]>
    ) => {
      state.lastMessages.isLoading = false;
      state.lastMessages.isLoaded = true;
      state.messages.data = payload;
    },
    setLastMessagesFailure: (state: ChatState, { payload }) => {
      state.lastMessages.isLoading = false;
      state.lastMessages.isLoaded = true;
      state.lastMessages.error = payload;
    },

    setToNewMessage: (
      state: ChatState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.toNewMessage = payload;
    },

    clearChat: (state: ChatState) => {
      const resetState: ChatState = {
        ...state,
        messages: initialState.messages,
        channel: initialState.channel,
        sendMessages: initialState.sendMessages,
        typingUsers: initialState.typingUsers,
      };

      return resetState;
    },
  },
});

export const {
  connectUser,
  connectUserSuccess,
  connectUserFailure,
  setChannel,
  setChannelSuccess,
  setChannelFailure,
  setMessages,
  setMessagesSuccess,
  setMessagesFailure,
  addMessage,
  removeMessage,
  findUnreadMessage,
  removeFirstUnreadMessage,
  readingChannel,
  sendMessage,
  sendMessageSuccess,
  sendMessageFailure,
  setTypingUsers,
  updatePrevMessage,
  updateNextMessage,
  setUpdateMessages,
  setUpdateMessagesSuccess,
  setUpdateMessagesFailure,
  setLastMessages,
  setLastMessagesSuccess,
  setLastMessagesFailure,
  setToNewMessage,
  clearChat,
} = chatSlice.actions;
export const chatSelector = (state: RootState) => state.chat;

export default chatSlice.reducer;

export const сonnection = (currentUserId: string) => {
  return (dispatch: AppDispatch) => {
    dispatch(connectUser());

    sendBird.connect(currentUserId, (user, error) => {
      if (error) {
        dispatch(connectUserFailure(error.message));
      } else {
        dispatch(connectUserSuccess({ currentUserId }));
      }
    });
  };
};

export const disconnect =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      chat: {
        connect: { isLoaded },
      },
    } = getState();

    if (!isLoaded) return;

    sendBird.disconnect();

    dispatch(clearChat());
  };

export const channelConnect = (
  channelUrl: string,
  cb: (channel: SendBird.GroupChannel) => void
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      chat: {
        channel: { isLoading },
      },
    } = getState();

    if (isLoading) return;

    dispatch(setChannel(channelUrl));

    sendBird.GroupChannel.getChannel(channelUrl, (channel) => {
      dispatch(setChannelSuccess());
      cb(channel);
    }).catch((e) => {
      //
    });
  };
};

export const initMessages = (channel: SendBird.GroupChannel) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      chat: {
        list: { limit },
        connect: { currentUserId },
      },
    } = getState();

    if (!currentUserId) return;

    dispatch(setMessages());

    const listQuery = channel.createPreviousMessageListQuery();
    listQuery.limit = limit;

    listQuery.load((messageList, error) => {
      if (error) {
        dispatch(setMessagesFailure(error.message));
      } else {
        const newMessagesList = reduceMessages(
          channel,
          messageList,
          currentUserId
        );

        dispatch(setMessagesSuccess(newMessagesList));
        dispatch(findUnreadMessage());
        // @ts-ignore
        dispatch(findPrevAndNextMessage(channel));
      }
    });
  };
};

export const findPrevAndNextMessage =
  (channel: SendBird.GroupChannel) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      chat: {
        messages: { data: messages },
      },
    } = getState();

    const prevParams = new sendBird.MessageListParams();
    prevParams.prevResultSize = 2;
    prevParams.nextResultSize = 0;

    const nextParams = new sendBird.MessageListParams();
    nextParams.prevResultSize = 0;
    nextParams.nextResultSize = 2;

    channel.getMessagesByMessageId(
      messages[0].messageId,
      prevParams,
      (messages) => {
        dispatch(updatePrevMessage(messages[0]?.messageId));
      }
    );

    const indexPreLastMessage = messages.length - 2;

    channel.getMessagesByMessageId(
      messages[indexPreLastMessage > 0 ? indexPreLastMessage : 0].messageId,
      nextParams,
      (messages) => {
        dispatch(updateNextMessage(messages[messages.length - 1]?.messageId));
      }
    );
  };

export const prevPage =
  (channel: SendBird.GroupChannel) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      chat: {
        list: { limit },
        messages: { data: messages, prevMessageId, atUpdated },
        connect: { currentUserId },
        lastMessages,
      },
    } = getState();

    if (atUpdated || lastMessages.isLoading) return;

    const prevMessage = messages.find((m) => m.messageId === prevMessageId);

    if (prevMessage || !prevMessageId || !currentUserId) return;
    dispatch(setUpdateMessages("prev"));

    const params = new sendBird.MessageListParams();
    params.prevResultSize = limit;
    params.nextResultSize = limit;
    params.isInclusive = true;

    channel.getMessagesByMessageId(
      messages[0].messageId,
      params,
      (messageList, error) => {
        if (error) {
          dispatch(setUpdateMessagesFailure(error));
          return;
        }
        const newMessagesList = reduceMessages(
          channel,
          messageList,
          currentUserId
        );
        dispatch(setUpdateMessagesSuccess(newMessagesList));
        // @ts-ignore
        dispatch(findPrevAndNextMessage(channel));
      }
    );
  };

export const nextPage =
  (channel: SendBird.GroupChannel) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      chat: {
        list: { limit },
        messages: { data: messages, nextMessageId, atUpdated },
        connect: { currentUserId },
        lastMessages,
      },
    } = getState();

    if (atUpdated || lastMessages.isLoading) return;

    const nextMessage = messages.find((m) => m.messageId === nextMessageId);

    if (nextMessage || !nextMessageId || !currentUserId) return;
    dispatch(setUpdateMessages("next"));

    const params = new sendBird.MessageListParams();
    params.prevResultSize = limit;
    params.nextResultSize = limit;
    params.isInclusive = true;

    channel.getMessagesByMessageId(
      messages[messages.length - 1].messageId,
      params,
      (messageList, error) => {
        if (error) {
          dispatch(setUpdateMessagesFailure(error));
          return;
        }
        const newMessagesList = reduceMessages(
          channel,
          messageList,
          currentUserId
        );
        dispatch(setUpdateMessagesSuccess(newMessagesList));
        dispatch(findUnreadMessage());
        // @ts-ignore
        dispatch(findPrevAndNextMessage(channel));
      }
    );
  };

export const loadLastMessages = (channel: SendBird.GroupChannel) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      chat: {
        list: { limit },
        connect: { currentUserId },
      },
    } = getState();

    if (!currentUserId) return;

    dispatch(setLastMessages());

    const listQuery = channel.createPreviousMessageListQuery();
    listQuery.limit = limit;

    listQuery.load((messageList, error) => {
      if (error) {
        dispatch(setLastMessagesFailure(error.message));
      } else {
        const newMessagesList = reduceMessages(
          channel,
          messageList,
          currentUserId
        );

        dispatch(setLastMessagesSuccess(newMessagesList));
        // @ts-ignore
        dispatch(findPrevAndNextMessage(channel));
        dispatch(setToNewMessage(true));
      }
    });
  };
};

export const handleReadMessage = (channel: SendBird.GroupChannel) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      chat: {
        readingChannel: { isLoading },
      },
    } = getState();

    if (isLoading || !channel.unreadMessageCount) return;

    try {
      channel.markAsRead(() => {
        // dispatch(readingChannel());
      });
    } catch (error) {
      //
    }
  };
};

export const handleSendMessage = (
  channel: SendBird.GroupChannel,
  value: string
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      chat: {
        connect: { currentUserId },
        sendMessages: { isLoading },
      },
    } = getState();

    if (isLoading) return;

    const id = Date.now();

    const params = new sendBird.UserMessageParams();

    params.message = value;
    params.data = String(id);

    const sender = channel.members.find((m) => m.userId === currentUserId);

    dispatch(
      sendMessage({
        createdAt: Date.now(),
        messageId: id,
        data: "",
        isCurrentUser: true,
        isReading: false,
        message: value,
        sendingStatus: "pending",
        type: "user",
        sender: {
          nickname: sender?.nickname,
          plainProfileUrl: sender?.plainProfileUrl,
          userId: sender?.userId,
        },
      })
    );

    let isCanceled = false;

    setTimeout(() => {
      if (!isCanceled) {
        dispatch(sendMessageFailure({ id, error: null }));
        isCanceled = true;
      }
    }, 20000);

    channel.sendUserMessage(params, (message, error) => {
      isCanceled = true;

      if (error) {
        dispatch(sendMessageFailure({ id, error }));
      } else {
        // @ts-ignore
        dispatch(handleAddMessage(channel, message));

        dispatch(sendMessageSuccess(id));
        dispatch(setToNewMessage(true));
        clientSendMessage();
      }
    });
  };
};

export const updateTypingUsers =
  (channel: SendBird.GroupChannel) => (dispatch: AppDispatch) => {
    const members = channel.getTypingMembers().map(conversionToUser);

    dispatch(setTypingUsers(members));
  };

export const handleAddMessage =
  (
    channel: SendBird.GroupChannel,
    message: SendBird.UserMessage | SendBird.AdminMessage
  ) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      chat: {
        connect: { currentUserId },
        messages: { data: messages, nextMessageId },
      },
    } = getState();

    if (
      nextMessageId &&
      messages.map((m) => m.messageId).includes(nextMessageId)
    ) {
      const readingMembers = channel
        .getReadMembers(message as SendBird.UserMessage, true)
        .filter((member) => member.userId !== currentUserId);

      //console.log("add", message.message, readingMembers);

      const newMessage = conversionMessage({
        message: message,
        isCurrentUser:
          message.messageType === "user" &&
          currentUserId === message.sender?.userId,
        isReading: !!readingMembers.length,
      });

      dispatch(addMessage(newMessage));
      dispatch(setToNewMessage(true));
    } else {
      // @ts-ignore
      dispatch(loadLastMessages(channel));
    }
  };
