import React, { useEffect, useState } from "react";
import { Form, Formik, FormikErrors, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import cn from "classnames";

import { Step2TypesProps, Step2TypesValues } from "./Step2Types";
import { Routes } from "src/constants/routes";
import { formatEasyAnswer } from "src/constants/constants";
import {
  questionnaireSelector,
  updateData,
} from "src/store/questionnaireSlice";
import {
  getAllSpainData,
  referenceForWhomSelector,
  referenceSpecializationSelector,
  referenceApproachSelector,
} from "src/store/referenceSlice";
import Button from "src/components/UI-kit/Button/Button";
import FieldChange from "src/components/UI-kit/FieldChange/FieldChange";
import FieldSelect from "src/components/UI-kit/FieldSelect/FieldSelect";
import FieldCheckbox from "src/components/UI-kit/FieldCheckbox/FieldCheckbox";

import css from "../Questionnaire.module.scss";
import Preloader from "src/components/Preloader/Preloader";

const Step2: React.FC<Step2TypesProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { step } = useParams<{ step: string }>();

  const forWhom = useSelector(referenceForWhomSelector);
  const specialization = useSelector(referenceSpecializationSelector);
  const approach = useSelector(referenceApproachSelector);
  const questionnaire = useSelector(questionnaireSelector);

  const thisData = questionnaire.data.map(
    (item) => item.step === step && item.values
  );

  const initData: /* Step2TypesValues */ any = thisData[1] || {
    forWhom: [],
    lgbt: "0",
    specialization: [],
    approach: [],
  };

  useEffect(() => {
    dispatch(getAllSpainData());
  }, [dispatch]);

  const [err, setErr] = useState<FormikErrors<Step2TypesValues>>({});
  const [formErrorMessage, setFormErrorMessage] = useState("");

  const handleSubmit = (values: Step2TypesValues) => {
    // workerClickRegister();

    nextStep(+step + 1);
    dispatch(updateData({ values, step }));
  };

  const handleStepBack = (values: Step2TypesValues) => {
    prevStep(+step - 1);
    dispatch(updateData({ values, step }));
  };

  const validate = (values: Step2TypesValues) => {
    const errors: FormikErrors<Step2TypesValues> = {};

    if (!values.forWhom.length) {
      errors.forWhom = "clientes";
    }
    if (!values.lgbt.length) {
      errors.lgbt = "experiencía con LGBT+";
    }
    if (!values.specialization.length) {
      errors.specialization = "áreas de consulta";
    }
    if (!values.approach.length) {
      errors.approach = "tipos de terapias";
    }

    return errors;
  };

  useEffect(() => {
    const msgErrors = {
      forWhom: {
        has: !!err.forWhom,
        text: "clientes",
      },
      lgbt: {
        has: !!err.lgbt,
        text: "experiencía con LGBT+",
      },
      specialization: {
        has: !!err.specialization,
        text: "áreas de consulta",
      },
      approach: {
        has: !!err.approach,
        text: "tipos de terapias",
      },
    };

    const errors = Object.values(msgErrors).filter((m) => m.has);

    if (errors.length) {
      setFormErrorMessage(
        `Para registrarse completa: ` +
          errors.map((m) => m.text).join(", ") +
          "."
      );
    } else {
      setFormErrorMessage("");
    }
  }, [err]);

  const nextStep = (step: number) => {
    step !== 5
      ? history.push(`${Routes.worker.register.questionnaire}/${step}`)
      : history.push(Routes.worker.register.onboarding);
  };

  const prevStep = (step: number) => {
    step !== 0
      ? history.push(`${Routes.worker.register.questionnaire}/${step}`)
      : history.push(Routes.worker.register.onboarding);
  };

  if (forWhom.isLoading || specialization.isLoading || approach.isLoading) {
    return <Preloader className={css.preloader} />;
  }

  return (
    <Formik
      initialValues={initData}
      enableReinitialize
      onSubmit={handleSubmit}
      validate={validate}
      validateOnMount={true}
    >
      {({ values, errors, dirty }: FormikProps<Step2TypesValues>) => (
        <Form className={css.questionnaire__wrap}>
          <div className={css.questionnaire__checkboxWrap}>
            <div
              className={cn(
                "b-content b-content_size b-content_size_xs",
                css.questionnaire__subtitle
              )}
            >
              <p>Categorías de clientes con quienes trabajas*</p>
            </div>

            <div role="group">
              {!!forWhom.data &&
                !!forWhom.data.length &&
                forWhom.data.map((n) => {
                  return (
                    <FieldCheckbox
                      className={css.questionnaire__fieldCheckbox}
                      key={n.id}
                      name="forWhom"
                      label={n.name}
                      value={String(n.id)}
                    />
                  );
                })}
            </div>
          </div>

          <FieldSelect
            className={css.questionnaire__checkboxWrap}
            label="Experiencia de trabajo con pacientes LGBT+"
            name="lgbt"
            required
            defaultValue={"0"}
            options={[formatEasyAnswer[1], formatEasyAnswer[0]]}
          />

          <div className={css.questionnaire__checkboxWrap}>
            <div
              className={cn(
                "b-content b-content_size b-content_size_xs",
                css.questionnaire__subtitle
              )}
            >
              <p>Áreas de consulta*</p>
            </div>

            <div role="group">
              {!!specialization.data &&
                !!specialization.data.length &&
                specialization.data.map((n) => {
                  return (
                    <FieldCheckbox
                      className={css.questionnaire__fieldCheckbox}
                      key={n.id}
                      name="specialization"
                      label={n.name}
                      value={String(n.id)}
                    />
                  );
                })}
            </div>
          </div>

          <div className={css.questionnaire__checkboxWrap}>
            <div
              className={cn(
                "b-content b-content_size b-content_size_xs",
                css.questionnaire__subtitle
              )}
            >
              <p>Tipos de terapias que usas*</p>
            </div>

            <div role="group">
              {!!approach.data &&
                !!approach.data.length &&
                approach.data.map((n) => {
                  return (
                    <FieldCheckbox
                      className={css.questionnaire__fieldCheckbox}
                      key={n.id}
                      name="approach"
                      label={n.name}
                      value={String(n.id)}
                    />
                  );
                })}
            </div>
          </div>

          <div className="divider"></div>

          <Button
            transparent
            className={css.questionnaire__btn}
            type="button"
            text="Volver"
            onClick={() => handleStepBack(values)}
          />

          <Button
            className={css.questionnaire__btn}
            type="submit"
            text="Continuar"
            disabled={!!Object.keys(errors).length}
          />

          {!!formErrorMessage.length && (
            <div className={css.questionnaire__alert}>
              <p>{formErrorMessage}</p>
            </div>
          )}

          <FieldChange<Step2TypesValues> onError={setErr} />
        </Form>
      )}
    </Formik>
  );
};

export default Step2;
