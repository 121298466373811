import { useEffect } from "react";
import css from "./Layout.module.scss";
import cn from "classnames";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { logSelector } from "src/store/logSlice";
import {
  createWorkereModalWaitingListSelector,
  hideModalWaitingList,
} from "src/store/createWorkerSlice";

import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ActionFailed from "./ActionFailed/ActionFailed";
import Toast from "src/components/Toast/Toast";
import Modal from "src/components/Modal/Modal";
import { MessageYes } from "src/assets/graphIcons";
import Routing from "src/Routing";
import { refundInfoSelector } from "src/store/createNewTaskSlice";
import { authSelector } from "src/store/authSlice";
import { debounce } from "src/helpers/debounce";

const Layout = () => {
  const dispatch = useDispatch();

  const { eventError } = useSelector(logSelector);

  const location = useLocation();
  const modalWaitingList = useSelector(createWorkereModalWaitingListSelector);
  const { shownPopup, shownBanner } = useSelector(refundInfoSelector);
  const { isAuthWorker } = useSelector(authSelector);

  useEffect(() => {
    // Костыль!
    if (!!document.querySelector(".content-right")) {
      document.querySelector(".content-right")?.scrollTo({ top: 0 });
    }
    window.scrollTo({ top: 0 });
  }, [location.pathname]);

  useEffect(() => {
    function updateDeviceProps() {
      const height = window.innerHeight;
      const vh = height * 0.01;
      const scrollWidth = window.innerWidth - document.body.clientWidth;

      document.documentElement.style.setProperty("--vh", `${vh}px`);
      document.documentElement.style.setProperty(
        "--scroll-width",
        `${scrollWidth}px`
      );
      document.documentElement.style.setProperty("--height", `${height}px`);
    }

    updateDeviceProps();
    const debouncedUpdate = debounce(updateDeviceProps);

    window.addEventListener("resize", debouncedUpdate);

    return () => {
      window.removeEventListener("resize", debouncedUpdate);
    };
  }, []);

  return (
    <div
      className={cn(css.layout, {
        [css.layout_withHeaderBanner]:
          shownPopup && !shownBanner && !isAuthWorker,
      })}
    >
      <Header />

      <Routing />

      {eventError && <ActionFailed />}

      {modalWaitingList && (
        <Modal
          active={true}
          setActive={() => dispatch(hideModalWaitingList())}
          modalConfig={{
            icon: <MessageYes />,
            title: "Estás en lista de espera",
            desc: `No estamos registrando cuentas nuevas. Lo sentimos mucho. Te avisaremos cuando haya disponibilidad.`,
            submitText: "Ok",
          }}
          type={"info"}
        />
      )}

      <Toast />
      <Footer />
    </div>
  );
};

export default Layout;
