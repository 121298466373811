import { monthes } from "src/constants/constants";

export const getDate = (dateNumber: number): string => {
  const date = new Date(dateNumber);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const now = new Date();

  if (
    now.getFullYear() === year &&
    now.getMonth() === month &&
    now.getDate() === day
  ) {
    return "Hoy";
  }

  return `${day} ${monthes[month]} ${year}`;
};
