import { useEffect, useState } from "react";

const useMobile = (width: number) => {
  const [isMobile, setMobile] = useState<boolean | null>(null);

  useEffect(() => {
    function defineMobile() {
      setMobile(window.innerWidth <= width);
    }
    window.addEventListener("resize", defineMobile);
    defineMobile();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isMobile;
};

export default useMobile;
