import { FieldSelectOption } from "src/components/UI-kit/FieldSelect/FieldSelect";
import { defaultOption, formatSexWorker } from "src/constants/constants";
import { IFilters } from "src/types/Task";

interface ITransformFilters {
  forWhomOptions: FieldSelectOption<string>[];
  specializationOptions: FieldSelectOption<string>[];
  data: IFilters;
}

export const transformFilters = ({
  forWhomOptions,
  specializationOptions,
  data,
}: ITransformFilters) => {
  const forWhomLabel =
    forWhomOptions.find((option) => option.value === data.forWhomId)?.label ||
    defaultOption.label;

  const specializationLabel =
    specializationOptions.find(
      (option) => option.value === data.specializationId
    )?.label || defaultOption.label;

  const sexLabel =
    formatSexWorker.find((option) => option.value === data.sex)?.label ||
    defaultOption.label;

  return {
    para_quien: forWhomLabel,
    especializacion: specializationLabel,
    genero: sexLabel,
  };
};
