import { useEffect, useRef } from "react";
import css from "./Footer.module.scss";
import { Mail } from "src/assets/icons";
import cn from "classnames";
import { useSelector } from "react-redux";
import useMobile from "../../hooks/useMobile";
import { authSelector } from "src/store/authSlice";
import { workerSelector } from "src/store/workerSlice";
import { useHistory } from "react-router-dom";
import { layoutFooterSelector } from "src/store/layoutSlice";

const Footer = () => {
  const { tokenWorker: isWorker, tokenTask: isTask } =
    useSelector(authSelector);
  const history = useHistory();
  const { visible } = useSelector(layoutFooterSelector);
  const worker = useSelector(workerSelector);
  const mobile = useMobile(1023);

  const footerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!!footerRef.current) {
      document.documentElement.style.setProperty(
        "--footerHeight",
        `${footerRef.current.offsetHeight}px`
      );
    }
  }, [mobile, isWorker, isTask, history]);

  if (!visible) return null;

  return (
    <footer ref={footerRef} className={cn(css.footer)}>
      <div className={cn(css[`footer-wrapper`], "container")}>
        {(isTask || (isWorker && worker.worker?.isStatus === 1)) && (
          <div className={css[`footer-title`]}>contacto</div>
        )}

        {(isTask || (isWorker && worker.worker?.isStatus === 1)) && (
          <div className={css[`footer-contacts`]}>
            <div className={css[`footer-contacts__item`]}>
              <Mail />
              <div className={css.title}>soporte@necesitaria.es</div>
            </div>
          </div>
        )}

        <div className={css[`footer-links`]}>
          <button
            className={cn(css[`footer-links__item`], css.link)}
            type="button"
            onClick={() => {
              window
                .open(
                  "https://www.iubenda.com/privacy-policy/83721225/full-legal",
                  "_blank"
                )
                ?.focus();
            }}
          >
            Política de privacidad y uso
          </button>

          <div className={css[`footer-links__item`]}>
            ©&nbsp;2021-{new Date().getFullYear()}, Necesitaría.Es
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
