import { RefObject, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCSSLayoutSize } from "src/helpers/getHeaderHeight";
import { authSelector } from "src/store/authSlice";
import { refundInfoSelector } from "src/store/createNewTaskSlice";

export const useDetectVisibleElement = (
  ref: RefObject<HTMLDivElement | HTMLButtonElement>,
  initialVisible?: boolean
) => {
  const [visible, setVisible] = useState<boolean>(!!initialVisible);
  const { shownPopup, shownBanner } = useSelector(refundInfoSelector);
  const { isAuthWorker } = useSelector(authSelector);
  const isBannerShow = shownPopup && !shownBanner && !isAuthWorker;

  useEffect(() => {
    const { current: element } = ref;

    const detectElement = () => {
      if (!element) return;
      const headerHeight = getCSSLayoutSize("--header-height");
      const headerBannerHeight = getCSSLayoutSize("--header-banner-height");
      const totalheight =
        headerHeight + (isBannerShow ? headerBannerHeight : 0);
      const coords = element.getBoundingClientRect();

      if (
        coords.y + coords.height - totalheight > 0 &&
        window.innerHeight - coords.y > 0
      ) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    detectElement();
    window.addEventListener("scroll", detectElement);

    return () => window.removeEventListener("scroll", detectElement);
  }, [ref, isBannerShow]); // eslint-disable-line

  return visible;
};
