import { RefObject, useEffect } from "react";
import { assertIsNode } from "src/helpers/assertIsNode";

export function useClickOut(
  callback: () => void,
  ref: RefObject<HTMLDivElement>,
  dependence: boolean
): void {
  useEffect(() => {
    function handleClick({ target }: globalThis.MouseEvent) {
      assertIsNode(target);

      if (dependence && ref.current && !ref.current.contains(target)) {
        callback();
      }
    }

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [callback, dependence, ref]);
}
