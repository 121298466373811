import { educationWorkerType, collegiateNumberType } from "../types";
import css from "./Blocks.module.scss";
import cn from "classnames";

const Education: React.FC<educationWorkerType & collegiateNumberType> = ({
  college_and_university_degree,
  collegiate_number,
  educationText,
  collegium,
}) => {
  return (
    <div className={css.block}>
      <div className={css.block__title}>formación y licencia profesional</div>

      {!!college_and_university_degree && (
        <div className={cn(css.block__item, css.block__item_col_2)}>
          <div className={css.block__subtitle}>
            Título universitario y universidad
          </div>
          <div className={css.block__desc}>{college_and_university_degree}</div>
        </div>
      )}

      {!!educationText && (
        <div className={cn(css.block__item, css.block__item_col_2)}>
          <div className={css.block__subtitle}>Formación complementaria</div>
          <div className={css.block__desc}>{educationText}</div>
        </div>
      )}

      {!!collegium && (
        <div className={cn(css.block__item, css.block__item_col_2)}>
          <div className={css.block__subtitle}>
            Colegio Oficial de Psicólogos
          </div>
          <div className={css.block__desc}>{collegium}</div>
        </div>
      )}

      {!!collegiate_number && (
        <div className={cn(css.block__item, css.block__item_col_2)}>
          <div className={css.block__subtitle}>Núm. Colegiado</div>
          <div className={css.block__desc}>{collegiate_number}</div>
        </div>
      )}
    </div>
  );
};

export default Education;
