import React from "react";
import { IBody } from "./BodyTypes";
import css from "./Body.module.scss";
import cn from "classnames";
import { Sex, Theme } from "src/assets/icons";
import { sexData } from "src/constants/constants";

const Body: React.FC<IBody> = ({ className, data }) => {
  const { sex, forWhom, specialization } = data || {};

  return (
    <div className={cn(className, css.task)}>
      <div className={cn(css[`task__icons`], css[`task-icons`])}>
        {!!forWhom && !!specialization && (
          <div className={css[`task-icons__item`]}>
            <div className={css.icon}>
              <Theme />
            </div>

            <div className={css.desc}>
              {!!forWhom && forWhom + ", "}
              {!!specialization && specialization}
            </div>
          </div>
        )}

        <div className={css[`task-icons__item`]}>
          <div className={css.icon}>
            <Sex />
          </div>

          <div className={css.desc}>
            {sexData.filter((item) => Number(item.id) === sex)[0]?.showValue ||
              sexData[0].showValue}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
