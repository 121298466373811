import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AsyncRequest, RootState } from "./strore";
import { api } from "../constants/api";
import axios from "axios";
import { taskIdLogged } from "src/constants/constants";
import { ICardWorkerCat, IFilters, Task } from "../types/Task";
import { addMessage } from "./logSlice";
import httpErrorHandler from "./httpErrorHandler";
import { Worker } from "../types/Worker";
import {
  getMoreReviews,
  getReviews,
  getReviewsFailure,
  getReviewsSuccess,
  logoutWorkerSuccess,
  resetWorkerNotFound,
} from "./workerSlice";
import {
  clienPayStart,
  clientSendMessage,
  userTaskAuthSuccess,
} from "src/helpers/metric";
import { clearToken, setTokenTask } from "./authSlice";
import { stripeResponceStatuses } from "src/types/Task";
import {
  disconnect as disconnectChat,
  сonnection as сonnectionChat,
} from "./chatSlice";

export interface LoginFormValues {
  taskLoginId: string;
  taskLoginPassword: string;
}

export interface IStripeResponce {
  isLoading: boolean;
  status: stripeResponceStatuses | null;
  message: string;
}

export interface TaskState {
  isLoading: boolean;
  isLoaded: boolean;
  isLogged: boolean | null;
  isLoggedClient: boolean | null;
  isBlocked: boolean | null;
  completed: boolean;
  task: Task | null;
  worker: Worker | null;
  stripeResponce: IStripeResponce;
  workers: { data: ICardWorkerCat[] } & AsyncRequest;
  channel: {
    url: Worker["channelUrl"];
    firstMessage: {
      workerId: string;
      message: string;
    } | null;
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
  };
  workerNotFound: boolean;
  error: {
    code?: number;
    message?: string;
  } | null;
}

const initialState: TaskState = {
  isLoading: false,
  isLoaded: false,
  isLogged: null,
  isLoggedClient: null,
  isBlocked: null,
  completed: false,
  task: null,
  worker: null,
  workers: {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  stripeResponce: {
    isLoading: false,
    status: null,
    message: "",
  },
  channel: {
    url: null,
    firstMessage: null,
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  workerNotFound: false,
  error: null,
};

/* TODO: refactor code, move api requests to the http folder with configs */

export const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    loginTask: (state: TaskState) => {
      state.isLoading = true;
    },
    loginTaskSuccess: (state: TaskState) => {
      state.isLoading = false;
      state.isLogged = true;
      state.completed = true;
    },
    loginTaskFailure: (state: TaskState, { payload }) => {
      state.isLoading = false;
      state.isLogged = false;
      state.isBlocked = payload.isBlocked;
    },
    cleareloginTask: (state: TaskState) => {
      state.completed = false;
    },

    logoutTask: (state: TaskState) => {
      state.isLoading = true;
    },
    logoutTaskSuccess: (state: TaskState) => {
      state.isLoading = false;
      state.task = null;
      state.isLogged = null;
    },
    logoutTaskFailure: (state: TaskState) => {
      state.isLoading = false;
    },

    getTask: (state: TaskState) => {
      state.isLoading = true;
    },
    getTaskSuccess: (state: TaskState, { payload }) => {
      state.isLoading = false;
      state.task = payload;
      state.isLogged = true;
    },
    getTaskFailure: (state: TaskState) => {
      state.isLoading = false;
      state.task = null;
    },

    getWorkers: (state: TaskState) => {
      state.workers.isLoading = true;
      state.workers.isLoaded = false;
      state.workers.error = null;
    },
    getWorkersSuccess: (
      state: TaskState,
      { payload }: PayloadAction<ICardWorkerCat[]>
    ) => {
      state.workers.isLoading = false;
      state.workers.isLoaded = true;
      state.workers.data = payload;
    },
    getWorkersFailure: (state: TaskState, { payload }) => {
      state.workers.isLoading = false;
      state.workers.isLoaded = true;
      state.workers.error = payload;
    },
    resetWorkers: (state: TaskState) => {
      state.workers = { ...initialState.workers };
    },

    stripeResponce: (state: TaskState) => {
      state.stripeResponce.isLoading = true;
    },
    stripeResponceSuccess: (state: TaskState) => {
      state.stripeResponce.isLoading = false;
      state.stripeResponce.status = stripeResponceStatuses.SUCCESS;
      state.stripeResponce.message = "";
    },
    stripeResponceFailure: (
      state: TaskState,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.stripeResponce.isLoading = false;
      state.stripeResponce.status = stripeResponceStatuses.FAILURE;
      state.stripeResponce.message = payload || "";
    },
    stripeResponceReset: (state: TaskState) => {
      state.stripeResponce = initialState.stripeResponce;
    },

    getWorkerClient: (state: TaskState) => {
      state.isLoading = true;
      state.isLoaded = false;
      state.workerNotFound = initialState.workerNotFound;
    },
    getWorkerClientSuccess: (
      state: TaskState,
      { payload: { data, isWorker } }
    ) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.worker = data;
      state.isLoggedClient = isWorker;
      state.workerNotFound = false;
    },
    getWorkerClientFailure: (state: TaskState) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.workerNotFound = true;
    },
    resetWorkerClientNotFound: (state: TaskState) => {
      state.workerNotFound = false;
    },
    resetWorkerClient: (state: TaskState) => {
      state.worker = null;
      state.workerNotFound = false;
    },

    createChannel: (state: TaskState) => {
      state.channel.isLoading = true;
      state.channel.error = null;
      state.channel.url = null;
      state.channel.isLoaded = false;
    },
    createChannelSuccess: (state: TaskState, { payload }) => {
      state.channel.isLoading = false;
      state.channel.isLoaded = true;

      if (state.worker) {
        state.worker.channelUrl = payload;
      } else {
        state.channel.url = payload;
        state.channel.firstMessage = null;
      }
    },
    createChannelError: (state: TaskState, { payload }) => {
      state.channel.isLoading = false;
      state.channel.isLoaded = true;
      state.channel.error = payload;
    },
    setChannelFirstMessage: (
      state: TaskState,
      { payload }: PayloadAction<TaskState["channel"]["firstMessage"]>
    ) => {
      state.channel.firstMessage = payload;
    },
    resetChannelFirstMessage: (state: TaskState) => {
      state.channel.firstMessage = null;
    },
    resetCreateChannel: (state: TaskState) => {
      state.channel.isLoading = false;
      state.channel.isLoaded = false;
      state.channel.error = null;
      state.channel.url = null;
    },

    setError: (
      state: TaskState,
      { payload }: PayloadAction<TaskState["error"]>
    ) => {
      state.error = payload;
    },
    resetError: (state: TaskState) => {
      state.error = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loginTask,
  loginTaskSuccess,
  loginTaskFailure,
  cleareloginTask,

  getTask,
  getTaskSuccess,
  getTaskFailure,

  getWorkers,
  getWorkersSuccess,
  getWorkersFailure,
  resetWorkers,

  logoutTask,
  logoutTaskSuccess,
  logoutTaskFailure,

  stripeResponce,
  stripeResponceSuccess,
  stripeResponceFailure,
  stripeResponceReset,

  getWorkerClient,
  getWorkerClientSuccess,
  getWorkerClientFailure,
  resetWorkerClientNotFound,
  resetWorkerClient,

  createChannel,
  createChannelSuccess,
  createChannelError,
  setChannelFirstMessage,
  resetChannelFirstMessage,
  resetCreateChannel,

  setError,
  resetError,
} = taskSlice.actions;

export const taskSelector = (state: RootState) => state.task;

export default taskSlice.reducer;

export const sendAuthTask = (loginData: LoginFormValues) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loginTask());
    //@ts-ignore
    dispatch(clearToken()); // Удаляю токены

    try {
      const { data } = await axios.post(`${api}/client/auth/`, {
        task: loginData.taskLoginId,
        password: loginData.taskLoginPassword,
      });
      //@ts-ignore
      dispatch(setTokenTask(data.token)); // Сохраняю токен
      localStorage.setItem(taskIdLogged, loginData.taskLoginId); // ???
      dispatch(loginTaskSuccess());
      userTaskAuthSuccess(loginData.taskLoginId); // Ya.Metrika
    } catch (error: any) {
      const { errors } = error.response.data;
      httpErrorHandler(error, dispatch);

      dispatch(
        loginTaskFailure({
          isBlocked:
            errors.findIndex(
              (err: any) => err?.message === "Задание заблокировано."
            ) !== -1,
        })
      );
      errors.forEach((err: any) =>
        dispatch(addMessage({ type: "error", message: err.message }))
      );
    }
  };
};

export const sendLogoutTask = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      auth: { tokenTask },
    } = getState();
    dispatch(logoutTask());

    try {
      await axios.post(`${api}/client/logout/`, null, {
        headers: {
          Authorization: `Bearer ${tokenTask}`,
          "Content-Type": "application/json",
        },
      });

      //@ts-ignore
      dispatch(disconnectChat());

      dispatch(logoutTaskSuccess());
      dispatch(logoutWorkerSuccess());
      //@ts-ignore
      dispatch(clearToken()); // Удаляю токены
      localStorage.removeItem(taskIdLogged);
    } catch (error: any) {
      // const {errors} = error.response.data;
      httpErrorHandler(error, dispatch);
      dispatch(logoutTaskFailure());
      /*errors.forEach((err: any) => dispatch(addMessage({type: 'error', message: err.message})))*/
    }
  };
};

export const getTaskData = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      auth: { tokenTask },
    } = getState();

    if (!tokenTask) return;

    dispatch(resetWorkerNotFound());
    dispatch(getTask());

    try {
      const { data } = await axios.get(`${api}/client/task/`, {
        headers: {
          Authorization: `Bearer ${tokenTask}`,
          "Content-Type": "application/json",
        },
      });

      dispatch(getTaskSuccess(data.data));

      const idSendbird = data.data.idSendbird as string;

      //@ts-ignore
      dispatch(сonnectionChat(idSendbird));
    } catch (error: any) {
      httpErrorHandler(error as Error, dispatch);
      dispatch(
        setError({
          code: error?.response?.status,
          message: error?.response?.message,
        })
      );
      dispatch(getTaskFailure());
    }
  };
};

export const getPsychologistUnauthorized =
  (filters: Partial<IFilters>) => async (dispatch: AppDispatch) => {
    dispatch(getWorkers());

    try {
      const { data } = await axios.get(`${api}/task/search-psychologists/`, {
        params: { ...filters },
      });

      dispatch(getWorkersSuccess(data.data));
    } catch (error) {
      httpErrorHandler(error, dispatch);
      dispatch(getWorkersFailure(error));
    }
  };

export const getPsychologistAuthorized =
  (filters: Partial<IFilters>) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      auth: { tokenTask },
    } = getState();

    if (!tokenTask) return;

    dispatch(getWorkers());
    try {
      const { data } = await axios.get(`${api}/client/recommended/`, {
        params: { ...filters },
        headers: {
          Authorization: `Bearer ${tokenTask}`,
          "Content-Type": "application/json",
        },
      });

      dispatch(getWorkersSuccess(data.data));
    } catch (error: any) {
      httpErrorHandler(error, dispatch);
      dispatch(getWorkersFailure(error.message));
    }
  };

export const getWorkerUnauthorized = (id: string | number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(getWorkerClient());

    try {
      const { data } = await axios.get(`${api}/task/psychologist/`, {
        params: { id },
      });

      dispatch(
        getWorkerClientSuccess({
          data: data.data,
          isWorker: false,
        })
      );
    } catch (error: any) {
      httpErrorHandler(error, dispatch);
      dispatch(getWorkerClientFailure());
    }
  };
};

export const getWorkerAuthorized = (id: string | number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      auth: { tokenTask },
    } = getState();
    if (!tokenTask) return;

    dispatch(getWorkerClient());

    try {
      const { data } = await axios.get(`${api}/client/tutor/?id=${id}`, {
        headers: {
          Authorization: `Bearer ${tokenTask}`,
          "Content-Type": "application/json",
        },
      });

      dispatch(
        getWorkerClientSuccess({
          data: data.data,
          isWorker: false,
        })
      );
    } catch (error: any) {
      httpErrorHandler(error, dispatch);
      dispatch(
        setError({
          code: error.response?.status,
          message: error.response?.message,
        })
      );
      dispatch(getWorkerClientFailure());
    }
  };
};

export const getWorkerReviews = (
  page?: number,
  limit?: number,
  add?: boolean,
  id?: string | number
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      auth: { tokenTask },
    } = getState();
    const addMode = page?.toString() && limit?.toString() && add;
    dispatch(getReviews());

    try {
      const { data } = await axios.get(`${api}/client/tutor-review/`, {
        headers: {
          Authorization: `Bearer ${tokenTask}`,
          "Content-Type": "application/json",
        },
        params: {
          page,
          limit,
          id,
        },
      });
      addMode
        ? dispatch(getMoreReviews(data.data))
        : dispatch(getReviewsSuccess(data.data));
    } catch (error: any) {
      httpErrorHandler(error, dispatch);
      dispatch(getReviewsFailure());
    }
  };
};

export const getNotAuthWorkerReviews = (
  page?: number,
  limit?: number,
  add?: boolean,
  id?: string | number
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const addMode = page?.toString() && limit?.toString() && add;
    dispatch(getReviews());

    try {
      const { data } = await axios.get(`${api}/task/review/`, {
        params: { id, page, limit },
      });
      addMode
        ? dispatch(getMoreReviews(data.data))
        : dispatch(getReviewsSuccess(data.data));
    } catch (error: any) {
      httpErrorHandler(error, dispatch);
      dispatch(getReviewsFailure());
    }
  };
};

export const replenishmentStripe = (
  urlReturnSuccess: string,
  urlReturnCancel: string,
  tutorId: string | number
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      auth: { tokenTask },
    } = getState();
    dispatch(stripeResponce());

    const sendData = { urlReturnSuccess, urlReturnCancel, tutorId };

    try {
      const { data } = await axios.post(
        `${api}/client/pay-session/`,
        sendData,
        {
          headers: {
            Authorization: `Bearer ${tokenTask}`,
            "Content-Type": "application/json",
          },
        }
      );

      clienPayStart();

      window.location.replace(data.confirmationUrl);
    } catch (error: any) {
      dispatch(
        setError({
          code: error.response?.status,
          message: error.response?.message,
        })
      );
      dispatch(stripeResponceFailure(error.response?.message));
      httpErrorHandler(error, dispatch);
    }
  };
};

export const createChat =
  (idWorker: number) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      auth: { tokenTask },
      task: {
        channel: { isLoading, firstMessage },
      },
    } = getState();

    if (!tokenTask || isLoading || !firstMessage) return;

    dispatch(createChannel());

    try {
      const data = await axios.post<{ success: number; channelUrl: string }>(
        `${api}/client/create-chat/`,
        {
          tutor_id: idWorker,
          message: firstMessage.message,
        },
        {
          headers: {
            Authorization: `Bearer ${tokenTask}`,
          },
        }
      );

      dispatch(createChannelSuccess(data.data.channelUrl));
      clientSendMessage();
    } catch (error: any) {
      dispatch(
        setError({
          code: error?.response?.status,
          message: error?.response?.message,
        })
      );
      httpErrorHandler(error, dispatch);
      dispatch(createChannelError(error?.response?.data.message || true));
    }
  };
