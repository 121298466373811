import cn from "classnames";
import { InterviewIcon } from "src/assets/graphIcons";
import { DoubleMessage, Euro, SmileStar, Phone } from "src/assets/icons";
import { useAppSelector } from "src/hooks/redux";
import { settingsSelector } from "src/store/settingsSlice";
import Form from "./components/Form/Form";
import css from "./Interview.module.scss";

const Interview = () => {
  const { data } = useAppSelector(settingsSelector);

  return (
    <div className={css.interview}>
      <div className={cn(css.interview__hero, css.hero)}>
        <div className={css.hero__wrapper}>
          <div className={cn("container", css.hero__title)}>
            Te invitamos a participar en una entrevista online de márketing
            remunerada
          </div>

          <div className={css.hero__img}>
            <InterviewIcon />
          </div>
        </div>
      </div>

      <div className="container">
        <ul className={css.interview__list}>
          <li className={cn(css.interview__item, css.card)}>
            <DoubleMessage className={css.card__img} />

            <div
              className={cn(
                "b-content b-content_size b-content_size_sm",
                css.card__text
              )}
            >
              <p>
                Hablar con los usuarios es la única manera de mejorar nuestro
                servicio. ¡Tu participación nos será de gran ayuda!
              </p>
            </div>
          </li>

          {data?.participationInInterviewCost && (
            <li className={cn(css.interview__item, css.card)}>
              <Euro className={css.card__img} />

              <div
                className={cn(
                  "b-content b-content_size b-content_size_sm b-content_color_purple",
                  css.card__text,
                  css.card__text_color_purpul
                )}
              >
                <p>
                  Pagamos a los participantes{" "}
                  {data.participationInInterviewCost} euros por la entrevista
                  vía bizum o transferencia bancaria.
                </p>
              </div>
            </li>
          )}

          <li className={cn(css.interview__item, css.card)}>
            <SmileStar className={css.card__img} />

            <div
              className={cn(
                "b-content b-content_size b-content_size_sm",
                css.card__text
              )}
            >
              <p>
                Es completamente confidencial. Únicamente nos interesa conocer
                los aspectos de tu comportamiento como consumidor.
              </p>
            </div>
          </li>

          {data?.participationInInterviewTime && (
            <li className={cn(css.interview__item, css.card)}>
              <Phone className={css.card__img} />

              <div
                className={cn(
                  "b-content b-content_size b-content_size_sm",
                  css.card__text
                )}
              >
                <p>
                  La entrevista dura {data.participationInInterviewTime} min,
                  vía Zoom o Whatsapp video.
                </p>
              </div>
            </li>
          )}
        </ul>

        <div className={"divider"} />

        <div className={cn(css.interview__form, css.form)}>
          <h3 className={css.form__title}>participa en la entrevista</h3>
          <p className={css.form__description}>
            Rellena los siguientes campos y nos pondremos en contacto contigo
            vía Whatsapp para concertar&nbsp;la entrevista
          </p>
          <Form />
        </div>
      </div>
    </div>
  );
};

export default Interview;
