import { createSlice } from "@reduxjs/toolkit";
import {
  tokenWorker,
  tokenTask,
  LAST_AUTH_ROLE,
} from "src/constants/constants";
import { storageInteraction } from "src/helpers/storageInteraction";
import { AppDispatch, RootState } from "./strore";

export interface AuthState {
  isAuthTask: boolean;
  isAuthWorker: boolean;
  tokenTask: string | null;
  tokenWorker: string | null;
}

const initialState: AuthState = {
  isAuthTask: !!localStorage.getItem(tokenTask),
  isAuthWorker: !!localStorage.getItem(tokenWorker),
  tokenTask: localStorage.getItem(tokenTask) || null,
  tokenWorker: localStorage.getItem(tokenWorker) || null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTokenTaskState: (state: AuthState, { payload }) => {
      state.isAuthTask = true;
      state.tokenTask = payload;
    },
    setTokenWorkerState: (state: AuthState, { payload }) => {
      state.isAuthWorker = true;
      state.tokenWorker = payload;
    },
    clearTokenState: (state: AuthState) => {
      state.isAuthTask = false;
      state.isAuthWorker = false;
      state.tokenTask = null;
      state.tokenWorker = null;
    },
  },
});

export const { setTokenTaskState, setTokenWorkerState, clearTokenState } =
  authSlice.actions;

export const authSelector = (state: RootState) => state.auth;

export const clearToken = () => (dispatch: AppDispatch) => {
  localStorage.removeItem(tokenTask);
  localStorage.removeItem(tokenWorker);
  dispatch(clearTokenState());
};

export const setTokenTask = (token: string) => (dispatch: AppDispatch) => {
  localStorage.removeItem(tokenTask);
  localStorage.removeItem(tokenWorker);
  dispatch(clearTokenState());

  localStorage.setItem(tokenTask, token);
  dispatch(setTokenTaskState(token));
  // dispatch(hideRefoundPopup());

  storageInteraction(LAST_AUTH_ROLE, "client");
};

export const setTokenWorker = (token: string) => (dispatch: AppDispatch) => {
  localStorage.removeItem(tokenTask);
  localStorage.removeItem(tokenWorker);
  dispatch(clearTokenState());

  localStorage.setItem(tokenWorker, token);
  dispatch(setTokenWorkerState(token));

  storageInteraction(LAST_AUTH_ROLE, "worker");
};

export default authSlice.reducer;
