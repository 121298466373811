import React, { useEffect, useState } from "react";
import cn from "classnames";
import css from "./Profile.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getReviewsData,
  resetReviews,
  workerSelector,
} from "src/store/workerSlice";
import Preloader from "src/components/Preloader/Preloader";
import ProfileHeader from "src/components/Profile/Header/Header";
import ProfileBody from "src/components/Profile/Body/Body";
import { authSelector } from "src/store/authSlice";
import { Routes } from "src/constants/routes";
import { useHistory } from "react-router-dom";
import { useHashFragment } from "src/hooks/useHashFragment";

const commentsLimit = 3;

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const scrollToHashElement = useHashFragment(55);
  const history = useHistory();
  const { worker, isLoading, reviews } = useSelector(workerSelector);
  const { isAuthWorker } = useSelector(authSelector);

  const {
    id,
    videoYoutubeId,
    about,
    consultation,
    spec,
    education,
    languages,
    collegiate_number,
  } = worker || {};

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!isAuthWorker) {
      history.push(Routes.home);
    }

    if (isAuthWorker) {
      if (worker?.isStatus === 3) {
        history.push(Routes.worker.register.onboarding);
      }
      if (worker?.isStatus === 4) {
        history.push(Routes.worker.register.callback);
      }
      if (worker?.isStatus === 5) {
        history.push(Routes.worker.register.final);
      }

      scrollToHashElement();
    }
  }, [history, worker, isAuthWorker, scrollToHashElement]);

  const [commentsPage, setCommentsPage] = useState(1);

  useEffect(() => {
    dispatch(getReviewsData(commentsPage, 3, false));

    return () => {
      dispatch(resetReviews());
    };
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    if (commentsPage === 1) return;

    dispatch(getReviewsData(commentsPage, commentsLimit, true));
  }, [commentsPage, dispatch]);

  if (worker?.isStatus !== 1 || isLoading) {
    return <Preloader />;
  }

  return (
    <div className={css.profile}>
      <div className={cn("container", css.profile__wrapper)}>
        <ProfileHeader
          {...worker}
          onClickReviews={() => {
            history.push(`${Routes.worker.profile}#reviews`);
            scrollToHashElement();
          }}
        />

        <ProfileBody
          reviews={reviews}
          showMoreComments={() => {
            setCommentsPage(commentsPage + 1);
          }}
          about={about}
          consultation={consultation}
          education={education}
          workerId={String(id)}
          languages={languages}
          spec={spec}
          videoYoutubeId={videoYoutubeId}
          collegiate_number={collegiate_number}
        />
      </div>
    </div>
  );
};

export default Profile;
