import Review from "src/components/Review/Review";
import cn from "classnames";
import { reviewsType, showMoreCommentsType } from "../types";
import css from "./Blocks.module.scss";
import Preloader from "src/components/Preloader/Preloader";
import Button from "src/components/UI-kit/Button/Button";

const Reviews: React.FC<reviewsType & showMoreCommentsType> = ({
  reviews,
  isLoading,
  count,
  showMoreComments,
}) => {
  return (
    <div className={cn(css.block, css.reviews)}>
      <div className={css.block__title}>opiniones de clientes ({count})</div>
      <div className={cn(css.block__item, css.block__item_col_2)}>
        <div className={css.reviews__list}>
          {reviews.map((review) => (
            <Review
              data={review}
              key={review.nameClient + review.review}
              className={css.reviews__item}
            />
          ))}
        </div>

        {reviews.length < count && (
          <>
            {isLoading ? (
              <Preloader className={css.reviews__preloader} />
            ) : (
              <Button
                text={"Mostrar más"}
                onClick={showMoreComments}
                transparent
                className={css.reviews__button}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Reviews;
