import React, { useEffect, useRef, useState } from "react";
import { MenuWorkerTypes } from "./MenuWorkerTypes";
import { useHistory } from "react-router-dom";
import css from "./MenuWorker.module.scss";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { MenuArrow } from "src/assets/icons";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import { Routes } from "src/constants/routes";
import { sendLogoutWorker, workerSelector } from "src/store/workerSlice";
import avatar from "src/assets/img/user.svg";
import avatarMale from "src/assets/img/user-male.svg";
import avatarFemale from "src/assets/img/user-female.svg";
import Menu, { TControls } from "../Menu/Menu";

const MenuWorker: React.FC<MenuWorkerTypes> = ({ className }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { worker, isLoaded } = useSelector(workerSelector);

  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen((state) => !state);
  };

  useOutsideClick(dropdownRef, null, () => {
    setOpen(false);
  });

  const handleLogout = () => {
    history.push(Routes.home);
    dispatch(sendLogoutWorker());
  };

  const handleContinueRegistration = () => {
    if (worker?.isStatus === 3) {
      history.push(Routes.worker.register.onboarding);
    }
    if (worker?.isStatus === 4) {
      history.push(Routes.worker.register.callback);
    }
    if (worker?.isStatus === 5) {
      history.push(Routes.worker.register.final);
    }
  };

  const initControls = [
    {
      id: 1,
      order: 4,
      label: "Cerrar sesión",
      onClick: handleLogout,
    },
  ];

  const [controls, setControls] = useState<TControls>(initControls);

  useEffect(() => {
    if (!worker || !isLoaded) return;

    const notComleateRegistration = [
      {
        id: 2,
        order: 1,
        label: "Seguir registrándose",
        onClick: handleContinueRegistration,
      },
    ];

    const comleateRegistration = [
      {
        id: 3,
        order: 2,
        label: "Solicitudes",
        link: Routes.worker.taskList,
      },
      {
        id: 4,
        order: 3,
        label: "Tu perfil",
        link: Routes.worker.profile,
      },
    ];

    if (worker.isStatus === 1) {
      setControls([...initControls, ...comleateRegistration]);
    } else {
      setControls([...initControls, ...notComleateRegistration]);
    }
  }, [isLoaded, worker, worker?.isStatus]); // eslint-disable-line

  return (
    <div className={cn(className, css.menu, css.worker)}>
      <button
        className={cn(css[`menu-btn`], open && css.opened)}
        aria-label={menuOpen ? "Close menu" : "Open the menu"}
        onClick={handleMenuToggle}
      >
        <div className={css[`menu-btn__photo`]}>
          <img
            // TODO: вынести в helpers
            src={
              worker?.photoPathSquare ||
              worker?.photoPath ||
              (worker?.sex === 1
                ? avatarMale
                : worker?.sex === 2
                ? avatarFemale
                : avatar
                ? avatar
                : "")
            }
            alt="avatar"
            onError={(e) => {
              if (worker?.sex === 1) {
                (e.target as HTMLImageElement).src = avatarMale
                  ? avatarMale
                  : "";
              } else if (worker?.sex === 2) {
                (e.target as HTMLImageElement).src = avatarFemale
                  ? avatarFemale
                  : "";
              } else {
                (e.target as HTMLImageElement).src = avatar ? avatar : "";
              }
            }}
          />
        </div>
        <div className={cn(css[`menu-btn__open`], open && css.rotate)}>
          <MenuArrow />
        </div>
      </button>

      {menuOpen && <Menu controls={controls} onClose={handleMenuToggle} />}
    </div>
  );
};

export default MenuWorker;
