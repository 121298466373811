import { configureStore } from "@reduxjs/toolkit";
import createTaskReducer from "./createTaskSlice";
import createNewTaskReducer from "./createNewTaskSlice";
import createWorkerReducer from "./createWorkerSlice";
import workerReducer from "./workerSlice";
import taskReducer from "./taskSlice";
import messagesReducer from "./logSlice";
import layoutReducer from "./layoutSlice";
import authReducer from "./authSlice";
import newRulesReducer from "./newRulesSlice";
import referenceReducer from "./referenceSlice";
import chatReducer from "./chatSlice";
import settingsReducer from "./settingsSlice";
import questionnaireReducer from "./questionnaireSlice";
import interviewReducer from "./interviewSlice";

export const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    auth: authReducer,
    task: taskReducer,
    createTask: createTaskReducer,
    createNewTask: createNewTaskReducer,
    worker: workerReducer,
    createWorker: createWorkerReducer,
    log: messagesReducer,
    layout: layoutReducer,
    newRules: newRulesReducer,
    reference: referenceReducer,
    chat: chatReducer,
    settings: settingsReducer,
    questionnaire: questionnaireReducer,
    interview: interviewReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export interface AsyncRequest {
  isLoading: boolean;
  isLoaded: boolean;
  error: any;
}
