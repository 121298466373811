import { Routes } from "src/constants/routes";
import { IAutoLogin } from "src/store/logSlice";

export const redirectUser = (autoLogin: IAutoLogin) => {
  const utm = autoLogin.utm ? "?" + autoLogin.utm : "";
  let redirectTo = "";

  if (autoLogin.isTask) {
    if (autoLogin.chat) {
      redirectTo = `${Routes.client.chat}/${autoLogin.chat.id}${utm}`;
      return redirectTo;
    }

    if (autoLogin.redirectTo) {
      redirectTo = `${Routes.client.create.intro}${utm}`;
      return redirectTo;
    }

    redirectTo = Routes.home;
    return redirectTo;
  }

  if (autoLogin.isWorker) {
    if (autoLogin.redirectTo) {
      redirectTo = `${Routes.worker.task}/${autoLogin.redirectTo}${utm}`;
      return redirectTo;
    }

    redirectTo = Routes.worker.taskList;
    return redirectTo;
  }
};
