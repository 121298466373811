import { consultationWorkerType, languagesWorkerType } from "../types";
import css from "./Blocks.module.scss";
import cn from "classnames";

const Consultation: React.FC<consultationWorkerType & languagesWorkerType> = ({
  languages,
  weekendConsultations,
  workSchedule,
  methodsOfConsultation,
  paymentMethods,
}) => {
  return (
    <div className={css.block}>
      <div className={css.block__title}>formato de las sesiones</div>
      {!!workSchedule && (
        <div className={cn(css.block__item, css.block__item_col_2)}>
          <div className={css.block__subtitle}>Horario</div>
          <div className={css.block__desc}>{workSchedule}</div>
        </div>
      )}
      {typeof weekendConsultations === "number" && (
        <div className={cn(css.block__item, css.block__item_col_2)}>
          <div className={css.block__subtitle}>Atención en fin de semana</div>
          <div className={css.block__desc}>
            {weekendConsultations === 1 ? "Si" : "No"}
          </div>
        </div>
      )}
      {!!methodsOfConsultation?.length && (
        <div className={cn(css.block__item, css.block__item_col_2)}>
          <div className={css.block__subtitle}>Aplicaciones</div>
          <div className={css.block__desc}>
            {methodsOfConsultation.map((method) => method.name).join(", ")}
          </div>
        </div>
      )}

      {!!languages?.length && (
        <div className={cn(css.block__item, css.block__item_col_2)}>
          <div className={css.block__subtitle}>Idiomas</div>
          <div className={css.block__desc}>
            {languages.map((language) => language.name).join(", ")}
          </div>
        </div>
      )}

      {!!paymentMethods?.length && (
        <div className={cn(css.block__item, css.block__item_col_2)}>
          <div className={css.block__subtitle}>Método de pago</div>
          <div className={css.block__desc}>
            {paymentMethods.map((method) => method.name).join(", ")}
          </div>
        </div>
      )}
    </div>
  );
};

export default Consultation;
