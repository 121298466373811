import { FC, useEffect, useRef, useState } from "react";
import cn from "classnames";
import css from "./InterviewPopup.module.scss";
import Modal from "src/components/Modal/Modal";
import { MessageMoney } from "src/assets/graphIcons";
import { Form, Formik, FormikErrors, FormikProps } from "formik";
import InternationalPhone from "src/components/UI-kit/InternationalPhone/InternationalPhone";
import Button from "src/components/UI-kit/Button/Button";
import { useDispatch } from "react-redux";
import FieldSelect from "src/components/UI-kit/FieldSelect/FieldSelect";
import { isEntryInterview } from "src/constants/constants";
import FieldTextArea from "src/components/UI-kit/FieldTextArea/FieldTextArea";
import { IInterviewPopupFormData } from "src/types/interview";
import {
  interviewPopupSelector,
  sendDataInterviewPopup,
} from "src/store/interviewSlice";
import { useAppSelector } from "src/hooks/redux";
import { IInterviewPopupProps } from "./InterviewPopupTypes";
import FieldChange from "src/components/UI-kit/FieldChange/FieldChange";
import { naPopupInterviewFormSend } from "src/helpers/metric";

const initData = {
  isEntry: "",
  steps: "",
  cause: "",
  phone: "",
};

const InterviewPopup: FC<IInterviewPopupProps> = ({
  onClose,
  timeInterview,
  costInterview,
}) => {
  const dispatch = useDispatch();

  const { isLoading, error, isLoaded } = useAppSelector(interviewPopupSelector);
  const [err, setErr] = useState<FormikErrors<IInterviewPopupFormData>>({});
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const formikRef = useRef<FormikProps<IInterviewPopupFormData>>(null);
  const [data, setData] = useState<IInterviewPopupFormData>(initData);

  const validate = (values: IInterviewPopupFormData) => {
    const errors: FormikErrors<IInterviewPopupFormData> = {};

    if (!values.isEntry) {
      errors.isEntry = "Campo obligatorio";
    } else {
      if (values.isEntry !== "4") {
        if (values.steps.trim().length < 100) {
          errors.steps = "Explícalo usando al menos 100 caracteres";
        }
      } else {
        if (values.cause.trim().length < 100) {
          errors.cause = "Explícalo usando al menos 100 caracteres";
        }
      }
    }

    if (values.phone === "INCORRECT") {
      errors.phone = "Formato erróneo";
    }

    return errors;
  };

  useEffect(() => {
    if (isLoaded && !error) {
      naPopupInterviewFormSend();
      onClose();
    }
  }, [isLoaded, error]); // eslint-disable-line

  useEffect(() => {
    const msgErrors = {
      isEntry: {
        has: !!err.isEntry,
        text: "momento de consulta",
      },
      steps: {
        has: !err.isEntry && data.isEntry !== "4" && !!err.steps,
        text: "proceso de encontrar un psicólogo",
      },
      cause: {
        has: !err.isEntry && data.isEntry === "4" && !!err.cause,
        text: "razón de visita",
      },
    };

    const errors = Object.values(msgErrors).filter((m) => m.has);

    if (errors.length) {
      setFormErrorMessage(
        `Para continuar completa: ` + errors.map((m) => m.text).join(", ") + "."
      );
    } else {
      setFormErrorMessage("");
    }
  }, [err]); //  eslint-disable-line

  useEffect(() => {
    const { current: formik } = formikRef;

    if (data.isEntry !== "4") {
      formik?.setFieldValue("cause", "");
      data.steps && formik?.setFieldTouched("steps");
    } else {
      formik?.setFieldValue("steps", "");
      data.cause && formik?.setFieldTouched("cause");
    }
  }, [data]);

  const handleSubmit = (data: IInterviewPopupFormData) => {
    dispatch(sendDataInterviewPopup(data));
  };

  return (
    <Modal active={true} setActive={onClose} type="form" className={css.offer}>
      <div className={css.offer__icon}>
        <MessageMoney />
      </div>

      <h2 className={cn(css.offer__title, "h2")}>
        danos {timeInterview} minutos, Gana {costInterview} EUROS
      </h2>

      <p className={css.offer__description}>
        Nos gustaría saber más de nuestros usuarios. ¡Por favor, ayúdanos!
        Premiamos diariamente vía Bizum al autor con la respuesta que se
        entienda mejor.
      </p>

      <Formik
        initialValues={initData}
        onSubmit={handleSubmit}
        validate={validate}
        enableReinitialize
        innerRef={formikRef}
      >
        {({ dirty, errors, values }: FormikProps<IInterviewPopupFormData>) => (
          <Form className={css.form}>
            <FieldSelect
              className={css.form__field}
              label="¿Cuándo te gustaría tener la consulta con el psicólogo?"
              name="isEntry"
              options={isEntryInterview}
              required
            />

            {values.isEntry && values.isEntry !== "4" && (
              <FieldTextArea
                className={cn(css.form__field, css.form__textarea)}
                name="steps"
                label="¿Describe cuál sería tu itinerario ideal a partir de ahora hasta la primera consulta con el psicólogo?"
                placeholder="Explícalo como si te dirigieras a una persona que no supiera nada de este tema..."
                required
              />
            )}

            {values.isEntry === "4" && (
              <FieldTextArea
                className={cn(css.form__field, css.form__textarea)}
                name="cause"
                label="¿Si no estas interesado en empezar la terapia cuál es la razón de tu visita a nuestra página web?"
                placeholder="Explícalo como si te dirigieras a una persona que no supiera nada de este tema..."
                required
              />
            )}

            <InternationalPhone
              placeholder="Teléfono conectado a Bizum"
              className={cn(css.form__field)}
              name="phone"
            />

            <Button
              className={css.form__submit}
              disabled={!dirty || isLoading || !!Object.keys(errors).length}
              type="submit"
              text="Enviar"
            />

            <div className={css.form__agreement}>
              Al enviar, acepto las{" "}
              <a
                href={
                  "https://www.iubenda.com/privacy-policy/83721225/full-legal"
                }
                target="_blank"
                rel="noreferrer"
              >
                condiciones de uso y la política de privacidad
              </a>
            </div>

            {!!formErrorMessage.length && (
              <div className={css.form__alert}>
                <p>{formErrorMessage}</p>
              </div>
            )}

            <FieldChange<IInterviewPopupFormData>
              onChange={setData}
              onError={setErr}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default InterviewPopup;
