import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState, AsyncRequest } from "./strore";
import { api } from "../constants/api";
import axios from "axios";
import { addMessage } from "./logSlice";
import { setTokenWorker } from "./authSlice";
import {
  verificationPsychologist,
  saveDataPsychologist,
} from "src/helpers/metric";
import httpErrorHandler from "./httpErrorHandler";
import { storageInteraction } from "src/helpers/storageInteraction";
import { UTM_TAGS } from "src/constants/constants";

export interface IDistrict {
  id: number;
  name: string;
  locativeName: string;
}
interface IDistricts extends AsyncRequest {
  data: IDistrict[];
}

export interface IAddition {
  id: number;
  name: string;
  nameDative: string;
  nameGen: string;
}
interface IAdditions extends AsyncRequest {
  data: IAddition[];
}

export interface ICreateWorkerState {
  phone: string;
  form: AsyncRequest;
  saveData: AsyncRequest;
  confirm: AsyncRequest;
  confirmRequest: AsyncRequest;
  signup: AsyncRequest;
  step1: AsyncRequest;
  step2: AsyncRequest;
  districts: {
    [k: string]: IDistricts;
  };
  additions: {
    [k: string]: IAdditions;
  };
  options: {
    areaId?: number;
    subjects?: {
      id: number;
      name: string;
    }[];
    isHome?: boolean;
    isExternal?: boolean;
    isOnline?: boolean;
  };
  modalWaitingList: boolean;
}

const initialState: ICreateWorkerState = {
  phone: "",
  form: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  saveData: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  confirm: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  confirmRequest: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  signup: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  step1: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  step2: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  districts: {},
  additions: {},
  options: {},
  modalWaitingList: false,
};

export const createWorkerSlice = createSlice({
  name: "create-worker",
  initialState,
  reducers: {
    setOptions: (state: ICreateWorkerState, { payload }) => {
      state.options = { ...state.options, ...payload };
    },
    setPhone: (state: ICreateWorkerState, { payload }) => {
      state.phone = payload;
    },
    setPhoneInit: (state: ICreateWorkerState, { payload }) => {
      state.phone = "";
    },

    sendForm: (state: ICreateWorkerState) => {
      state.form.isLoading = true;
      state.form.isLoaded = false;
      state.form.error = null;
    },
    sendFormSuccess: (state: ICreateWorkerState) => {
      state.form.isLoading = false;
      state.form.isLoaded = true;
      state.form.error = null;
    },
    sendFormFailure: (state: ICreateWorkerState, { payload }) => {
      state.form.isLoading = false;
      state.form.isLoaded = true;
      state.form.error = payload;
    },
    sendFormInit: (state: ICreateWorkerState) => {
      state.form.isLoading = false;
      state.form.isLoaded = false;
      state.form.error = null;
    },

    sendSaveData: (state: ICreateWorkerState) => {
      state.saveData.isLoading = true;
      state.saveData.isLoaded = false;
      state.saveData.error = null;
    },
    sendSaveDataSuccess: (state: ICreateWorkerState) => {
      state.saveData.isLoading = false;
      state.saveData.isLoaded = true;
      state.saveData.error = null;
    },
    sendSaveDataFailure: (state: ICreateWorkerState, { payload }) => {
      state.saveData.isLoading = false;
      state.saveData.isLoaded = true;
      state.saveData.error = payload;
    },
    sendSaveDataInit: (state: ICreateWorkerState) => {
      state.saveData.isLoading = false;
      state.saveData.isLoaded = false;
      state.saveData.error = null;
    },

    confirmCode: (state: ICreateWorkerState) => {
      state.confirm.isLoading = true;
      state.confirm.isLoaded = false;
      state.confirm.error = null;
    },
    confirmCodeSuccess: (state: ICreateWorkerState) => {
      state.confirm.isLoading = false;
      state.confirm.isLoaded = true;
      state.confirm.error = null;
    },
    confirmCodeFailure: (state: ICreateWorkerState, { payload }) => {
      state.confirm.isLoading = false;
      state.confirm.isLoaded = true;
      state.confirm.error = payload;
    },
    confirmCodeInit: (state: ICreateWorkerState) => {
      state.confirm.isLoading = false;
      state.confirm.isLoaded = false;
      state.confirm.error = null;
    },

    confirmRequestCode: (state: ICreateWorkerState) => {
      state.confirmRequest.isLoading = true;
      state.confirmRequest.isLoaded = false;
      state.confirmRequest.error = null;
    },
    confirmRequestCodeSuccess: (state: ICreateWorkerState) => {
      state.confirmRequest.isLoading = false;
      state.confirmRequest.isLoaded = true;
      state.confirmRequest.error = null;
    },
    confirmRequestCodeFailure: (state: ICreateWorkerState, { payload }) => {
      state.confirmRequest.isLoading = false;
      state.confirmRequest.isLoaded = true;
      state.confirmRequest.error = payload;
    },

    sendFormStep1: (state: ICreateWorkerState) => {
      state.step1.isLoading = true;
      state.step1.isLoaded = false;
      state.step1.error = null;
    },
    sendFormStep1Success: (state: ICreateWorkerState) => {
      state.step1.isLoading = false;
      state.step1.isLoaded = true;
      state.step1.error = null;
    },
    sendFormStep1Failure: (state: ICreateWorkerState, { payload }) => {
      state.step1.isLoading = false;
      state.step1.isLoaded = true;
      state.step1.error = payload;
    },
    sendFormStep1Init: (state: ICreateWorkerState) => {
      state.step1.isLoading = false;
      state.step1.isLoaded = false;
      state.step1.error = null;
    },

    sendFormSignup: (state: ICreateWorkerState) => {
      state.signup.isLoading = true;
      state.signup.isLoaded = false;
      state.signup.error = null;
    },
    sendFormSignupSuccess: (state: ICreateWorkerState) => {
      state.signup.isLoading = false;
      state.signup.isLoaded = true;
      state.signup.error = null;
    },
    sendFormSignupFailure: (state: ICreateWorkerState, { payload }) => {
      state.signup.isLoading = false;
      state.signup.isLoaded = true;
      state.signup.error = payload;
    },
    sendFormSignupInit: (state: ICreateWorkerState) => {
      state.signup.isLoading = false;
      state.signup.isLoaded = false;
      state.signup.error = null;
    },

    sendFormStep2: (state: ICreateWorkerState) => {
      state.step2.isLoading = true;
      state.step2.isLoaded = false;
      state.step2.error = null;
    },
    sendFormStep2Success: (state: ICreateWorkerState) => {
      state.step2.isLoading = false;
      state.step2.isLoaded = true;
      state.step2.error = null;
    },
    sendFormStep2Failure: (state: ICreateWorkerState, { payload }) => {
      state.step2.isLoading = false;
      state.step2.isLoaded = true;
      state.step2.error = payload;
    },
    sendFormStep2Init: (state: ICreateWorkerState) => {
      state.step2.isLoading = false;
      state.step2.isLoaded = false;
      state.step2.error = null;
    },

    getDistricts: (state: ICreateWorkerState, { payload: { id } }) => {
      state.districts[id] = {
        isLoading: true,
        isLoaded: false,
        error: null,
        data: [],
      };
    },
    getDistrictsSuccess: (
      state: ICreateWorkerState,
      { payload: { id, data } }
    ) => {
      state.districts[id].isLoading = false;
      state.districts[id].isLoaded = true;
      state.districts[id].error = null;
      state.districts[id].data = [...data];
    },
    getDistrictsFailure: (
      state: ICreateWorkerState,
      { payload: { id, data } }
    ) => {
      state.districts[id].isLoading = false;
      state.districts[id].isLoaded = true;
      state.districts[id].error = data;
    },

    getAdditions: (state: ICreateWorkerState, { payload: { id } }) => {
      state.additions[id] = {
        isLoading: true,
        isLoaded: false,
        error: null,
        data: [],
      };
    },
    getAdditionsSuccess: (
      state: ICreateWorkerState,
      { payload: { id, data } }
    ) => {
      state.additions[id].isLoading = false;
      state.additions[id].isLoaded = true;
      state.additions[id].error = null;
      state.additions[id].data = [...data];
    },
    getAdditionsFailure: (
      state: ICreateWorkerState,
      { payload: { id, data } }
    ) => {
      state.additions[id].isLoading = false;
      state.additions[id].isLoaded = true;
      state.additions[id].error = data;
    },

    showModalWaitingList: (state: ICreateWorkerState) => {
      state.modalWaitingList = true;
    },
    hideModalWaitingList: (state: ICreateWorkerState) => {
      state.modalWaitingList = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setOptions,

  setPhone,
  setPhoneInit,

  sendForm,
  sendFormSuccess,
  sendFormFailure,
  sendFormInit,

  sendSaveData,
  sendSaveDataSuccess,
  sendSaveDataFailure,
  sendSaveDataInit,

  confirmCode,
  confirmCodeSuccess,
  confirmCodeFailure,
  confirmCodeInit,

  confirmRequestCode,
  confirmRequestCodeSuccess,
  confirmRequestCodeFailure,

  sendFormSignup,
  sendFormSignupSuccess,
  sendFormSignupFailure,
  sendFormSignupInit,

  sendFormStep1,
  sendFormStep1Success,
  sendFormStep1Failure,
  sendFormStep1Init,

  sendFormStep2,
  sendFormStep2Success,
  sendFormStep2Failure,
  sendFormStep2Init,

  getDistricts,
  getDistrictsSuccess,
  getDistrictsFailure,

  getAdditions,
  getAdditionsSuccess,
  getAdditionsFailure,

  showModalWaitingList,
  hideModalWaitingList,
} = createWorkerSlice.actions;

export const createWorkerSelector = (state: RootState) => state.createWorker;
export const createWorkerOptionsSelector = (state: RootState) =>
  state.createWorker.options;
export const createWorkerPhoneSelector = (state: RootState) =>
  state.createWorker.phone;
export const createWorkerFormSelector = (state: RootState) =>
  state.createWorker.form;
export const createWorkerSaveDataSelector = (state: RootState) =>
  state.createWorker.saveData;
export const createWorkerConfirmSelector = (state: RootState) =>
  state.createWorker.confirm;
export const createWorkerConfirmRequestSelector = (state: RootState) =>
  state.createWorker.confirmRequest;
export const createWorkerSignupSelector = (state: RootState) =>
  state.createWorker.signup;
export const createWorkerStep1Selector = (state: RootState) =>
  state.createWorker.step1;
export const createWorkerStep2Selector = (state: RootState) =>
  state.createWorker.step2;
export const createWorkerDistrictsSelector = (state: RootState) =>
  state.createWorker.districts;
export const createWorkerAdditionsSelector = (state: RootState) =>
  state.createWorker.additions;
export const createWorkereModalWaitingListSelector = (state: RootState) =>
  state.createWorker.modalWaitingList;

export default createWorkerSlice.reducer;

export const sendFormData = (sendData: any) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(sendForm());

    try {
      await axios.post(`${api}/executor-create/`, sendData);
      dispatch(sendFormSuccess());
      // workerCreate();
    } catch (error: any) {
      dispatch(sendFormFailure(error));
      httpErrorHandler(error, dispatch);
    }
  };
};

export const sendDataSave = (sendData: any) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      auth: { tokenWorker },
    } = getState();
    dispatch(sendSaveData());

    try {
      await axios.post(`${api}/tutor/data-save/`, sendData, {
        headers: {
          Authorization: `Bearer ${tokenWorker}`,
          "Content-Type": "application/json",
        },
      });
      dispatch(sendSaveDataSuccess());
      // dispatch(showModalWaitingList());
      saveDataPsychologist();
      // workerCreate();
    } catch (error: any) {
      dispatch(sendSaveDataFailure(error));
      httpErrorHandler(error, dispatch);
    }
  };
};

export const sendCode = (sendData: object) => {
  return async (dispatch: AppDispatch) => {
    dispatch(confirmCode());

    try {
      const { data } = await axios.post(
        `${api}/tutor/confirmation-phone/`,
        sendData
      );
      // @ts-ignore
      dispatch(setTokenWorker(data.auth.token));
      dispatch(confirmCodeSuccess());
      dispatch(
        addMessage({
          type: "success",
          message: `Tu teléfono ha sido verificado.`,
        })
      );
      verificationPsychologist();
    } catch (error: any) {
      dispatch(confirmCodeFailure(error));
      httpErrorHandler(error, dispatch);
    }
  };
};

export const sendRequestCode = (sendData: object) => {
  return async (dispatch: AppDispatch) => {
    dispatch(confirmRequestCode());

    try {
      await axios.post(`${api}/tutor/send-confirmation-phone/`, sendData);
      dispatch(confirmRequestCodeSuccess());
    } catch (error: any) {
      dispatch(confirmRequestCodeFailure(error));
      httpErrorHandler(error, dispatch);
    }
  };
};

export const sendFormDataSignup = (sendData: object) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      auth: { tokenWorker },
    } = getState();
    dispatch(sendFormSignup());

    const utmTags = (storageInteraction(UTM_TAGS) || {}) as Record<
      string,
      string
    >;

    try {
      await axios.post(
        `${api}/tutor/signup/`,
        { ...sendData, ...utmTags },
        {
          headers: {
            Authorization: `Bearer ${tokenWorker}`,
            "Content-Type": "application/json",
          },
        }
      );
      dispatch(sendFormSignupSuccess());
    } catch (error: any) {
      dispatch(sendFormSignupFailure(error));
      httpErrorHandler(error, dispatch);
      dispatch(
        addMessage({
          type: "error",
          message: `Ya existe una cuenta con este teléfono. Inicia sesión <a href='${process.env.PUBLIC_URL}/worker/auth'>aqui</a>`,
          logIn: true,
        })
      );
    }
  };
};

export const sendFormStep1Data = (sendData: any) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      auth: { tokenWorker },
    } = getState();
    dispatch(sendFormStep1());

    try {
      await axios.post(`${api}/tutor/signup-one-step/`, sendData, {
        headers: {
          Authorization: `Bearer ${tokenWorker}`,
          "Content-Type": "application/json",
        },
      });
      dispatch(sendFormStep1Success());
      dispatch(
        setOptions({
          isOnline: sendData.online === 1,
          isExternal:
            sendData.offline === 1 &&
            sendData.addresses.findIndex((a: any) => a.isDeparting) !== -1,
          isHome:
            sendData.offline === 1 &&
            sendData.addresses.findIndex((a: any) => a.isLessonsConducted) !==
              -1,
        })
      );
    } catch (error: any) {
      dispatch(sendFormStep1Failure(error));
      httpErrorHandler(error, dispatch);
    }
  };
};

export const sendFormStep2Data = (sendData: object) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      auth: { tokenWorker },
    } = getState();
    dispatch(sendFormStep2());

    try {
      await axios.post(`${api}/tutor/signup-two-step/`, sendData, {
        headers: {
          Authorization: `Bearer ${tokenWorker}`,
          "Content-Type": "application/json",
        },
      });
      dispatch(sendFormStep2Success());
    } catch (error: any) {
      dispatch(sendFormStep2Failure(error));
      httpErrorHandler(error, dispatch);
    }
  };
};

export const getDistrictsData = (id: number | string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getDistricts({ id }));

    try {
      const { data } = await axios.get(
        `${api}/reference/districts-list/?id=${id}`
      );
      dispatch(getDistrictsSuccess({ id, data: data.list }));
    } catch (error) {
      httpErrorHandler(error, dispatch);
      dispatch(getDistrictsFailure({ id, data: error }));
    }
  };
};

export const getAdditionsData = (id: number | string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getAdditions({ id }));

    try {
      const { data } = await axios.get(
        `${api}/reference/additions-list/?id=${id}`
      );
      dispatch(getAdditionsSuccess({ id, data: data.list }));
    } catch (error) {
      httpErrorHandler(error, dispatch);
      dispatch(getAdditionsFailure({ id, data: error }));
    }
  };
};
