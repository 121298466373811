import React from "react";
import css from "./Empty.module.scss";
import { EmptyTypes } from "./EmptyTypes";
import cn from "classnames";
import { MessageSmartphone } from "src/assets/graphIcons";

const Empty: React.FC<EmptyTypes> = ({ className, desc, title, icon }) => {
  return (
    <div className={cn(className, css.empty)}>
      <div className={css[`empty-icon`]}>{icon || <MessageSmartphone />}</div>
      <div className={css[`empty-title`]}>{title || "Está vacío"}</div>
      <div className={css[`empty-desc`]}>
        {desc || "Pronto habrá información aquí"}
      </div>
    </div>
  );
};

export default Empty;
