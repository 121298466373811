import { FC, useEffect, useState } from "react";
import css from "./Registration.module.scss";
import cn from "classnames";
import InternationalPhone from "src/components/UI-kit/InternationalPhone/InternationalPhone";
import SuccessMessage from "src/components/SuccessMessage/SuccessMessage";
import FieldText from "src/components/UI-kit/FieldText/FieldText";
import { Formik, Form as FormikForm, FormikErrors, FormikProps } from "formik";
import Button from "src/components/UI-kit/Button/Button";
import {
  createTaskFormSelector,
  IRegister,
  sendCreateTaskData,
  setIds,
  setRegister,
} from "src/store/createNewTaskSlice";
import { Routes } from "src/constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { naCreateClient, naOpenRegistrationPopup } from "src/helpers/metric";
import Modal from "src/components/Modal/Modal";

interface IRegistrationProps {
  workerId: string;
  workerName: string;
  onClose: () => void;
}

const Registration: FC<IRegistrationProps> = ({
  workerId,
  workerName,
  onClose,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading, isLoaded, error, taskId } = useSelector(
    createTaskFormSelector
  );
  const [openModal, setOpenModal] = useState<boolean>(true);

  const initData: IRegister = {
    phone: "",
    name: "",
  };

  useEffect(() => {
    naOpenRegistrationPopup();
  }, []);

  // Логика переадресации на страницу подтверждения телефона
  useEffect(() => {
    if (isLoaded && error === null && !!taskId) {
      dispatch(
        setIds({
          taskId,
          workerId,
        })
      );

      history.push(Routes.client.create.verification);
    }
  }, [isLoaded, error, taskId]); // eslint-disable-line

  useEffect(() => {
    if (!openModal) {
      onClose();
    }
  }, [openModal]); // eslint-disable-line

  const validate = (values: IRegister) => {
    const errors: FormikErrors<IRegister> = {};

    if (!values.phone) {
      errors.phone = "Campo obligatorio";
    } else if (values.phone === "INCORRECT") {
      errors.phone = "Formato erróneo";
    }

    if (!values.name.trim()) {
      errors.name = "Campo obligatorio";
    } else if (values.name.trim().length < 2) {
      errors.name = "Formato erróneo";
    }

    return errors;
  };

  const handleSubmit = (data: IRegister) => {
    dispatch(setRegister({ ...data }));
    dispatch(sendCreateTaskData());
    naCreateClient();
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Modal
      active={openModal}
      onCancel={handleClose}
      setActive={handleClose}
      type="form"
      className={css.offer}
    >
      <div className={css.registration}>
        <h3 className={css.registration__title}>
          le Mandamos tu mensaje a{" "}
          <span className={css.registration__title_purpul}>{workerName}</span>{" "}
          en cuanto verifiques tu teléfono
        </h3>

        <Formik
          initialValues={initData}
          onSubmit={handleSubmit}
          validate={validate}
        >
          {({ errors }: FormikProps<IRegister>) => (
            <FormikForm className={cn(css.step__wrap, css.form)}>
              <div
                className={css.form__group}
                ref={(element) => {
                  element?.focus();
                }}
              >
                <SuccessMessage
                  className={cn(css.form__field, css.form__field_mb_sm)}
                >
                  Protegemos a los psicólogos de posibles estafas
                </SuccessMessage>
                <SuccessMessage
                  className={cn(css.form__field, css.form__field_mb_sm)}
                >
                  El psicólogo no verá tu número
                </SuccessMessage>
                <SuccessMessage
                  className={cn(css.form__field, css.form__field_mb_sm)}
                >
                  No te enviaremos spam
                </SuccessMessage>

                <InternationalPhone
                  className={cn(css.form__field, css.form__field_mb_md)}
                  name="phone"
                />

                <FieldText
                  className={css.form__field}
                  name="name"
                  label="Tu nombre"
                  placeholder="Introduce"
                  required
                />

                <Button
                  className={css.form__submit}
                  disabled={!!Object.keys(errors).length || isLoading}
                  type="submit"
                  text="Enviar"
                />

                <div className={css.form__agreement}>
                  <p>
                    Al enviar, acepto las{" "}
                    <a
                      href={
                        "https://www.iubenda.com/privacy-policy/83721225/full-legal"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      condiciones de uso y la política de privacidad
                    </a>
                  </p>
                </div>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default Registration;
