import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store/strore";
import TagManager from "react-gtm-module";
import SendBird from "sendbird";
import { BrowserRouter } from "react-router-dom";

if (process.env.NODE_ENV === "production") {
  const tagManagerArgs = {
    gtmId: "GTM-MJX2WJQ",
  };

  TagManager.initialize(tagManagerArgs);
}

if (process.env.NODE_ENV === "development") {
  // @ts-ignore
  window["_ym_debug"] = 1;
}

export const sendBird = new SendBird({
  appId: process.env.REACT_APP_SENDBIRD_KEY as string,
  // localCacheEnabled: true,
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
