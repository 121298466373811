import React, { ReactChild, ReactChildren } from "react";
import cn from "classnames";
import css from "./SuccessMessage.module.scss";
import { ReactComponent as SuccessLine } from "src/assets/img/icons/Systems/success-line.svg";

interface SuccessMessageProps {
  className?: string;
  children?: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  text?: string;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({
  className,
  children,
  text,
}) => {
  return (
    <div className={cn(className, css.message)}>
      <SuccessLine className={css.message__icon} />

      <div className={cn(css.message__desc, "b-content", "b-content_size_xs")}>
        {!!text && <p>{text}</p>}
        {children}
      </div>
    </div>
  );
};

export default SuccessMessage;
