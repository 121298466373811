import React from "react";
import cn from "classnames";
import { FieldHookConfig, useField } from "formik";
import css from "./FieldTextArea.module.scss";

interface FieldTextProps {
  className?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  onChange?: (value: any) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

const FieldTextArea = (props: FieldHookConfig<string> & FieldTextProps) => {
  const { className, label, required, type, placeholder, ...config } = props;
  const [field, meta] = useField(config);

  return (
    <label className={cn(className, css.textarea)}>
      {label && (
        <div className={css[`textarea-label`]}>
          {`${label}${required ? "*" : ""}`}
        </div>
      )}
      <textarea
        className={cn({ [css.error]: meta.touched && !!meta.error })}
        rows={5}
        placeholder={placeholder}
        required={required}
        autoComplete={"off"}
        {...field}
      />

      {meta.touched && !!meta.error ? (
        <div className={cn(css[`textarea-error`], css.show)}>{meta.error}</div>
      ) : null}
    </label>
  );
};

export default FieldTextArea;
