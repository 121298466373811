import { FC } from "react";
import { StepProps } from "./DataTypes";
import cn from "classnames";
import css from "./Data.module.scss";

import Button from "src/components/UI-kit/Button/Button";
import { Routes } from "src/constants/routes";

const Data: FC<StepProps> = ({ className, onLink }) => {
  return (
    <div className={cn(className, css.data)}>
      <div className={css.data__list}>
        <div className={css.data__item}>
          <div className={css.data__title}>
            SIN
            <br />
            SUSCRIPCIÓN
          </div>
          <div className={css.data__desc}>
            No hay ninguna cuota mensual. Cobramos a los psicólogos solo por
            resultados — nuevos pacientes
          </div>
        </div>

        <div className={css.data__item}>
          <div className={css.data__title}>Solo online</div>
          <div className={css.data__desc}>
            Captamos clientes que busquen sesiones a distancia. ¡Trabaja desde
            cualquier lugar a cualquier hora!
          </div>
        </div>

        <div className={css.data__item}>
          <div className={css.data__title}>tú pones las condiciones</div>
          <div className={css.data__desc}>
            Nos encargamos del márketing. Tú estableces tarifa, duración de
            consultas, horario y tipo de aplicación
          </div>
        </div>
      </div>

      <Button
        text={"Registrarse"}
        className={css.data__button}
        onClick={onLink}
        asLink
        to={Routes.worker.register.form}
      />
    </div>
  );
};

export default Data;
