import { useEffect, useState } from "react";

export const useMountVisible = () => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    timer = setTimeout(() => {
      setVisible(true);
    }, 10);

    return () => {
      timer && clearInterval(timer);
    };
  }, []);

  return visible;
};
