import "./App.module.scss";
import "./App.scss";
import Layout from "./Layout/Layout";

import { useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { authSelector } from "src/store/authSlice";
import { getWorkerData, workerSelector } from "src/store/workerSlice";

import {
  updateYmClientId,
  userUnauthorized,
  visitStatus,
} from "src/helpers/metric";
import { hitFunction } from "src/helpers/metric";
import { Routes } from "src/constants/routes";

import {
  getTaskData,
  resetChannelFirstMessage,
  resetError,
  taskSelector,
} from "src/store/taskSlice";

import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { logSelector } from "./store/logSlice";
import { getSettings } from "./store/settingsSlice";
import { disconnect, сonnection } from "./store/chatSlice";
import { cleareCash } from "./helpers/cleareCash";
import { redirectUser } from "./helpers/redirectUser";
import { storageInteraction } from "./helpers/storageInteraction";
import { LAST_AUTH_ROLE, UTM_TAGS } from "./constants/constants";
import { useScript } from "./hooks/useScript";

function App() {
  useScript();
  const [prevHref, setPrevHref] = useState(window.location.href);
  const { isAuthTask, isAuthWorker } = useSelector(authSelector);
  const history = useHistory();
  const { task, error } = useSelector(taskSelector);
  const { worker } = useSelector(workerSelector);
  const { autoLogin } = useSelector(logSelector);
  const [redirectTo, setRedirectTo] = useState<string>();

  const dispatch = useDispatch();

  const location = useLocation();

  // Обработка ошибок
  useEffect(() => {
    if (error && error.code === 401) {
      dispatch(resetError());
      history.push(Routes.home);
      return;
    }
  }, [dispatch, history, error]);

  useEffect(() => {
    const oldUtmTags = (storageInteraction(UTM_TAGS) || {}) as Record<
      string,
      string
    >;
    const query = new URLSearchParams(
      decodeURIComponent(location.search.replace(/&amp;/g, "&"))
    );
    const [utm_source, utm_medium, utm_campaign, utm_content, utm_term] = [
      query.get("utm_source"),
      query.get("utm_medium"),
      query.get("utm_campaign"),
      query.get("utm_content"),
      query.get("utm_term"),
    ];

    storageInteraction(UTM_TAGS, {
      utm_source: utm_source ? utm_source : oldUtmTags?.utm_source,
      utm_medium: utm_medium ? utm_medium : oldUtmTags?.utm_medium,
      utm_campaign: utm_campaign ? utm_campaign : oldUtmTags?.utm_campaign,
      utm_content: utm_content ? utm_content : oldUtmTags?.utm_content,
      utm_term: utm_term ? utm_term : oldUtmTags?.utm_term,
    });
  }, [location]);

  useEffect(() => {
    if (isAuthTask) {
      visitStatus("client");
    } else if (isAuthWorker) {
      visitStatus("worker");
    } else {
      visitStatus("unauthorized");
    }
  }, [location.pathname]); // eslint-disable-line

  useEffect(() => {
    // Очищаю кэш
    if (
      location.pathname === Routes.client.create.filters ||
      location.pathname === Routes.client.workers
    ) {
      cleareCash();

      history.push(
        location.pathname === Routes.client.create.filters
          ? Routes.home
          : Routes.client.create.intro
      );
    }

    if (
      location.pathname !== Routes.client.create.verification &&
      location.pathname !== Routes.client.chat
    ) {
      dispatch(resetChannelFirstMessage());
    }
  }, [dispatch, history, location]);

  useEffect(() => {
    !!redirectTo && history.push(redirectTo);
  }, [history, redirectTo]);

  useEffect(() => {
    updateYmClientId();
    dispatch(getSettings());

    if (!storageInteraction(LAST_AUTH_ROLE)) {
      userUnauthorized();
    }
  }, [dispatch]);

  useEffect(() => {
    if (task && isAuthTask) {
      dispatch(сonnection(task.idSendbird));
    }

    if (worker && isAuthWorker) {
      worker.isStatus === 1 && dispatch(сonnection(worker.idSendbird));
    }
  }, [task, worker, dispatch]); // eslint-disable-line

  useEffect(() => {
    return () => {
      if (isAuthTask || isAuthWorker) {
        dispatch(disconnect());
      }
    };
  }, [dispatch, isAuthTask, isAuthWorker]);

  useEffect(() => {
    const re = new RegExp(/^[A-z0-9]{8}$/);
    const path = location.pathname.replace(Routes.home, "");

    if (!re.test(path)) {
      hitFunction({
        url: window.location.href,
        referer: prevHref,
        title: document.title,
      });
      setPrevHref(window.location.href);
    }
  }, [location.pathname]); // eslint-disable-line

  // Редирект после авторизации по короткой ссылке
  useEffect(() => {
    setRedirectTo(redirectUser(autoLogin));
  }, [history, autoLogin]);

  // Получаю основную информацию о пользователе
  useEffect(() => {
    if (isAuthTask) dispatch(getTaskData());
    if (isAuthWorker) dispatch(getWorkerData());
  }, [isAuthTask, isAuthWorker]); // eslint-disable-line react-hooks/exhaustive-deps

  return <Layout />;
}

// Тестовый комментарий для запуска CD с гита
export default App;
