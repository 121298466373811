import React, { useEffect } from "react";
import cn from "classnames";

import { LastStepTypes } from "./LastStepTypes";
import {
  ManFeedback,
  Clock,
  Camera,
  Video,
  HomeStar,
  ManSquare,
  ManWithStar,
} from "src/assets/icons";
import man from "src/assets/img/man-reference.png";
import line1 from "src/assets/img/line1.svg";
import line2 from "src/assets/img/line2.svg";
import line3 from "src/assets/img/line3.svg";

import css from "./LastStep.module.scss";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { workerSelector } from "src/store/workerSlice";
import { authSelector } from "src/store/authSlice";
import { Routes } from "src/constants/routes";
import Preloader from "src/components/Preloader/Preloader";

const LastStep: React.FC<LastStepTypes> = () => {
  const history = useHistory();
  const { worker, isLoaded, isLoading } = useSelector(workerSelector);
  const { isAuthWorker } = useSelector(authSelector);

  useEffect(() => {
    if (!isAuthWorker) {
      history.push(Routes.home);
    }
    if (isAuthWorker && worker?.isStatus !== 5 && isLoaded && !isLoading) {
      if (worker?.isStatus === 1) {
        history.push(Routes.worker.taskList);
      }
      if (worker?.isStatus === 4) {
        history.push(Routes.worker.register.callback);
      }
      if (worker?.isStatus === 3) {
        history.push(Routes.worker.register.onboarding);
      }
    }
  }, [worker, isAuthWorker]); // eslint-disable-line

  if (worker?.isStatus !== 5) {
    return <Preloader />;
  }

  return (
    <div className={css.onboarding}>
      <div className={cn(css.onboarding__hero, css.hero)}>
        <div className={cn("container", css.hero__wrapper)}>
          <h2 className={cn("h2", css.hero__title)}>
            ¡nos queda un último paso!
          </h2>
          <div className="b-content">
            <p>
              Enviánoslo al{" "}
              <a href="mailTo: soporte@necesitaria.es">
                soporte@necesitaria.es
              </a>
            </p>
          </div>

          <div className={css.hero__img}>
            <ManFeedback />
          </div>
        </div>
      </div>

      <div className={cn("container", css.onboarding__main)}>
        <h3 className={cn("h3", css.onboarding__subtitle)}>una foto tuya</h3>

        <div className={cn(css.onboarding__demand, css.demand)}>
          <div className={css.demand__img}>
            <img src={man} alt="" />
          </div>

          <div
            className={cn(
              "b-content b-content_size b-content_size_xs",
              css.demand__descr,
              css.demand__descr_1
            )}
          >
            <p>Frontal, que se te vea bien</p>
            <img src={line1} alt="" />
          </div>
          <div
            className={cn(
              "b-content b-content_size b-content_size_xs",
              css.demand__descr,
              css.demand__descr_2
            )}
          >
            <p>Bien iluminada</p>
            <img src={line2} alt="" />
          </div>
          <div
            className={cn(
              "b-content b-content_size b-content_size_xs",
              css.demand__descr,
              css.demand__descr_3
            )}
          >
            <p>Buena resolución</p>
            <img src={line3} alt="" />
          </div>
        </div>

        <div className="divider"></div>

        <h3 className={cn("h3", css.onboarding__subtitle)}>
          tu videopresentación
        </h3>

        <ul className={css.onboarding__list}>
          <li className={cn(css.onboarding__item, css.onboardItem)}>
            <div className={css.onboardItem__img}>
              <Clock />
            </div>

            <div
              className={cn(
                "b-content b-content_size b-content_size_xs",
                css.onboardItem__text
              )}
            >
              <p>30-120 segundos</p>
            </div>
          </li>
          <li className={cn(css.onboarding__item, css.onboardItem)}>
            <div className={css.onboardItem__img}>
              <Camera />
            </div>

            <div
              className={cn(
                "b-content b-content_size b-content_size_xs",
                css.onboardItem__text
              )}
            >
              <p>Fija el móvil para que no se mueva la imagen</p>
            </div>
          </li>
          <li className={cn(css.onboarding__item, css.onboardItem)}>
            <div className={css.onboardItem__img}>
              <Video />
            </div>

            <div
              className={cn(
                "b-content b-content_size b-content_size_xs",
                css.onboardItem__text
              )}
            >
              <p>Graba el video horizontalmente</p>
            </div>
          </li>
          <li className={cn(css.onboarding__item, css.onboardItem)}>
            <div className={css.onboardItem__img}>
              <HomeStar />
            </div>

            <div
              className={cn(
                "b-content b-content_size b-content_size_xs",
                css.onboardItem__text
              )}
            >
              <p>Encuentra un espacio luminoso y silencioso</p>
            </div>
          </li>
        </ul>

        <div className="divider"></div>

        <h3 className={cn("h3", css.onboarding__subtitle)}>
          Reseñas de tus previos pacientes
        </h3>

        <ul className={css.onboarding__list}>
          <li className={cn(css.onboarding__item, css.onboardItem)}>
            <div className={css.onboardItem__img}>
              <ManSquare />
            </div>

            <div
              className={cn(
                "b-content b-content_size b-content_size_xs",
                css.onboardItem__text
              )}
            >
              <p>Formato: Nombre / Fecha de inicio de la terapia / Texto</p>
            </div>
          </li>
          <li className={cn(css.onboarding__item, css.onboardItem)}>
            <div className={css.onboardItem__img}>
              <ManWithStar />
            </div>

            <div
              className={cn(
                "b-content b-content_size b-content_size_xs",
                css.onboardItem__text
              )}
            >
              <p>Mínimo 3, cuantas más, mejor</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LastStep;
