import React, { useState } from "react";
import { MenuTaskTypes } from "./MenuTaskTypes";
import css from "./MenuTask.module.scss";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { sendLogoutTask, taskSelector } from "src/store/taskSlice";
import { MenuArrow } from "src/assets/icons";
import { useHistory } from "react-router-dom";
import { Routes } from "src/constants/routes";
import Modal from "src/components/Modal/Modal";
import { MessageWork } from "src/assets/graphIcons";
import Menu from "../Menu/Menu";

const MenuTask: React.FC<MenuTaskTypes> = ({ className }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { task } = useSelector(taskSelector);

  const [showModalNewTask, setShowModalNewTask] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen((state) => !state);
  };

  const handleLogout = () => {
    dispatch(sendLogoutTask());
    history.push(Routes.home);
    handleMenuToggle();
  };

  const handleNewTask = () => {
    dispatch(sendLogoutTask());
    history.push(Routes.client.create.intro);
    handleMenuToggle();
  };

  const controls = [
    {
      id: 1,
      order: 1,
      label: "Psicólogos recomendados",
      link: Routes.client.create.intro,
    },
    {
      id: 2,
      order: 2,
      label: "Solicitud nueva",
      onClick: () => setShowModalNewTask(true),
    },
    {
      id: 3,
      order: 3,
      label: "Salir",
      onClick: handleLogout,
    },
  ];

  return (
    <div className={cn(className, css.menu)}>
      <button
        className={cn(css[`menu-btn`])}
        aria-label={menuOpen ? "Close menu" : "Open the menu"}
        onClick={handleMenuToggle}
      >
        <div className={css[`menu-btn__title`]}>
          {!!task && !!task.id && `#${task?.id}`}
        </div>
        <div className={cn(css[`menu-btn__open`])}>
          <MenuArrow />
        </div>
      </button>

      {menuOpen && <Menu controls={controls} onClose={handleMenuToggle} />}

      {showModalNewTask && (
        <Modal
          active={true}
          setActive={setShowModalNewTask}
          onSubmit={handleNewTask}
          modalConfig={{
            icon: <MessageWork />,
            title: "NUEVA SOLICITUD.",
            desc: "Cuando crees una nueva solicitud, dejarás la actual. Pero siempre se puede abrir más tarde.",
            submitText: "Crear",
          }}
          type={"accept"}
        />
      )}
    </div>
  );
};

export default MenuTask;
