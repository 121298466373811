import { ITemplateList } from "src/store/referenceSlice";
import { FieldSelectOption } from "src/components/UI-kit/FieldSelect/FieldSelect";

interface DefaultReference {
  id: number;
  name: string;
}

const generateReferenceOptions = <T extends DefaultReference>(
  data: T[]
): FieldSelectOption[] => {
  return data.map((d) => ({
    value: "" + d.id,
    label: d.name,
  }));
};

const generateReferenceOptionsOfList = <T extends DefaultReference>(
  id: string,
  list: ITemplateList<T>
): FieldSelectOption[] => {
  return (
    !!id &&
    Array.isArray(list) &&
    !!list[+id] &&
    list[+id].isLoaded &&
    list[+id].data.map((d: T) => ({
      value: "" + d.id,
      label: d.name,
    }))
  );
};

export { generateReferenceOptions, generateReferenceOptionsOfList };
