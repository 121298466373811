import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./strore";

export interface questionnaireState {
  data: Step[];
  isLoaded: boolean;
}

interface Step {
  [key: string]: string;
}

const initialState: questionnaireState = {
  data: [],
  isLoaded: false,
};

export const questionnaireSlice = createSlice({
  name: "questionnaire",
  initialState,
  reducers: {
    updateData: (state: questionnaireState, { payload }) => {
      const index = state.data.findIndex((n) => n.step === payload.step);
      if (index === -1) {
        state.data = [...state.data, payload];
      } else {
        state.data[index].values = payload.values;
      }
    },
    resetData: (state: questionnaireState) => {
      state.data = [];
    },
  },
});

export const { updateData, resetData } = questionnaireSlice.actions;

export const questionnaireSelector = (state: RootState) => state.questionnaire;

export default questionnaireSlice.reducer;
