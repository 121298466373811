import { FC } from "react";
import { ReactComponent as SadFace } from "src/assets/img/icons/Modal/sad-face.svg";
import css from "./NotAvailable.module.scss";
import cn from "classnames";

const NotAvailable: FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={cn(css.section, className)}>
      <SadFace className={css.section__icon} />

      <h2 className={cn(css.section__title, "h2")}>Lo sentimos mucho</h2>

      <div className={cn(css.section__desc, "b-content")}>
        <p>
          No hemos encontrado ningún psicólogo&nbsp;que&nbsp;se
          adapte&nbsp;a&nbsp;tus&nbsp;necesidades
        </p>
      </div>
    </div>
  );
};

export default NotAvailable;
