import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState, AsyncRequest } from "./strore";
import axios from "axios";
import { api } from "../constants/api";
import httpErrorHandler from "./httpErrorHandler";

export interface ITemplateList<T> extends AsyncRequest {
  data: Array<T>;
}

export interface IArea {
  id: number;
  name: string;
  cityName: string;
  nameGen: string;
  namePrep: string;
}
export interface IAreas extends AsyncRequest {
  data: IArea[];
}

export interface ISubject {
  id: number;
  name: string;
  nameGen: string;
  namePrep: string;
}
export interface ISubjects extends AsyncRequest {
  data: ISubject[];
}

export interface ICategorie {
  id: number;
  name: string;
  nameGen: string;
}
export interface ICategories extends AsyncRequest {
  data: ICategorie[];
}

export interface ICountLesson {
  id: number;
  name: string;
}

export interface ICountLessons extends AsyncRequest {
  data: ICountLesson[];
}

export interface IDivision {
  id: number;
  name: string;
  nameGen: string;
  namePrep: string;
}

export interface IDivisions extends AsyncRequest {
  data: IDivision[];
}

export interface IDivisionList {
  [k: string]: IDivisions;
}

export interface IDistrict {
  id: number;
  name: string;
  locativeName: string;
}

export interface IDistricts extends AsyncRequest {
  data: IDistrict[];
}

export interface IDistrictList {
  [k: string]: IDistricts;
}

export interface IAddition {
  id: number;
  name: string;
  nameDative: string;
  nameGen: string;
}

export interface IAdditions extends AsyncRequest {
  data: IAddition[];
}

export interface IAdditionList {
  [k: string]: IAdditions;
}

export interface ITitle extends AsyncRequest {
  data: {
    id: number;
    code: string;
    title: string;
  } | null;
}

export interface ICostLessons extends AsyncRequest {
  data: {
    id: number;
    name: string;
    order: number;
  }[];
}

export interface IForWhom {
  id: number;
  name: string;
  order: number;
}

export interface IForWhoms extends AsyncRequest {
  data: IForWhom[];
}

export interface ISpecialization {
  id: number;
  name: string;
  order: number;
}

export interface ISpecializations extends AsyncRequest {
  data: ISpecialization[];
}

export interface IApproach {
  id: number;
  name: string;
  order: number;
}

export interface IApproachs extends AsyncRequest {
  data: IApproach[];
}

export interface IPaymentMethod {
  id: number;
  name: string;
  order: number;
}

export interface IPaymentMethods extends AsyncRequest {
  data: IPaymentMethod[];
}

export interface IMethodOfConsultation {
  id: number;
  name: string;
  order: number;
}

export interface IMethodsOfConsultation extends AsyncRequest {
  data: IMethodOfConsultation[];
}

export interface ILanguage {
  id: number;
  name: string;
  order: number;
}

export interface ILanguages extends AsyncRequest {
  data: ILanguage[];
}

export interface ICollegium {
  id: number;
  name: string;
  order: number;
}

export interface ICollegiums extends AsyncRequest {
  data: ICollegium[];
}

export interface IReferenceState {
  areas: IAreas;
  subjects: ISubjects;
  categories: ICategories;
  countLessons: ICountLessons;
  divisionList: IDivisionList;
  districtList: IDistrictList;
  additionList: IAdditionList;
  title: ITitle;
  costLessons: ICostLessons;
  forWhoms: IForWhoms;
  specializations: ISpecializations;
  approach: IApproachs;
  paymentMethods: IPaymentMethods;
  methodsOfConsultation: IMethodsOfConsultation;
  languages: ILanguages;
  collegium: ICollegiums;
}

export const templateList = <T>(): ITemplateList<T> => ({
  data: [],
  isLoading: false,
  isLoaded: false,
  error: null,
});

const initialState: IReferenceState = {
  areas: {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  subjects: {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  categories: {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  countLessons: {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  divisionList: {},
  districtList: {},
  additionList: {},
  title: {
    data: null,
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  costLessons: {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  forWhoms: {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  specializations: {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  approach: {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  paymentMethods: {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  methodsOfConsultation: {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  languages: {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  collegium: {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

export const referenceSlice = createSlice({
  name: "reference",
  initialState,
  reducers: {
    getAreas: (state) => {
      state.areas.isLoading = true;
      state.areas.isLoaded = false;
      state.areas.error = null;
    },
    getAreasSuccess: (state, { payload }) => {
      state.areas.isLoading = false;
      state.areas.isLoaded = true;
      state.areas.error = null;
      state.areas.data = payload;
    },
    getAreasFailure: (state, { payload }) => {
      state.areas.isLoading = false;
      state.areas.isLoaded = true;
      state.areas.error = payload;
    },

    getSubjects: (state) => {
      state.subjects.isLoading = true;
      state.subjects.isLoaded = false;
      state.subjects.error = null;
    },
    getSubjectsSuccess: (state, { payload }) => {
      state.subjects.isLoading = false;
      state.subjects.isLoaded = true;
      state.subjects.error = null;
      state.subjects.data = payload;
    },
    getSubjectsFailure: (state, { payload }) => {
      state.subjects.isLoading = false;
      state.subjects.isLoaded = true;
      state.subjects.error = payload;
    },

    getCategories: (state) => {
      state.categories.isLoading = true;
      state.categories.isLoaded = false;
      state.categories.error = null;
    },
    getCategoriesSuccess: (state, { payload }) => {
      state.categories.isLoading = false;
      state.categories.isLoaded = true;
      state.categories.error = null;
      state.categories.data = payload;
    },
    getCategoriesFailure: (state, { payload }) => {
      state.categories.isLoading = false;
      state.categories.isLoaded = true;
      state.categories.error = payload;
    },

    getTitle: (state) => {
      state.title.isLoading = true;
      state.title.isLoaded = false;
      state.title.error = null;
    },
    getTitleSuccess: (state, { payload }) => {
      state.title.isLoading = false;
      state.title.isLoaded = true;
      state.title.error = null;
      state.title.data = payload;
    },
    getTitleFailure: (state, { payload }) => {
      state.title.isLoading = false;
      state.title.isLoaded = true;
      state.title.error = payload;
    },

    getAdditions: (state, { payload }) => {
      state.additionList[payload.id] = {
        ...templateList<IAddition>(),
        isLoading: true,
      };
    },
    getAdditionsSuccess: (state, { payload }) => {
      state.additionList[payload.id].data = [...payload.data];
      state.additionList[payload.id].isLoading = false;
      state.additionList[payload.id].isLoaded = true;
    },
    getAdditionsFailure: (state, { payload }) => {
      state.additionList[payload.id].isLoading = false;
      state.additionList[payload.id].isLoaded = true;
      state.additionList[payload.id].error = payload.error;
    },

    getDistricts: (state, { payload }) => {
      state.districtList[payload.id] = {
        ...templateList<IDistrict>(),
        isLoading: true,
      };
    },
    getDistrictsSuccess: (state, { payload }) => {
      state.districtList[payload.id].data = [...payload.data];
      state.districtList[payload.id].isLoading = false;
      state.districtList[payload.id].isLoaded = true;
    },
    getDistrictsFailure: (state, { payload }) => {
      state.districtList[payload.id].isLoading = false;
      state.districtList[payload.id].isLoaded = true;
      state.districtList[payload.id].error = payload.error;
    },

    getCountLessons: (state) => {
      state.countLessons.isLoading = true;
      state.countLessons.isLoaded = false;
      state.countLessons.error = null;
    },
    getCountLessonsSuccess: (state, { payload }) => {
      state.countLessons.isLoading = false;
      state.countLessons.isLoaded = true;
      state.countLessons.data = [...payload.data];
    },
    getCountLessonsFailure: (state, { payload }) => {
      state.countLessons.isLoading = false;
      state.countLessons.isLoaded = true;
      state.countLessons.error = payload.error;
    },

    getAllSpain: (state) => {
      state.forWhoms.isLoading = true;
      state.forWhoms.isLoaded = false;
      state.forWhoms.error = null;

      state.specializations.isLoading = true;
      state.specializations.isLoaded = false;
      state.specializations.error = null;

      state.costLessons.isLoading = true;
      state.costLessons.isLoaded = false;
      state.costLessons.error = null;

      state.approach.isLoading = true;
      state.approach.isLoaded = false;
      state.approach.error = null;

      state.paymentMethods.isLoading = true;
      state.paymentMethods.isLoaded = false;
      state.paymentMethods.error = null;

      state.methodsOfConsultation.isLoading = true;
      state.methodsOfConsultation.isLoaded = false;
      state.methodsOfConsultation.error = null;

      state.languages.isLoading = true;
      state.languages.isLoaded = false;
      state.languages.error = null;

      state.collegium.isLoading = true;
      state.collegium.isLoaded = false;
      state.collegium.error = null;
    },
    getAllSpainSuccess: (state, { payload }) => {
      state.forWhoms.isLoading = false;
      state.forWhoms.isLoaded = true;
      state.forWhoms.error = null;
      state.forWhoms.data = payload["for-whom"];

      state.specializations.isLoading = false;
      state.specializations.isLoaded = true;
      state.specializations.error = null;
      state.specializations.data = payload["specialization"];

      state.costLessons.isLoading = false;
      state.costLessons.isLoaded = true;
      state.costLessons.error = true;
      state.costLessons.data = payload["range"];

      state.approach.isLoading = false;
      state.approach.isLoaded = true;
      state.approach.error = true;
      state.approach.data = payload["approach"];

      state.paymentMethods.isLoading = false;
      state.paymentMethods.isLoaded = true;
      state.paymentMethods.error = true;
      state.paymentMethods.data = payload["payment-methods"];

      state.methodsOfConsultation.isLoading = false;
      state.methodsOfConsultation.isLoaded = true;
      state.methodsOfConsultation.error = true;
      state.methodsOfConsultation.data = payload["methods-of-consultation"];

      state.languages.isLoading = false;
      state.languages.isLoaded = true;
      state.languages.error = true;
      state.languages.data = payload["languages"];

      state.collegium.isLoading = false;
      state.collegium.isLoaded = true;
      state.collegium.error = true;
      state.collegium.data = payload["collegium"];
    },
    getAllSpainFailure: (state, { payload }) => {
      state.subjects.isLoading = false;
      state.subjects.isLoaded = true;
      state.subjects.error = payload;

      state.specializations.isLoading = false;
      state.specializations.isLoaded = true;
      state.specializations.error = payload;

      state.costLessons.isLoading = false;
      state.costLessons.isLoaded = true;
      state.costLessons.error = payload;

      state.approach.isLoading = false;
      state.approach.isLoaded = true;
      state.approach.error = payload;

      state.paymentMethods.isLoading = false;
      state.paymentMethods.isLoaded = true;
      state.paymentMethods.error = payload;

      state.methodsOfConsultation.isLoading = false;
      state.methodsOfConsultation.isLoaded = true;
      state.methodsOfConsultation.error = payload;

      state.languages.isLoading = false;
      state.languages.isLoaded = true;
      state.languages.error = payload;

      state.collegium.isLoading = false;
      state.collegium.isLoaded = true;
      state.collegium.error = payload;
    },
  },
});

export const {
  getAreas,
  getAreasSuccess,
  getAreasFailure,

  getSubjects,
  getSubjectsSuccess,
  getSubjectsFailure,

  getCategories,
  getCategoriesSuccess,
  getCategoriesFailure,

  getTitle,
  getTitleSuccess,
  getTitleFailure,

  getAdditions,
  getAdditionsSuccess,
  getAdditionsFailure,

  getDistricts,
  getDistrictsSuccess,
  getDistrictsFailure,

  getCountLessons,
  getCountLessonsSuccess,
  getCountLessonsFailure,

  getAllSpain,
  getAllSpainSuccess,
  getAllSpainFailure,
} = referenceSlice.actions;
export const referenceSelector = (state: RootState) => state.reference;
export const referenceAreasSelector = (state: RootState) =>
  state.reference.areas;
export const referenceDistrictListSelector = (state: RootState) =>
  state.reference.districtList;
export const referenceSubjectsSelector = (state: RootState) =>
  state.reference.subjects;
export const referenceAdditionListSelector = (state: RootState) =>
  state.reference.additionList;
export const referenceCategoriesSelector = (state: RootState) =>
  state.reference.categories;
export const referenceCostLessonsSelector = (state: RootState) =>
  state.reference.costLessons;
export const referenceCountLessonsSelector = (state: RootState) =>
  state.reference.countLessons;
export const referenceTitleSelector = (state: RootState) =>
  state.reference.title;
export const referenceForWhomSelector = (state: RootState) =>
  state.reference.forWhoms;
export const referenceSpecializationSelector = (state: RootState) =>
  state.reference.specializations;
export const referenceApproachSelector = (state: RootState) =>
  state.reference.approach;
export const referencePaymentMethodsSelector = (state: RootState) =>
  state.reference.paymentMethods;
export const referenceMethodsOfConsultationSelector = (state: RootState) =>
  state.reference.methodsOfConsultation;
export const referenceLanguagesSelector = (state: RootState) =>
  state.reference.languages;
export const referenceCollegiumSelector = (state: RootState) =>
  state.reference.collegium;

export default referenceSlice.reducer;

// Районы
export const getAreasData = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      reference: { areas },
    } = getState();
    if (areas.isLoaded || areas.isLoading) return;

    dispatch(getAreas());

    try {
      const { data } = await axios.get(`${api}/reference/areas-list/`);
      dispatch(getAreasSuccess(data.list));
    } catch (error) {
      httpErrorHandler(error, dispatch);
      dispatch(getAreasFailure(error));
    }
  };
};

// Предметы
export const getSubjectsData = ({
  worker = false,
}: { worker?: boolean } = {}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      reference: { subjects },
    } = getState();
    if (subjects.isLoaded || subjects.isLoading) return;

    dispatch(getSubjects());

    try {
      const { data } = await axios.get(
        `${api}/reference/subjects-list/${worker ? "?worker=true" : ""}`
      );
      dispatch(getSubjectsSuccess(data.list));
    } catch (error) {
      httpErrorHandler(error, dispatch);
      dispatch(getSubjectsFailure(error));
    }
  };
};

// Категории учеников
export const getCategoriesData = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      reference: { categories },
    } = getState();
    if (categories.isLoaded || categories.isLoading) return;

    dispatch(getCategories());

    try {
      const { data } = await axios.get(`${api}/reference/categories-list/`);
      dispatch(getCategoriesSuccess(data.list));
    } catch (error) {
      httpErrorHandler(error, dispatch);
      dispatch(getCategoriesFailure(error));
    }
  };
};

// Весь список (для формирования заголовка регистрации)
export const getTitleData = (code?: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      reference: { title },
    } = getState();
    if (title.isLoaded) return;

    dispatch(getTitle());
    const query = code !== undefined ? "?code=" + code : "";

    try {
      const { data } = await axios.get(`${api}/reference/title/${query}`);
      dispatch(getTitleSuccess(data.title));
    } catch (error: any) {
      dispatch(getTitleFailure({ error: "Произошла ошибка" }));
    }
  };
};

export const getAdditionsData = (subject_id: number | string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      reference: { additionList },
    } = getState();

    if (!!additionList[subject_id]) return;

    dispatch(getAdditions({ id: subject_id }));

    try {
      const { data } = await axios.get(
        `${api}/reference/additions-list/?id=${subject_id}`
      );
      dispatch(getAdditionsSuccess({ id: subject_id, data: data.list }));
    } catch (error) {
      httpErrorHandler(error, dispatch);
      dispatch(getAdditionsFailure({ id: subject_id, error: error }));
    }
  };
};

export const getDistrictsData = (area_id: number | string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      reference: { districtList },
    } = getState();

    if (!!districtList[area_id]) return;

    dispatch(getDistricts({ id: area_id }));

    try {
      const { data } = await axios.get(
        `${api}/reference/districts-list/?id=${area_id}`
      );
      dispatch(getDistrictsSuccess({ id: area_id, data: data.list }));
    } catch (error) {
      httpErrorHandler(error, dispatch);
      dispatch(getDistrictsFailure({ id: area_id, error: error }));
    }
  };
};

export const getCountLessonsData = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(getCountLessons());

    try {
      const { data } = await axios.get(`${api}/reference/count-lesson-list/`);
      dispatch(getCountLessonsSuccess({ data: data.list }));
    } catch (error) {
      httpErrorHandler(error, dispatch);
      dispatch(getCountLessonsFailure({ error: error }));
    }
  };
};

export const getAllSpainData = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(getAllSpain());

    try {
      const { data } = await axios.get(
        `${api}/reference/directory/?index[]=specialization&index[]=for-whom&index[]=range&index[]=approach&index[]=payment-methods&index[]=methods-of-consultation&index[]=languages&index[]=collegium`
      );

      dispatch(getAllSpainSuccess({ ...data.directory }));
    } catch (error) {
      httpErrorHandler(error, dispatch);
      dispatch(getAllSpainFailure({ error: error }));
    }
  };
};
