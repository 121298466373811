import { FormikErrors, Formik, FormikProps, Form } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "src/components/UI-kit/Button/Button";
import FieldText from "src/components/UI-kit/FieldText/FieldText";
import InternationalPhone from "src/components/UI-kit/InternationalPhone/InternationalPhone";
import {
  interviewPageSelector,
  sendDataInterviewPage,
} from "src/store/interviewSlice";
import { IInterviewPageFormData } from "src/types/interview";
import css from "./Form.module.scss";
import cn from "classnames";
import FieldChange from "src/components/UI-kit/FieldChange/FieldChange";
import { IPhoneMethods } from "src/components/UI-kit/InternationalPhone/InternationalPhoneTypes";
import { pageInterviewFormSend } from "src/helpers/metric";

const FormComponent = () => {
  const dispatch = useDispatch();
  const { error, isLoaded, isLoading } = useSelector(interviewPageSelector);
  const [err, setErr] = useState<FormikErrors<IInterviewPageFormData>>({}); // актуальные ошибки полей
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const formikRef = useRef<FormikProps<IInterviewPageFormData>>(null);
  const methodsPhoneRef = useRef<IPhoneMethods | null>(null);

  const validate = (values: IInterviewPageFormData) => {
    const errors: FormikErrors<IInterviewPageFormData> = {};

    if (!values.phone) {
      errors.phone = "Campo obligatorio";
    } else if (values.phone === "INCORRECT") {
      errors.phone = "Formato erróneo";
    }

    if (!values.name.trim()) {
      errors.name = "Campo obligatorio";
    } else if (values.name.trim().length < 2) {
      errors.name = "Formato erróneo";
    }

    return errors;
  };

  useEffect(() => {
    const { current: formik } = formikRef;
    const { current: methodsPhone } = methodsPhoneRef;

    if (isLoaded && !error) {
      formik?.resetForm();
      methodsPhone?.reset();
      pageInterviewFormSend();
    }
  }, [isLoaded, error]);

  useEffect(() => {
    const msgErrors = {
      name: {
        has: !!err.name,
        text: "nombre",
      },
      phone: {
        has: !!err.phone,
        text: "teléfono",
      },
    };

    const errors = Object.values(msgErrors).filter((m) => m.has);

    if (errors.length) {
      setFormErrorMessage(
        `Para registrarse completa: ` +
          errors.map((m) => m.text).join(", ") +
          "."
      );
    } else {
      setFormErrorMessage("");
    }
  }, [err]);

  const handleSubmit = (data: IInterviewPageFormData) => {
    dispatch(sendDataInterviewPage(data));
  };

  return (
    <Formik
      initialValues={{
        name: "",
        phone: "",
      }}
      onSubmit={handleSubmit}
      validate={validate}
      innerRef={formikRef}
      validateOnMount
    >
      {({ dirty, errors }: FormikProps<IInterviewPageFormData>) => (
        <Form className={css.form}>
          <FieldText
            className={css.form__field}
            name="name"
            label="Nombre"
            placeholder="Introduce"
            required
          />

          <InternationalPhone
            className={cn(css.form__field)}
            onRef={(methods) => (methodsPhoneRef.current = methods)}
            placeholder="Teléfono con Whatsapp*"
            name="phone"
          />

          <Button
            className={css.form__submit}
            disabled={!dirty || isLoading || !!Object.keys(errors).length}
            type="submit"
            text="Apuntarse a la entrevista"
          />

          {!!formErrorMessage && (
            <span className={css.form__message}>{formErrorMessage}</span>
          )}

          <FieldChange<IInterviewPageFormData> onError={setErr} />
        </Form>
      )}
    </Formik>
  );
};

export default FormComponent;
