import React, { useEffect } from "react";
import cn from "classnames";

import { OnboardingTypes } from "./OnboardingTypes";
import {
  DoubleMessage,
  ManWithComputer,
  Phone,
  Euro,
  Wallet,
  HalfCircle,
} from "src/assets/icons";

import css from "./Onboarding.module.scss";
import Button from "src/components/UI-kit/Button/Button";
import { Routes } from "src/constants/routes";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Preloader from "src/components/Preloader/Preloader";
import { workerSelector } from "src/store/workerSlice";
import { authSelector } from "src/store/authSlice";

const Onboarding: React.FC<OnboardingTypes> = () => {
  const history = useHistory();
  const { worker } = useSelector(workerSelector);
  const { isAuthWorker } = useSelector(authSelector);

  useEffect(() => {
    if (!isAuthWorker) {
      history.push(Routes.home);
    }
    if (isAuthWorker && worker?.isStatus !== 3) {
      if (worker?.isStatus === 1) {
        history.push(Routes.worker.taskList);
      }
      if (worker?.isStatus === 4) {
        history.push(Routes.worker.register.callback);
      }
      if (worker?.isStatus === 5) {
        history.push(Routes.worker.register.final);
      }
    }
  }, [worker, isAuthWorker, history]);

  if (worker?.isStatus !== 3) {
    return <Preloader />;
  }
  return (
    <div className={css.onboarding}>
      <div className={cn(css.onboarding__hero, css.hero)}>
        <div className={cn("container", css.hero__wrapper)}>
          <h2 className={cn("h2", css.hero__title)}>¡Bienvenidos!</h2>
          <div className={cn("b-content", css.hero__descr)}>
            <p>
              Has iniciado el proceso de registro. Para continuar lee la
              siguiente información.
            </p>
          </div>

          <div className={css.hero__img}>
            <ManWithComputer />
          </div>
        </div>
      </div>

      <div className={cn("container", css.onboarding__main)}>
        <h3 className={cn("h3", css.onboarding__subtitle)}>
          Como funciona el servicio
        </h3>

        <ul className={css.onboarding__list}>
          <li className={cn(css.onboarding__item, css.onboardItem)}>
            <div className={css.onboardItem__img}>
              <Phone />
            </div>

            <div
              className={cn(
                "b-content b-content_size b-content_size_xs",
                css.onboardItem__text
              )}
            >
              <p>Te avisamos por SMS cuando hay un cliente potencial</p>
            </div>
          </li>
          <li className={cn(css.onboarding__item, css.onboardItem)}>
            <div className={css.onboardItem__img}>
              <DoubleMessage />
            </div>

            <div
              className={cn(
                "b-content b-content_size b-content_size_xs",
                css.onboardItem__text
              )}
            >
              <p>Contéstale en nuestro chat y acordad la primera sesión</p>
            </div>
          </li>
          <li className={cn(css.onboarding__item, css.onboardItem)}>
            <div className={css.onboardItem__img}>
              <Euro />
            </div>

            <div
              className={cn(
                "b-content b-content_size b-content_size_xs",
                css.onboardItem__text
              )}
            >
              <p>Unicamente nos pagarás cuando hayas iniciado la terapia</p>
            </div>
          </li>
        </ul>

        <div className="divider"></div>

        <h3 className={cn("h3", css.onboarding__subtitle)}>Cuanto cobramos</h3>

        <ul className={css.onboarding__list}>
          <li className={cn(css.onboarding__item, css.onboardItem)}>
            <div className={css.onboardItem__img}>
              <Wallet />
            </div>

            <div
              className={cn(
                "b-content b-content_size b-content_size_xs",
                css.onboardItem__text
              )}
            >
              <p>
                El valor de 1 sesión si se realiza más de una consulta con el
                nuevo cliente
              </p>
            </div>
          </li>
          <li className={cn(css.onboarding__item, css.onboardItem)}>
            <div className={css.onboardItem__img}>
              <HalfCircle />
            </div>

            <div
              className={cn(
                "b-content b-content_size b-content_size_xs",
                css.onboardItem__text
              )}
            >
              <p>50% del valor de 1 sesión si se realiza solo una consulta</p>
            </div>
          </li>
        </ul>

        <div className="divider"></div>

        <h3 className={cn("h3", css.onboarding__subtitle)}>Como registrarse</h3>

        <ul className={css.onboarding__stepList}>
          <li className={cn(css.onboarding__stepItem, css.stepItem)}>
            <div className={css.stepItem__left}>
              <div className={css.stepItem__circle}>
                <span>1</span>
              </div>
              <span className={css.stepItem__line}></span>
            </div>
            <div className={css.stepItem__right}>
              <div className={cn("b-content", css.stepItem__title)}>
                <p>Rellena tu perfil — 5 minutos</p>
              </div>
              <div
                className={cn(
                  "b-content b-content_size b-content_size_xs",
                  css.stepItem__descr
                )}
              >
                <p>Se lo mostramos a clientes potenciales</p>
              </div>
            </div>
          </li>
          <li className={cn(css.onboarding__stepItem, css.stepItem)}>
            <div className={css.stepItem__left}>
              <div className={css.stepItem__circle}>
                <span>2</span>
              </div>
              <span className={css.stepItem__line}></span>
            </div>
            <div className={css.stepItem__right}>
              <div className={cn("b-content", css.stepItem__title)}>
                <p>Mantén una breve llamada con nosotros — 10 minutos</p>
              </div>
              <div
                className={cn(
                  "b-content b-content_size b-content_size_xs",
                  css.stepItem__descr
                )}
              >
                <p>Nos conoceremos y te explicaremos cómo funciona</p>
              </div>
            </div>
          </li>
          <li className={cn(css.onboarding__stepItem, css.stepItem)}>
            <div className={css.stepItem__left}>
              <div className={css.stepItem__circle}>
                <span>3</span>
              </div>
              <span className={css.stepItem__line}></span>
            </div>
            <div className={css.stepItem__right}>
              <div className={cn("b-content", css.stepItem__title)}>
                <p>
                  Envíanos tu foto y videopresentación (30 segundos es
                  suficiente), reseñas de tus pacientes previos (si tienes) — 15
                  minutos
                </p>
              </div>
              <div
                className={cn(
                  "b-content b-content_size b-content_size_xs",
                  css.stepItem__descr
                )}
              >
                <p>
                  No olvides que estamos en Internet, estos datas nos ayudarán
                  en la captación de pacientes
                </p>
              </div>
            </div>
          </li>
        </ul>

        <Button
          asLink
          to={`${Routes.worker.register.questionnaire}/1`}
          text="Rellenar mi perfil"
          className={css.onboarding__btn}
        />
      </div>
    </div>
  );
};

export default Onboarding;
