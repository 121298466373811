import { FC, TransitionEvent, useEffect, useState } from "react";
import css from "./Banner.module.scss";
import cn from "classnames";
import { Close } from "src/assets/icons";
import { useDispatch } from "react-redux";
import { hideRefoundBanner } from "src/store/createNewTaskSlice";

const Banner: FC<{ show: boolean; className?: string }> = ({
  show,
  className,
}) => {
  const dispatch = useDispatch();
  const [mount, setMount] = useState<boolean>(show);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setMount(show);
  }, [show]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (mount) {
      timer = setTimeout(() => {
        setOpen(true);
      }, 100);
    }

    return () => {
      timer && clearTimeout(timer);
    };
  }, [mount]);

  const onTransition = (event: TransitionEvent<HTMLDivElement>) => {
    if (!open && event.propertyName === "transform") {
      setMount(false);
    }
  };

  if (!mount) return null;

  return (
    <div
      className={cn(className, css.banner, {
        [css.banner_active]: open,
      })}
      onTransitionEnd={onTransition}
    >
      <div className={cn("container", css.banner__wrapper)}>
        <p className={css.banner__text}>
          Te devolvemos el dinero si no quedas satisfecho con tu 1a sesión
        </p>
        <button
          className={css.banner__button}
          onClick={() => {
            setOpen(false);
            dispatch(hideRefoundBanner());
          }}
        >
          <Close className={css.banner__icon} />
        </button>
      </div>
    </div>
  );
};

export default Banner;
