import React from "react";
import cn from "classnames";
import { FieldHookConfig, useField } from "formik";
import css from "./FieldCheckbox.module.scss";

interface FieldCheckboxProps {
  className?: string;
  label?: string;
  type?: string;
  onChange?: (value: any) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

interface FieldCheckboxGroupProps {
  className?: string;
  children?: React.ReactChildren | React.ReactElement | React.ReactFragment;
  label?: string | React.ReactElement;
  required?: boolean;
  error?: string | string[];
}

export const FieldCheckboxGroup: React.FC<FieldCheckboxGroupProps> = ({
  className,
  children,
  label,
  required,
  error,
}) => {
  return (
    <div className={cn(className, css.checkboxGroup)}>
      {label && (
        <div className={css.checkboxGroup__label}>
          {label}
          {required ? "*" : ""}
        </div>
      )}
      <div className={css.checkboxGroup__all}>{children}</div>
      {!!error && <span className={css.checkboxGroup__error}>{error}</span>}
    </div>
  );
};

const FieldCheckbox = (props: FieldHookConfig<string> & FieldCheckboxProps) => {
  const {
    className,
    label,
    required,
    type = "checkbox",
    name,
    ...config
  } = props;
  const [field] = useField({
    ...config,
    name,
    type: type,
  });

  return (
    <div className={cn(className, css.checkbox)}>
      <label className={css.checkbox__label}>
        <input className={css.checkbox__input} {...field} type={type} />
        <div className={css.checkbox__svg}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20Z"
              fill="#888173"
            />
            <path
              d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
              fill="#84E782"
            />
          </svg>
        </div>
        <span className={css.checkbox__text}>{label}</span>
      </label>
    </div>
  );
};

export default FieldCheckbox;
