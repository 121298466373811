import React, { useEffect, useState } from "react";
import { Form, Formik, FormikErrors, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";

import Button from "src/components/UI-kit/Button/Button";
import FieldBirthdate from "src/components/UI-kit/FieldBirthdate/FieldBirthdate";
import FieldChange from "src/components/UI-kit/FieldChange/FieldChange";
import FieldSelect from "src/components/UI-kit/FieldSelect/FieldSelect";
import FieldText from "src/components/UI-kit/FieldText/FieldText";
import { Step1TypesProps } from "./Step1Types";

import css from "../Questionnaire.module.scss";
import { QuestionnaireTypesValues } from "../QuestionnaireTypes";
import { formatEasyAnswer } from "src/constants/constants";
import {
  questionnaireSelector,
  updateData,
} from "src/store/questionnaireSlice";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "src/constants/routes";
import { Pation } from "src/assets/icons";

const Step1: React.FC<Step1TypesProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { step } = useParams<{ step: string }>();
  const questionnaire = useSelector(questionnaireSelector);

  const thisData: any = questionnaire.data.map(
    (item) => item.step === step && item.values
  );

  // Нужна подсказка по поводу типизации))
  const initData: /* QuestionnaireTypesValues */ any = thisData[0] || {
    college_and_university_degree: "",
    experience: "",
    colegiadoTo: "",
    costConsultation: "",
    durationConsultation: "",
    workSchedule: "",
    weekendConsultations: "0",
  };

  const defaultYearOption = !!thisData[0]
    ? thisData[0].colegiadoTo.split("-")[0]
    : "";
  const defaultMonthOption = !!thisData[0]
    ? thisData[0].colegiadoTo.split("-")[1]
    : "";
  const defaultDayOption = !!thisData[0]
    ? thisData[0].colegiadoTo.split("-").pop().split("-")[0]
    : "";

  const [err, setErr] = useState<FormikErrors<QuestionnaireTypesValues>>({});
  const [formErrorMessage, setFormErrorMessage] = useState("");

  const handleSubmit = (values: QuestionnaireTypesValues) => {
    const finalData = {
      ...values,
      experience: +values.experience,
      costConsultation: +values.costConsultation,
      durationConsultation: +values.durationConsultation,
    };

    nextStep(+step + 1);
    dispatch(updateData({ values: finalData, step }));
  };

  const handleStepBack = (values: QuestionnaireTypesValues) => {
    const finalData = {
      ...values,
      experience: values.experience !== "" && +values.experience,
      costConsultation:
        values.costConsultation !== "" && +values.costConsultation,
      durationConsultation:
        values.durationConsultation !== "" && +values.durationConsultation,
    };

    prevStep(+step - 1);
    dispatch(updateData({ values: finalData, step }));
  };

  const validate = (values: QuestionnaireTypesValues) => {
    const errors: FormikErrors<QuestionnaireTypesValues> = {};
    if (values.experience === "0") {
      values.experience = "";
    }

    if (!values.college_and_university_degree.trim()) {
      errors.college_and_university_degree = "Campo obligatorio";
    }
    if (!values.experience) {
      errors.experience = "Campo obligatorio";
    } else if (+values.experience > 2147483647) {
      errors.experience = "Introduce un número menos que 2147483647";
    }
    if (!values.colegiadoTo) {
      errors.colegiadoTo = "Campo obligatorio";
    } else {
      const [year, month, day] = values.colegiadoTo.split("-");
      const newDate = new Date();
      const dataDate = new Date(+year, +month - 1, +day);
      const idOlderYear =
        (newDate.getTime() - dataDate.getTime()) / (1000 * 3600 * 24 * 365) > 1;
      const nowDate =
        (newDate.getTime() - dataDate.getTime()) / (1000 * 3600 * 24 * 365) <
        0.004;

      if (!year || !month || !day || idOlderYear) {
        errors.colegiadoTo = "Formato erróneo";
      }

      if (year && month && day && !nowDate) {
        errors.colegiadoTo = "La fecha no puede ser en el pasado";
      }
    }
    if (!values.costConsultation) {
      errors.costConsultation = "Campo obligatorio";
    } else if (+values.costConsultation > 2147483647) {
      errors.costConsultation = "Introduce un número menos que 2147483647";
    }
    if (!values.durationConsultation) {
      errors.durationConsultation = "Campo obligatorio";
    } else if (+values.durationConsultation > 2147483647) {
      errors.durationConsultation = "Introduce un número menos que 2147483647";
    }
    if (!values.workSchedule.trim()) {
      errors.workSchedule = "Campo obligatorio";
    }
    if (!values.weekendConsultations.trim()) {
      errors.weekendConsultations = "Campo obligatorio";
    }

    return errors;
  };

  useEffect(() => {
    const msgErrors = {
      lastName: {
        has: !!err.college_and_university_degree,
        text: "universidad",
      },
      experience: {
        has: !!err.experience,
        text: "años de experiencia",
      },
      colegiadoTo: {
        has: !!err.colegiadoTo,
        text: "colegiad@ hasta",
      },
      costConsultation: {
        has: !!err.costConsultation,
        text: "costo de consulta",
      },
      durationConsultation: {
        has: !!err.durationConsultation,
        text: "duración de consulta",
      },
      workSchedule: {
        has: !!err.workSchedule,
        text: "horario",
      },
      weekendConsultations: {
        has: !!err.weekendConsultations,
        text: "atención en finde",
      },
    };

    const errors = Object.values(msgErrors).filter((m) => m.has);

    if (errors.length) {
      setFormErrorMessage(
        `Para registrarse completa: ` +
          errors.map((m) => m.text).join(", ") +
          "."
      );
    } else {
      setFormErrorMessage("");
    }
  }, [err]);

  const nextStep = (step: number) => {
    step !== 4
      ? history.push(`${Routes.worker.register.questionnaire}/${step}`)
      : history.push(Routes.worker.register.onboarding);
  };

  const prevStep = (step: number) => {
    history.push(Routes.worker.register.onboarding);
  };

  return (
    <Formik
      initialValues={initData}
      enableReinitialize
      onSubmit={handleSubmit}
      validate={validate}
      validateOnMount={true}
    >
      {({ values, errors, dirty }: FormikProps<QuestionnaireTypesValues>) => (
        <Form className={css.questionnaire__wrap}>
          <FieldText
            className={css.questionnaire__field}
            name="college_and_university_degree"
            type="text"
            label="Título universitario y universidad"
            placeholder={"Introduce"}
            required
          />

          <FieldText
            className={css.questionnaire__field}
            name="educationText"
            type="text"
            label="Formación adicional"
            placeholder={"Introduce"}
          />

          <FieldBirthdate
            className={css.questionnaire__field}
            label="Fecha de validez del certificado de colegiado"
            name="colegiadoTo"
            defaultDay={defaultDayOption}
            defaultMonth={defaultMonthOption}
            defaultYear={defaultYearOption}
            yearUp={true}
            required
          />

          <div className={css.questionnaire__policy}>
            <Pation />

            <div className="b-content b-content_size b-content_size_xs">
              <p>
                Según la legislacion española los psicólogos deben estar
                colegiados. Por lo tanto contamos la experiencia como la
                cantidad de años colegiados.
              </p>
            </div>
          </div>

          <FieldText
            className={css.questionnaire__field}
            name="experience"
            type="number"
            label="Cuantos años en total llevas de colegiado"
            placeholder={"Introduce"}
            required
          />

          <FieldText
            className={css.questionnaire__field}
            name="costConsultation"
            type="number"
            label="Tarifa de una consulta online estándar, en euros"
            placeholder={"Introduce"}
            required
          />

          <FieldText
            className={css.questionnaire__field}
            name="durationConsultation"
            type="number"
            label={"Duración de una consulta online estándar, minutos"}
            placeholder={"Introduce"}
            required
          />

          <FieldText
            className={css.questionnaire__field}
            name="workSchedule"
            type="text"
            label="Horario"
            placeholder={"Introduce"}
            required
          />

          <FieldSelect
            className={css.questionnaire__field}
            label="Atención en fin de semana"
            name="weekendConsultations"
            defaultValue={"0"}
            required
            options={[formatEasyAnswer[1], formatEasyAnswer[0]]}
          />

          <div className="divider"></div>

          <Button
            transparent
            className={css.questionnaire__btn}
            type="button"
            text="Volver"
            onClick={() => handleStepBack(values)}
          />

          <Button
            className={css.questionnaire__btn}
            type="submit"
            text="Continuar"
            disabled={!!Object.keys(errors).length}
          />

          {!!formErrorMessage.length && (
            <div className={css.questionnaire__alert}>
              <p>{formErrorMessage}</p>
            </div>
          )}

          <FieldChange<QuestionnaireTypesValues> onError={setErr} />
        </Form>
      )}
    </Formik>
  );
};

export default Step1;
