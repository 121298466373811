import { yandexMetric, ymClientID } from "src/constants/constants";

interface hitTypes {
  url: string;
  referer: string;
  title: string;
}

export const updateYmClientId = () => {
  // @ts-ignore
  window.ym(yandexMetric, "getClientID", (clientID: string) => {
    localStorage.setItem(ymClientID, clientID);
  });
};

export const getClientIDYmCookie = () => {
  const match = document.cookie.match("(?:^|;)\\s*_ym_uid=([^;]*)");

  return match ? decodeURIComponent(match[1]) : false;
};

export const hitFunction = ({ url, referer, title }: hitTypes) => {
  // @ts-ignore
  window.ym(yandexMetric, "hit", url, {
    referer,
    title,
  });
};

export const userWorkerAuthSuccess = (userId: string | number) => {
  // @ts-ignore
  window.ym(yandexMetric, "userParams", {
    user_status: "worker",
    UserID: `t_${userId}_`,
  });
};

export const userTaskAuthSuccess = (userId: string) => {
  // @ts-ignore
  window.ym(yandexMetric, "userParams", {
    user_status: "client",
    UserID: `c_${userId}_`,
  });
};

export const userUnauthorized = () => {
  const clientID = getClientIDYmCookie();

  // @ts-ignore
  window.ym(yandexMetric, "userParams", {
    user_status: "unauthorized",
    UserID: `c_${clientID || Date.now()}_`,
  });
};

export const userShowPageTask = (id: string) => {
  // @ts-ignore
  window.ym(yandexMetric, "params", {
    task: id + "_",
  });
};

export const userFilters = (data: {
  para_quien: string;
  especializacion: string;
  genero: string;
}) => {
  // @ts-ignore
  window.ym(yandexMetric, "params", {
    filtros: data,
  });
};

export const visitStatus = (status: "client" | "worker" | "unauthorized") => {
  // @ts-ignore
  window.ym(yandexMetric, "params", {
    visit_status: status,
  });
};

export const userDiapasonCount = (data: Record<string, number>) => {
  // @ts-ignore
  window.ym(yandexMetric, "params", {
    cantidad_en_diapason: data,
  });
};

const ym = (action: string, value: string) => {
  // @ts-ignore
  window.ym(yandexMetric, action, value);
};

// Не авторизованый
export const naFilters = () => {
  ym("reachGoal", "no-autorizado_catalogo_aplicar-filtros");
};

export const naOpenChat = () => {
  ym("reachGoal", "no-autorizado_chat-perfil_chat");
};

export const naOpenProfile = () => {
  ym("reachGoal", "no-autorizado_chat-perfil_perfil");
};

export const naCreateClient = () => {
  ym("reachGoal", "no-autorizado_chat-perfil_enviar");
};

export const clientPageInputCodeFormSms = () => {
  ym("reachGoal", "task_page_input-code-from-sms");
};

export const clientPhoneVerificationSuccess = () => {
  ym("reachGoal", "task_phone-verification-success");
};

export const naPopupRefound = () => {
  ym("reachGoal", "no-autorizado_chat-perfil_pop-up-te-devolvemos-dinero");
};

export const naPopupInterviewOpen = () => {
  ym("reachGoal", "no-autorizado_catalogo_pop-up-encuesta");
};

export const naPopupInterviewFormSend = () => {
  ym("reachGoal", "no-autorizado_catalogo_pop-up-encuesta_enviar");
};

export const naLinkCatalogToPerfil = () => {
  ym("reachGoal", "no-autorizado_catalogo_a-perfil");
};

export const naLinkCatalogToChat = () => {
  ym("reachGoal", "no-autorizado_catalogo_a-chat");
};

export const naChatSendMessage = () => {
  ym("reachGoal", "no-autorizado_chat_enviar-1-mensaje");
};

export const naOpenRegistrationPopup = () => {
  ym("reachGoal", "no-autorizado_chat_pop-up-verificar-telefono");
};

export const naLinkChatToProfile = () => {
  ym("reachGoal", "no-autorizado_chat_a-perfil");
};

export const naPlayPerfilVideo = () => {
  ym("reachGoal", "no-autorizado_perfil_video");
};

export const naLinkProfileToChat = () => {
  ym("reachGoal", "no-autorizado_perfil_a-chat");
};

// Aвторизованый
export const clientLinkProfileToChat = () => {
  ym("reachGoal", "cliente_perfil_a-chat");
};

export const clientLinkChatToProfile = () => {
  ym("reachGoal", "cliente_chat_a-perfil");
};

export const clientLinkCatalogToPerfil = () => {
  ym("reachGoal", "cliente_catalogo_a-perfil");
};

export const clientLinkCatalogToChat = () => {
  ym("reachGoal", "cliente_catalogo_a-chat");
};

export const clientFilters = () => {
  ym("reachGoal", "cliente_catalogo_aplicar-filtros");
};

export const clientOpenChat = () => {
  ym("reachGoal", "cliente_chat-perfil_chat");
};

export const clientOpenProfile = () => {
  ym("reachGoal", "cliente_chat-perfil_perfil");
};

export const clienPayStart = () => {
  ym("reachGoal", "cliente_chat-perfil_reservar");
};

export const clienPayEnd = () => {
  ym("reachGoal", "cliente_ha-pagado-1a-sesion");
};

export const clientSendMessage = () => {
  ym("reachGoal", "cliente_chat-perfil_enviar-mensaje");
};

export const clientPlayPerfilVideo = () => {
  ym("reachGoal", "cliente_chat-perfil_perfil_video");
};

export const clientCreateChat = () => {
  ym("reachGoal", "cliente_chat-perfil_chat_buton-chatear");
};

// Психолог
export const workerPhoneVerificationSuccess = () => {
  ym("reachGoal", "worker-reg_phone-verification-success");
};

export const workerClickRegister = () => {
  ym("reachGoal", "fontanero-reg_click_registrarse");
};

export const workerCreate = () => {
  ym("reachGoal", "fontanero-reg_click_registrarse");
};

// Общие
export const landingClickFindPsychologist = () => {
  ym("reachGoal", "landing_click_necesito-fontanero");
};

export const registrationPsychologist = () => {
  ym("reachGoal", "no-autorizado_registrarse-1_buton");
};

export const verificationPsychologist = () => {
  ym("reachGoal", "especialista_telefono-verificado");
};

export const saveDataPsychologist = () => {
  ym("reachGoal", "especialista_perfil-completado");
};

export const pageInterviewFormSend = () => {
  ym("reachGoal", "no-autorizado_pagina-entrevista_enviar");
};

export const allUsersHideRefundBanner = () => {
  ym("reachGoal", "te-devolvemos-dinero_tablilla-cerrada");
};

export const policyPopup = () => {
  ym("reachGoal", "cliente_chat_pop-up-politica-de-cancelacion");
};

interface IEcommerceProduct {
  id: string | number;
  name: string;
  sex: number;
  category: string;
  price: string | number;
}
interface IData {
  actionField?: { id: number };
  products: {
    id: string | number;
    name: string;
    brand: string;
    category: string;
    price: string | number;
    quantity: 1;
  }[];
}
export const ecommerce = (
  type: "detail" | "add" | "purchase",
  product: IEcommerceProduct
) => {
  const data: IData | Record<any, any> = {
    products: [],
  };

  if (type === "purchase") {
    data.actionField = { id: Date.now() };
  }

  data.products.push({
    id: product.id,
    name: product.name,
    brand: product.sex === 1 ? "Hombre" : "Mujer",
    category: product.category,
    price: product.price,
    quantity: 1,
  });

  window.ymDataLayer?.push({
    ecommerce: {
      currencyCode: "EUR",
      [type]: data,
    },
  });
};
