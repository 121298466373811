import { FC } from "react";
import { CardProps } from "./CardTypes";
import css from "./Card.module.scss";
import cn from "classnames";

import Button from "src/components/UI-kit/Button/Button";
import { CheckMark, SimpleCamera, Youtube } from "src/assets/icons";
import LowText from "src/components/LowText/LowText";
import usedExperience from "src/helpers/usedExperience";
import { Routes } from "src/constants/routes";
import { Link } from "react-router-dom";

import avatar from "src/assets/img/user.svg";
import avatarMale from "src/assets/img/user-male.svg";
import avatarFemale from "src/assets/img/user-female.svg";
import Rating from "src/components/Rating/Rating";
import { Message } from "src/assets/graphIcons";
import {
  clientLinkCatalogToChat,
  clientLinkCatalogToPerfil,
  naLinkCatalogToChat,
  naLinkCatalogToPerfil,
} from "src/helpers/metric";
import { dateAdapter3 } from "src/helpers/dateAdapter";

const Card: FC<CardProps> = ({
  className,
  costConsultation,
  durationConsultation,
  experience,
  firstName,
  id,
  lastName,
  lastNameTwo,
  grade,
  photoPath,
  photoPathSquare,
  methodsOfConsultation,
  sex,
  reviews,
  taskLogged,
  workerLogged,
}) => {
  const review = reviews.data[0];
  const defaultAvatar =
    sex === 1 ? avatarMale : sex === 2 ? avatarFemale : avatar;

  const metrikToPerfil = () => {
    if (workerLogged) return;

    if (!taskLogged) {
      naLinkCatalogToPerfil();
    } else {
      clientLinkCatalogToPerfil();
    }
  };

  const metrikToChat = () => {
    if (workerLogged) return;

    if (!taskLogged) {
      naLinkCatalogToChat();
    } else {
      clientLinkCatalogToChat();
    }
  };

  return (
    <article className={cn(className, css.card)}>
      <div className={cn(css.card__preview, css.preview)}>
        <div className={css.preview__feedback}>
          <Link
            to={`${Routes.client.show}/${id}#reviews`}
            onClick={metrikToPerfil}
            className={cn(css.preview__link, {
              [css.preview__link_disabled]: reviews.count === 0,
            })}
          >
            <div
              className={cn(css.preview__title, {
                [css.preview__title_empty]: reviews.count === 0,
              })}
            >
              {reviews.count}
            </div>

            <p className={css.preview__text}>
              {reviews.count === 1 ? "opinión" : "opiniones"}
            </p>
            <Rating
              rating={reviews.count === 0 || !Number(grade) ? 0 : Number(grade)}
              className={css.preview__rating}
              size="sm"
            />
          </Link>
        </div>

        <div className={css.preview__imgContainer}>
          <Link
            to={`${Routes.client.show}/${id}`}
            className={css.preview__link}
            onClick={metrikToPerfil}
          >
            <img
              src={photoPathSquare || photoPath || defaultAvatar}
              alt="avatar"
              onError={(e) => {
                (e.target as HTMLImageElement).src = defaultAvatar;
              }}
              className={css.preview__img}
            />
          </Link>
        </div>

        <div className={css.preview__video}>
          <Link
            to={`${Routes.client.show}/${id}#video`}
            className={css.preview__link}
            onClick={metrikToPerfil}
          >
            <Youtube className={css.preview__icon} />

            <p className={css.preview__text}>
              Vídeo
              <br />
              en perfil
            </p>
          </Link>
        </div>
      </div>

      <div className={cn(css.card__description, css.description)}>
        <div className={css.description__title}>
          <Link
            to={`${Routes.client.show}/${id}`}
            className={css.description__name}
            onClick={metrikToPerfil}
          >
            <span className={css.description__nameText}>
              {firstName} {lastName} {lastNameTwo || ""}
              <div className={css.description__status}>
                <CheckMark className={css.description__icon} />
              </div>
            </span>
          </Link>
        </div>

        {!!costConsultation && !!durationConsultation && (
          <p className={css.description__subtitle}>
            Desde {costConsultation} € / {durationConsultation} min
          </p>
        )}

        <p className={css.description__text}>
          {usedExperience(experience)} {experience !== 0 && "de experiencia"}
        </p>

        {!!methodsOfConsultation?.length && (
          <p className={css.description__text}>
            <SimpleCamera className={css.description__icon} />
            Sesiones vía {methodsOfConsultation.map((m) => m.name).join(", ")}
          </p>
        )}
      </div>

      {review ? (
        <article className={cn(css.card__feedback, css.feedback)}>
          <Link
            to={`${Routes.client.show}/${id}#reviews`}
            className={css.feedback__wrapper}
            onClick={metrikToPerfil}
          >
            <header className={css.feedback__header}>
              <h6 className={css.feedback__title}>opinión de cliente</h6>
              <p className={css.feedback__subtitle}>
                {dateAdapter3(review.date)}
              </p>
            </header>

            <LowText className={css.feedback__text} text={review.review} />
          </Link>
        </article>
      ) : (
        <div className={cn(css.card__feedback, css.feedbackEmpty)}>
          <Message className={css.feedbackEmpty__icon} />
          <p className={css.feedbackEmpty__text}>
            Sé la primera persona en dar su opinión
          </p>
        </div>
      )}

      <div className={cn(css.card__control, css.control)}>
        <Button
          asLink
          to={`${Routes.client.show}/${id}`}
          onClick={metrikToPerfil}
          text="Perfil"
          transparent
          className={css.controls__button}
        />
        <Button
          asLink
          to={`${Routes.client.chat}/${id}`}
          onClick={metrikToChat}
          text="Chat"
          className={css.controls__button}
        />
      </div>
    </article>
  );
};

export default Card;
