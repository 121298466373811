import React, { useEffect } from "react";
import cn from "classnames";
import { Routes } from "src/constants/routes";
import Button from "src/components/UI-kit/Button/Button";
import onboarding from "src/assets/img/icons/onboarding.svg";
import css from "./Main.module.scss";
import { registrationPsychologist } from "src/helpers/metric";
import { useSelector } from "react-redux";
import { authSelector } from "src/store/authSlice";
import { workerSelector } from "src/store/workerSlice";
import { useHistory } from "react-router-dom";
import Preloader from "src/components/Preloader/Preloader";
import Data from "./components/Data/Data";
import Step from "./components/Step/Step";
import Reviews from "./components/Reviews/Reviews";
import { ReviewData } from "src/store/createTaskSlice";
import Avatar_1 from "src/assets/img/avatars/avatar_1.png";
import Avatar_2 from "src/assets/img/avatars/avatar_2.png";
import Avatar_3 from "src/assets/img/avatars/avatar_3.png";

const Main = () => {
  const history = useHistory();
  const { tokenWorker } = useSelector(authSelector);
  const { worker } = useSelector(workerSelector);

  useEffect(() => {
    if (tokenWorker) {
      if (worker?.isStatus === 1) {
        history.push(Routes.worker.taskList);
      }
      if (worker?.isStatus === 3) {
        history.push(Routes.worker.register.onboarding);
      }
      if (worker?.isStatus === 4) {
        history.push(Routes.worker.register.callback);
      }
      if (worker?.isStatus === 5) {
        history.push(Routes.worker.register.final);
      }
    }
  }, [worker, tokenWorker, history]);

  if (tokenWorker) {
    return <Preloader />;
  }

  const handleClick = () => {
    registrationPsychologist();
  };

  return (
    <div className={css.create}>
      <div className="container">
        <h1 className={cn(css.create__title, "h2")}>
          ¿ERES PSICÓLOGO COLEGIADO EN ESPAÑA? ¡captamos NUEVOS CLIENTeS para
          ti!
        </h1>

        <div className={css.create__onboarding}>
          <img src={onboarding} alt="onboarding" />
        </div>

        <div className={css.create__text}>
          <p>Pagas solamente por los nuevos pacientes</p>
        </div>

        <Button
          className={css.create__next}
          text="Registrarse"
          onClick={handleClick}
          asLink
          to={Routes.worker.register.form}
        />
        <Data onLink={handleClick} className={css.create__data} />
      </div>

      <Step onLink={handleClick} className={css.create__steps} />

      <div className="container">
        <Reviews
          className={css.create__reviews}
          reviews={{
            isLoading: false,
            reviews: reviewsData,
          }}
          onLink={handleClick}
        />
      </div>
    </div>
  );
};

export default Main;

const reviewsData: ReviewData[] = [
  {
    image: Avatar_1,
    title: "Mireia Plata Pascual",
    subtitle: "Núm. Colegiado 29599",
    score: 5,
    text: "Estoy muy contenta con Necesitaría. La plataforma realmente hace el trabajo de captación de personas interesadas en iniciar un proceso terapéutico. Me ahorra mucho tiempo porque me puedo centrar en las personas que me contactan y no tengo que estar pensando en qué más puedo hacer para captar más personas. ¡Un acierto!",
  },
  {
    image: Avatar_2,
    title: "Raquel Beneito Vilaplana",
    subtitle: "Núm. Colegiado 27291",
    score: 5,
    text: "Me gustó la plataforma en especial la rápida e inmediata afluencia de pacientes que recibí en pocos meses. Fue un descubrimiento para mí. Además aporta confianza al paciente antes del pago a través de una toma de contacto confidencial entre paciente/ psicólogo a través del chat.",
  },
  {
    image: Avatar_3,
    title: "Amador Manero Moreno",
    subtitle: "Núm. Colegiado 30410",
    score: 5,
    text: "<p>La plataforma Necesitaria.es es una plataforma con la que me siento muy cómodo trabajando porque puedo recibir solicitudes de clientes, en cualquier momento del día, avisándome por SMS de las interacciones que se producen en cada momento. De esta forma, podemos contestar rápida y diligentemente las primeras necesidades de nuestros clientes, mediante un chat, desde cualquier dispositivo (móvil, tablet, ordenador), etc.</p> <p>La plataforma nos permite entender rápidamente cuál es la petición de solicitud de terapia de forma resumida y, empezar una conversación fluida y ágil con nuestros clientes desde el primer momento, para determinar la agenda de la primera sesión terapéutica.</p> <p>Otra de las cosas que me gustan mucho de la plataforma es que te permite saber, de forma muy fácil, mediante un SMS, cuándo el cliente ha pagado la primera sesión y, puedes empezar a realizar la primera toma de contacto, cuando ha respondido y leído tus mensajes, de forma totalmente desatendida por nuestra parte.</p>",
  },
];
