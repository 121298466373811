import { FC } from "react";
import css from "./Flag.module.scss";
import { hasFlag } from "country-flag-icons";
import flags from "country-flag-icons/react/3x2";
import cn from "classnames";

interface FlagProps {
  code: string;
  className?: string;
}

const Flag: FC<FlagProps> = ({ code, className }) => {
  const Flag = hasFlag(code) && flags[code as keyof typeof flags];

  return (
    <div className={cn(className, css.flag)}>
      {Flag ? (
        <Flag className={css.flag__icon} />
      ) : (
        <span className={css.flag__text}>{code}</span>
      )}
    </div>
  );
};

export default Flag;
