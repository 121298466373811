import React, { useEffect, useState } from "react";
import { Form, Formik, FormikErrors, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import cn from "classnames";

import { Step3TypesProps, Step3TypesValues } from "./Step3Types";
import { Routes } from "src/constants/routes";
import {
  questionnaireSelector,
  updateData,
} from "src/store/questionnaireSlice";
import {
  getAllSpainData,
  referencePaymentMethodsSelector,
  referenceMethodsOfConsultationSelector,
  referenceLanguagesSelector,
} from "src/store/referenceSlice";
import Button from "src/components/UI-kit/Button/Button";
import FieldChange from "src/components/UI-kit/FieldChange/FieldChange";
import FieldCheckbox from "src/components/UI-kit/FieldCheckbox/FieldCheckbox";

import css from "../Questionnaire.module.scss";
import Preloader from "src/components/Preloader/Preloader";

const Step3: React.FC<Step3TypesProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { step } = useParams<{ step: string }>();

  const paymentMethods = useSelector(referencePaymentMethodsSelector);
  const methodsOfConsultation = useSelector(
    referenceMethodsOfConsultationSelector
  );
  const languages = useSelector(referenceLanguagesSelector);
  const questionnaire = useSelector(questionnaireSelector);

  const thisData = questionnaire.data.map(
    (item) => item.step === step && item.values
  );

  const initData: /* Step3TypesValues */ any = thisData[2] || {
    paymentMethods: [],
    methodsOfConsultation: [],
    languages: [],
  };

  useEffect(() => {
    dispatch(getAllSpainData());
  }, [dispatch]);

  const [err, setErr] = useState<FormikErrors<Step3TypesValues>>({});
  const [formErrorMessage, setFormErrorMessage] = useState("");

  const handleSubmit = (values: Step3TypesValues) => {
    // workerClickRegister();

    nextStep(+step + 1);
    dispatch(updateData({ values, step }));
  };

  const handleStepBack = (values: Step3TypesValues) => {
    prevStep(+step - 1);
    dispatch(updateData({ values, step }));
  };

  const validate = (values: Step3TypesValues) => {
    const errors: FormikErrors<Step3TypesValues> = {};

    if (!values.paymentMethods.length) {
      errors.paymentMethods = "modalidades de pago";
    }
    if (!values.methodsOfConsultation.length) {
      errors.methodsOfConsultation = "programas";
    }
    if (!values.languages.length) {
      errors.languages = "idiomas";
    }

    return errors;
  };

  useEffect(() => {
    const msgErrors = {
      paymentMethods: {
        has: !!err.paymentMethods,
        text: "modalidades de pago",
      },
      methodsOfConsultation: {
        has: !!err.methodsOfConsultation,
        text: "programas",
      },
      languages: {
        has: !!err.languages,
        text: "idiomas",
      },
    };

    const errors = Object.values(msgErrors).filter((m) => m.has);

    if (errors.length) {
      setFormErrorMessage(
        `Para registrarse completa: ` +
          errors.map((m) => m.text).join(", ") +
          "."
      );
    } else {
      setFormErrorMessage("");
    }
  }, [err]);

  const nextStep = (step: number) => {
    step !== 5
      ? history.push(`${Routes.worker.register.questionnaire}/${step}`)
      : history.push(Routes.worker.register.onboarding);
  };

  const prevStep = (step: number) => {
    step !== 0
      ? history.push(`${Routes.worker.register.questionnaire}/${step}`)
      : history.push(Routes.worker.register.onboarding);
  };

  if (
    paymentMethods.isLoading ||
    methodsOfConsultation.isLoading ||
    languages.isLoading
  ) {
    return <Preloader className={css.preloader} />;
  }

  return (
    <Formik
      initialValues={initData}
      enableReinitialize
      onSubmit={handleSubmit}
      validate={validate}
      validateOnMount={true}
    >
      {({ values, errors, dirty }: FormikProps<Step3TypesValues>) => (
        <Form className={css.questionnaire__wrap}>
          <div className={css.questionnaire__checkboxWrap}>
            <div
              className={cn(
                "b-content b-content_size b-content_size_xs",
                css.questionnaire__subtitle
              )}
            >
              <p>Modalidades de pago (para clientes online)*</p>
            </div>

            <div role="group">
              {!!paymentMethods.data &&
                !!paymentMethods.data.length &&
                paymentMethods.data.map((n) => {
                  return (
                    <FieldCheckbox
                      className={css.questionnaire__fieldCheckbox}
                      key={n.id}
                      name="paymentMethods"
                      label={n.name}
                      value={String(n.id)}
                    />
                  );
                })}
            </div>
          </div>

          <div className={css.questionnaire__checkboxWrap}>
            <div
              className={cn(
                "b-content b-content_size b-content_size_xs",
                css.questionnaire__subtitle
              )}
            >
              <p>Aplicaciones que usas*</p>
            </div>

            <div role="group">
              {!!methodsOfConsultation.data &&
                !!methodsOfConsultation.data.length &&
                methodsOfConsultation.data.map((n) => {
                  return (
                    <FieldCheckbox
                      className={css.questionnaire__fieldCheckbox}
                      key={n.id}
                      name="methodsOfConsultation"
                      label={n.name}
                      value={String(n.id)}
                    />
                  );
                })}
            </div>
          </div>

          <div className={css.questionnaire__checkboxWrap}>
            <div
              className={cn(
                "b-content b-content_size b-content_size_xs",
                css.questionnaire__subtitle
              )}
            >
              <p>Idiomas de consulta*</p>
            </div>

            <div role="group">
              {!!languages.data &&
                !!languages.data.length &&
                languages.data.map((n) => {
                  return (
                    <FieldCheckbox
                      className={css.questionnaire__fieldCheckbox}
                      key={n.id}
                      name="languages"
                      label={n.name}
                      value={String(n.id)}
                    />
                  );
                })}
            </div>
          </div>

          <div className="divider"></div>

          <Button
            transparent
            className={css.questionnaire__btn}
            type="button"
            text="Volver"
            onClick={() => handleStepBack(values)}
          />

          <Button
            className={css.questionnaire__btn}
            type="submit"
            text="Continuar"
            disabled={!!Object.keys(errors).length}
          />

          {!!formErrorMessage.length && (
            <div className={css.questionnaire__alert}>
              <p>{formErrorMessage}</p>
            </div>
          )}

          <FieldChange<Step3TypesValues> onError={setErr} />
        </Form>
      )}
    </Formik>
  );
};

export default Step3;
