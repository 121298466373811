import React, { useState, useEffect } from "react";
import cn from "classnames";
import Select, { components } from "react-select";
import { FieldHookConfig, useField } from "formik";
import { ReactComponent as DropdownSvg } from "src/assets/img/icons/Systems/arrow-drop-down-line.svg";
import {
  dayOptions,
  monthOptions,
  yearOptions,
  yearOptionsToUp,
} from "./FieldBirthdateData";
import { FieldBirthdateProps } from "./FieldBirthdateType";
import css from "./FieldBirthdate.module.scss";

const PlaceholderDay = (props: any) => {
  return <components.Placeholder {...props}>Día</components.Placeholder>;
};
const PlaceholderMonth = (props: any) => {
  return <components.Placeholder {...props}>Mes</components.Placeholder>;
};
const PlaceholderYear = (props: any) => {
  return <components.Placeholder {...props}>Año</components.Placeholder>;
};

const IndicatorsContainer = (props: any) => {
  return (
    <components.IndicatorsContainer {...props}>
      <DropdownSvg />
    </components.IndicatorsContainer>
  );
};

const FieldBirthdate: React.FC<
  FieldHookConfig<string> & FieldBirthdateProps
> = (props) => {
  const {
    className,
    label,
    required,
    placeholder,
    disabled,
    value,
    defaultValue,
    defaultDay,
    defaultMonth,
    defaultYear,
    yearUp = false,
    ...config
  } = props;
  const [focusDay, setFocusDay] = useState(false);
  const [focusMonth, setFocusMonth] = useState(false);
  const [focusYear, setFocusYear] = useState(false);
  const [day, setDay] = useState(!!defaultDay ? defaultDay : "");
  const [month, setMonth] = useState(!!defaultMonth ? defaultMonth : "");
  const [year, setYear] = useState(!!defaultMonth ? defaultMonth : "");
  const [field, meta, helper] = useField(config);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (!!defaultDay && !!defaultMonth && !!defaultYear) {
      setTrigger(true);
    }
  }, [defaultDay, defaultMonth, defaultYear]);

  useEffect(() => {
    if (trigger) {
      helper.setValue(`${defaultYear}-${defaultMonth}-${defaultDay}`);
    }
  }, [trigger]); // eslint-disable-line

  useEffect(() => {
    helper.setValue(`${year}-${month}-${day}`);
  }, [day, month, year]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!(focusDay || focusMonth || focusYear)) {
      helper.setTouched(true, false);
    }
  }, [focusDay, focusMonth, focusYear]); // eslint-disable-line react-hooks/exhaustive-deps

  const defaultDayOption = dayOptions.find((v) => v.value === defaultDay);
  const defaultMonthOption = monthOptions.find((v) => v.value === defaultMonth);
  const defaultYearOption = yearUp
    ? yearOptionsToUp.find((v) => v.value === defaultYear)
    : yearOptions.find((v) => v.value === defaultYear);

  return (
    <div className={cn(className, css.select)}>
      {label && (
        <div className={css.select__label}>
          {`${label}${required ? "*" : ""}`}
        </div>
      )}

      <div className={css.select__all}>
        <div className={css.select__day}>
          <Select
            components={{ Placeholder: PlaceholderDay, IndicatorsContainer }}
            className={cn(css.select__component, css.select__component_date, {
              [css.select__component_error]: meta.touched && !!meta.error,
            })}
            classNamePrefix="react-select"
            isDisabled={disabled}
            isSearchable={false}
            options={dayOptions}
            defaultValue={defaultDayOption}
            onChange={(option) => {
              setDay(option?.value || "");
            }}
            onBlur={() => {
              setFocusDay(false);
            }}
            onFocus={() => {
              setFocusDay(true);
            }}
          />
        </div>
        <div className={css.select__month}>
          <Select
            components={{ Placeholder: PlaceholderMonth, IndicatorsContainer }}
            className={cn(css.select__component, css.select__component_month, {
              [css.select__component_error]: meta.touched && !!meta.error,
            })}
            classNamePrefix="react-select"
            isDisabled={disabled}
            isSearchable={false}
            options={monthOptions}
            defaultValue={defaultMonthOption}
            onChange={(option) => {
              setMonth(option?.value || "");
            }}
            onBlur={() => {
              setFocusMonth(false);
            }}
            onFocus={() => {
              setFocusMonth(true);
            }}
          />
        </div>

        <div className={css.select__year}>
          <Select
            components={{ Placeholder: PlaceholderYear, IndicatorsContainer }}
            className={cn(css.select__component, css.select__component_year, {
              [css.select__component_error]: meta.touched && !!meta.error,
            })}
            classNamePrefix="react-select"
            isDisabled={disabled}
            isSearchable={false}
            options={yearUp ? yearOptionsToUp : yearOptions}
            defaultValue={defaultYearOption}
            onChange={(option) => {
              setYear(option?.value || "");
            }}
            onBlur={() => {
              setFocusYear(false);
            }}
            onFocus={() => {
              setFocusYear(true);
            }}
          />
        </div>
        <input type="hidden" {...field} />
      </div>

      {meta.touched && !!meta.error && (
        <div className={css.select__error}>{meta.error}</div>
      )}
    </div>
  );
};

export default FieldBirthdate;
