import { FC, useEffect, useRef, useState } from "react";
import css from "./Profile.module.scss";
import cn from "classnames";
import { ProfileProps } from "./ProfileTypes";
import ProfileHeader from "src/components/Profile/Header/Header";
import ProfileBody from "src/components/Profile/Body/Body";
import { getNotAuthWorkerReviews, getWorkerReviews } from "src/store/taskSlice";
import { useDispatch, useSelector } from "react-redux";
import { resetReviews, workerSelector } from "src/store/workerSlice";
import { useHistory, useParams } from "react-router-dom";
import {
  clientLinkProfileToChat,
  ecommerce,
  naLinkProfileToChat,
} from "src/helpers/metric";
import Button from "src/components/UI-kit/Button/Button";
import { Routes } from "src/constants/routes";
import { useDetectVisibleElement } from "src/hooks/useDetectVisibleElement";
import MiniCardWorker from "src/components/MiniCardWorker/MiniCardWorker";
import { useHashFragment } from "src/hooks/useHashFragment";

const Profile: FC<ProfileProps> = ({
  worker,
  className,
  scrollBlockId,
  isLogged,
}) => {
  const dispatch = useDispatch();
  const scrollToHashElement = useHashFragment(55);
  const history = useHistory();

  const { reviews, isLogged: workerLogged } = useSelector(workerSelector);
  const [commentsPage, setCommentsPage] = useState(1);
  const { workerId } = useParams<{ workerId: string }>();
  const buttonRef = useRef<HTMLDivElement>(null);
  const visibleButton = useDetectVisibleElement(buttonRef, true);

  useEffect(() => {
    if (!isLogged && !workerLogged) {
      ecommerce("detail", {
        id: worker.id,
        name: `${worker.firstName} ${worker.lastName} ${worker.lastNameTwo}`,
        sex: worker.sex,
        category: worker.range,
        price: worker.consultation.costConsultation,
      });
    }
  }, [worker, isLogged, workerLogged, dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      // если ставить y = 0 то шапка уезжает
      window.scrollTo(0, 1);
    }, 100);

    if (isLogged) {
      dispatch(getWorkerReviews(1, 3, false, worker.id));
    } else {
      dispatch(getNotAuthWorkerReviews(1, 3, false, worker.id));
    }

    return () => {
      dispatch(resetReviews());
      clearTimeout(timer);
    };
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    const commentsLimit = 3;
    if (commentsPage === 1) return;

    if (isLogged) {
      dispatch(
        getWorkerReviews(
          commentsPage,
          commentsLimit,
          Boolean(reviews.reviews.length),
          worker.id
        )
      );
    } else {
      dispatch(
        getNotAuthWorkerReviews(
          commentsPage,
          commentsLimit,
          Boolean(reviews.reviews.length),
          worker.id
        )
      );
    }
  }, [commentsPage, dispatch]); // eslint-disable-line

  const metrikToPerfil = () => {
    if (workerLogged) return;

    if (!isLogged) {
      naLinkProfileToChat();
    } else {
      clientLinkProfileToChat();
    }
  };

  return (
    <div className={cn(className, css.profile)}>
      <div className="container">
        <ProfileHeader
          className={css.profile__header}
          onClickReviews={() => {
            history.push(`${Routes.client.show}/${worker.id}#reviews`);
            scrollToHashElement();
          }}
          {...worker}
        />

        <div ref={buttonRef}>
          <Button
            text="Chat"
            asLink
            to={`${Routes.client.chat}/${worker.id}`}
            onClick={metrikToPerfil}
            className={css.profile__button}
          />
        </div>

        <ProfileBody
          workerId={workerId}
          reviews={reviews}
          showMoreComments={() => setCommentsPage(commentsPage + 1)}
          className={css.profile__body}
          scrollBlockId={scrollBlockId}
          isLogged={isLogged}
          {...worker}
        />
      </div>

      <div
        className={cn(css.profile__banner, css.banner, {
          [css.banner_visible]: !visibleButton,
        })}
      >
        <div className={cn("container", css.banner__card)}>
          <MiniCardWorker worker={worker} />
          <Button
            text="Chat"
            asLink
            to={`${Routes.client.chat}/${worker.id}`}
            onClick={metrikToPerfil}
            className={css.banner__link}
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;
