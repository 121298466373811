import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cn from "classnames";
import css from "./InternationalPhone.module.scss";
import countriesName from "src/data/countryNames.json";

import {
  CountryCode,
  getCountryCallingCode,
  getCountries,
} from "libphonenumber-js";
import { AsYouType, validatePhoneNumberLength } from "libphonenumber-js/mobile";
import { InternationalPhoneProps } from "./InternationalPhoneTypes";
import Dropdown from "./Dropdown/Dropdown";
import SelectedCountry from "./SelectedCountry/SelectedCountry";
import { useClickOut } from "src/hooks/useClickOut";
import { FieldHookConfig, useField } from "formik";

const getPlaceholder = (countryCode: CountryCode) => {
  return "+" + getCountryCallingCode(countryCode);
};

const InternationalPhone: FC<
  FieldHookConfig<string> & InternationalPhoneProps
> = ({
  className,
  isBlur,
  onBlur = (a?: any) => a,
  onFocus = (a?: any) => a,
  onRef,
  placeholder,
  initFocus,
  ...config
}) => {
  const [dropdownShow, setDropdownShow] = useState<boolean>(false);
  const [selectedCountry, setCountry] = useState<CountryCode>("ES"); // AE GB MX ES RU
  const [value, setValue] = useState<string>("");
  const dropdownRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [{ name }, meta, helpers] = useField(config);

  useClickOut(() => setDropdownShow(false), dropdownRef, dropdownShow);

  useEffect(() => {
    const timer = setTimeout(() => {
      initFocus && inputRef.current?.focus();
    }, 1);

    return () => clearTimeout(timer);
  }, []); // eslint-disable-line

  const reset = useCallback(() => {
    setValue("");
  }, []);

  useEffect(() => {
    onRef && onRef({ reset });
  }, [reset]); // eslint-disable-line

  const countriesCode = useMemo<CountryCode[]>(() => {
    const filterCodes = getCountries().filter((c) => countriesName[c]);

    const sortCodes = filterCodes.sort((a, b) => {
      if (countriesName[a] < countriesName[b]) return -1;
      if (countriesName[a] > countriesName[b]) return 1;
      return 0;
    });

    return sortCodes;
  }, []);

  const handleBlur = () => {
    helpers.setTouched(true);
    onBlur();

    if (!value.replace(getPlaceholder(selectedCountry), "").length) {
      setValue("");
    }
  };

  const handleFocus = () => {
    if (!value.replace(getPlaceholder(selectedCountry), "").length) {
      setValue(getPlaceholder(selectedCountry));
    }
  };

  const handleChangeCountry = (code: CountryCode) => {
    setCountry(code);
    setDropdownShow(false);
    setValue("");
    helpers.setValue("");
  };

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;

    const asYouType = new AsYouType(selectedCountry);
    asYouType.input(target.value);
    const number = asYouType.getChars() || "";
    const placeholder = getPlaceholder(selectedCountry);
    const lenght = validatePhoneNumberLength(number, selectedCountry);

    if (lenght === "TOO_LONG") return;

    const newValue = number.startsWith(placeholder) ? number : placeholder;

    setValue(newValue);

    if (!asYouType.isValid() || newValue.length < 4) {
      helpers.setValue(newValue.replace(placeholder, "") ? "INCORRECT" : "");
      return;
    }

    helpers.setValue(newValue);
  };

  if (!countriesCode.length) return <></>;

  return (
    <div
      className={cn(className, css.phone, {
        [css.phone_error]: meta.touched && !!meta.error,
      })}
    >
      <div className={css.phone__label}>
        {placeholder ? placeholder : "Teléfono*"}
      </div>

      <div className={css.phone__top}>
        <SelectedCountry
          active={dropdownShow}
          selectedCountry={selectedCountry}
          onClick={() => setDropdownShow((state) => !state)}
          className={css.phone__select}
        />

        <div className={css.phone__inputContainer}>
          <input
            type="tel"
            placeholder={getPlaceholder(selectedCountry)}
            value={value}
            onChange={handleChangeValue}
            onBlur={handleBlur}
            onFocus={handleFocus}
            autoComplete={"off"}
            readOnly={isBlur}
            className={cn(css.phone__input, "ym-record-keys")}
            name={name}
            ref={inputRef}
          />
        </div>
      </div>

      <div ref={dropdownRef}>
        <Dropdown
          codes={countriesCode}
          names={countriesName}
          show={dropdownShow}
          selectCountry={handleChangeCountry}
        />
      </div>

      {meta.touched && !!meta.error ? (
        <div className={cn(css.phone__error)}>{meta.error}</div>
      ) : null}
    </div>
  );
};

export default InternationalPhone;
