import { FC } from "react";
import { TypingProps } from "./TypingTypes";
import css from "./Typing.module.scss";
import cn from "classnames";

const Typing: FC<TypingProps> = ({ typingUsers, className }): JSX.Element => {
  return (
    <div className={cn(className, css.typingUsers)}>
      {typingUsers.map((user) => (
        <div key={user.userId} className={css.typingUsers__item}>
          {user.nickname} escribiendo
          <div className={css.typingUsers__dots}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Typing;
