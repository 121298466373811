import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import css from "./MiniCardWorker.module.scss";
import cn from "classnames";
import { Routes } from "src/constants/routes";
import { Worker } from "src/types/Worker";
import avatar from "src/assets/img/user.svg";
import avatarMale from "src/assets/img/user-male.svg";
import avatarFemale from "src/assets/img/user-female.svg";
import { CheckMark } from "src/assets/icons";

interface IMiniCardWorkerProps {
  worker: Worker;
  showSubtitle?: boolean;
  onLink?: () => void;
  className?: string;
  showStatus?: boolean;
}

const MiniCardWorker: FC<IMiniCardWorkerProps> = ({
  onLink = () => "",
  worker,
  showSubtitle = true,
  showStatus,
  className,
}) => {
  const location = useLocation();
  const isProfilepage = location.pathname === `${Routes.client.show}/${worker.id}`;

  const defaultAvatar =
    worker.sex === 1 ? avatarMale : worker.sex === 2 ? avatarFemale : avatar;
  const consultation = worker.consultation;

  return (
    <div className={cn(className, css.card)}>
      <Link
        to={`${Routes.client.show}/${worker.id}`}
        onClick={onLink}
        className={cn(css.card__imgContainer, {
          [css.notLink]: isProfilepage,
          [css.card__imgContainer_chat]: showStatus
        })}
      >
        <img
          src={worker.photoPathSquare || worker.photoPath || defaultAvatar}
          alt="avatar"
          onError={(e) => {
            (e.target as HTMLImageElement).src = defaultAvatar;
          }}
          className={css.card__img}
        />
        {showStatus && (
          <div className={css.card__status}>
            <CheckMark className={css.card__icon} />
          </div>
        )}
      </Link>

      <div className={css.card__text}>
        <Link
          to={`${Routes.client.show}/${worker.id}`}
          onClick={onLink}
          className={cn(css.card__title, {
            [css.notLink]: isProfilepage
          })}
        >
          {worker.firstName} {worker.lastName} {worker.lastNameTwo}
        </Link>

        {showSubtitle &&
          !!consultation.costConsultation &&
          !!consultation.durationConsultation && (
            <Link
              to={`${Routes.client.show}/${worker.id}`}
              onClick={onLink}
              className={cn(css.card__subtitle, {
                [css.notLink]: isProfilepage
              })}
            >
              Desde {consultation.costConsultation} € /{" "}
              {consultation.durationConsultation} min
            </Link>
          )}
        <p className={css.card__subtitle}></p>
      </div>
    </div>
  );
};

export default MiniCardWorker;
