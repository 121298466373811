import React, { useEffect } from "react";
import cn from "classnames";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { QuestionnaireTypesProps } from "./QuestionnaireTypes";
import { workerSelector } from "src/store/workerSlice";
import { authSelector } from "src/store/authSlice";
import { Routes } from "src/constants/routes";

import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import Step4 from "./Step4/Step4";
import Preloader from "src/components/Preloader/Preloader";

import css from "./Questionnaire.module.scss";
import { questionnaireSelector } from "src/store/questionnaireSlice";

const Questionnaire: React.FC<QuestionnaireTypesProps> = () => {
  const { step } = useParams<{ step: string }>();
  const history = useHistory();
  const { worker } = useSelector(workerSelector);
  const { isAuthWorker } = useSelector(authSelector);
  const questionnaire = useSelector(questionnaireSelector);

  useEffect(() => {
    if (window.innerWidth < 1023) {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 1);
    }
  }, [step]);

  useEffect(() => {
    if (!isAuthWorker) {
      history.push(Routes.home);
    }
    if (isAuthWorker && worker?.isStatus !== 3) {
      if (worker?.isStatus === 1) {
        history.push(Routes.worker.taskList);
      }
      if (worker?.isStatus === 4) {
        history.push(Routes.worker.register.callback);
      }
      if (worker?.isStatus === 5) {
        history.push(Routes.worker.register.final);
      }
    }
    if (step !== "1") {
      questionnaire.data.length === 0 &&
        history.push(`${Routes.worker.register.questionnaire}/1`);
    }
  }, [worker, isAuthWorker]); // eslint-disable-line

  if (worker?.isStatus !== 3) {
    return <Preloader />;
  }

  return (
    <div className={css.questionnaire}>
      <div className={cn(css.questionnaire__header, css.header)}>
        <div className={cn("container", css.header__wrapper)}>
          <h2 className={cn("h2", css.header__title)}>
            Rellenar mi perfil:{""}{" "}
            <span style={{ whiteSpace: "nowrap" }}>{`${step} / 4`}</span>
          </h2>
        </div>
      </div>

      <div className={cn("container", css.questionnaire__main)}>
        {step === "1" && <Step1 />}
        {step === "2" && <Step2 />}
        {step === "3" && <Step3 />}
        {step === "4" && <Step4 />}
      </div>
    </div>
  );
};

export default Questionnaire;
