import { FC } from "react";
import { Link } from "react-router-dom";
import { Routes } from "src/constants/routes";
import Body from "./Body/Body";
import Header from "./Header/Header";
import css from "./Item.module.scss";
import cn from "classnames";
import { IItem } from "./ItemTypes";

const Item: FC<IItem> = ({ task, consultation }) => {
  return (
    <Link className={css.item} to={`${Routes.worker.task}/${task.id}`}>
      <div className={css.item__wrapper}>
        <Header task={task} className={css.item__header} />

        <Body
          className={css.item__body}
          data={{ ...task, consultation: consultation }}
        />
      </div>

      <div className={cn("divider", css.item__divider)} />
    </Link>
  );
};

export default Item;
