import React, { useEffect, useState } from "react";
import css from "./Verification.module.scss";
import cn from "classnames";
import { MessageYes } from "src/assets/graphIcons";
import Button from "src/components/UI-kit/Button/Button";
import { useHistory } from "react-router-dom";
import { phoneTransform } from "src/helpers/phoneTransform";
import { useDispatch, useSelector } from "react-redux";
import {
  createWorkerConfirmSelector,
  sendCode,
  sendRequestCode,
  confirmCodeInit,
  sendFormInit,
  createWorkerPhoneSelector,
} from "src/store/createWorkerSlice";
import { Formik, Form, FormikProps, FormikErrors } from "formik";
import FieldText from "src/components/UI-kit/FieldText/FieldText";
import { getWorkerData } from "src/store/workerSlice";
import { Routes } from "src/constants/routes";

interface FormVerification {
  phone: string;
  code: string;
}

const UpdateError = ({ error, setError }: any) => {
  useEffect(() => {
    if (!!error) setError("code", "Código incorrecto");
  }, [error]); // eslint-disable-line
  return null;
};

const Verification: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading, isLoaded, error } = useSelector(
    createWorkerConfirmSelector
  );
  const phone = useSelector(createWorkerPhoneSelector);
  const [seconds, setSeconds] = useState(60);
  const [timerActive, setTimerActive] = useState(true);

  useEffect(() => {
    return () => {
      dispatch(sendFormInit());
    };
  }, [dispatch]);

  useEffect(() => {
    if (phone === "") {
      history.push(Routes.worker.register.main);
      return;
    }
  }, [history, phone]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    if (seconds > 0 && timerActive) {
      timeout = setTimeout(setSeconds, 1000, seconds - 1);
    } else {
      setTimerActive(false);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [seconds, timerActive]);

  useEffect(() => {
    if (isLoaded && error === null) {
      history.push(Routes.worker.register.onboarding);
      dispatch(confirmCodeInit());
      dispatch(getWorkerData());
    }
  }, [isLoaded, error]); // eslint-disable-line

  const handleSubmit = (data: FormVerification) => {
    dispatch(sendCode(data));
  };

  const validate = (values: FormVerification) => {
    const errors: FormikErrors<FormVerification> = {};

    if (!values.code) {
      errors.code = "Введите код";
    }

    return errors;
  };

  return (
    <div className={cn(css.verification, "container", "fullpage")}>
      <div className={css[`verification-icon`]}>
        <MessageYes />
      </div>
      <div className={css[`verification-title`]}>Verifica tu NÚMERO</div>
      <div className={css[`verification-desc`]}>
        Hemos enviado el código al{" "}
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {!!phone && phoneTransform(phone)}
        </span>
      </div>

      <Formik
        initialValues={{
          phone,
          code: "",
        }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ dirty, errors, setFieldError }: FormikProps<FormVerification>) => (
          <Form>
            <FieldText
              className={css[`verification-input`]}
              name={"code"}
              type={"text"}
              placeholder={"_ _ _ _"}
              required
            />
            {timerActive ? (
              <div className={css[`verification-hint`]}>
                Puedes solicitar otro código en{" "}
                <span>
                  00:
                  {String(seconds).length === 1 ? `0${seconds}` : seconds}
                </span>
              </div>
            ) : (
              <div
                className={cn(css[`verification-hint`], css.btn)}
                onClick={() => {
                  dispatch(sendRequestCode({ phone }));
                  setTimerActive(true);
                  setSeconds(60);
                }}
              >
                Enviar otro código
              </div>
            )}
            <UpdateError error={error} setError={setFieldError} />
            <Button
              className={css[`verification-submit`]}
              type={"submit"}
              text={"Verificar"}
              disabled={isLoading || !dirty || !!Object.keys(errors).length}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Verification;
