import {
  ChangeEvent,
  FC,
  FormEvent,
  Fragment,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormProps } from "./FormTypes";
import css from "./Form.module.scss";
import cn from "classnames";
import { Send } from "src/assets/icons";
import { useDetectOS } from "src/hooks/useDetectOS";

const Form: FC<FormProps> = ({
  className,
  disabledSend,
  initialValue,
  handleWrapInputMessage,
  onSendMessage,
  onStartTyping,
  onEndTyping,
  disableAutoFocus,
  handleScrollOnFocus,
}): JSX.Element => {
  const OS = useDetectOS();
  const [value, setValue] = useState<string>("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const valueRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!value && initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]); // eslint-disable-line

  useEffect(() => {
    const { current: wrapper } = wrapperRef;
    const { current: value } = valueRef;

    if (!wrapper || !value) return;

    const oldHeight = wrapper.offsetHeight;
    const newHeight = value.offsetHeight;

    if (oldHeight !== newHeight) {
      handleWrapInputMessage();
      wrapper.style.height = newHeight + "px";
    }
  }, [value]); // eslint-disable-line

  const handleSendMessage = () => {
    if (value.trim() && !disabledSend) {
      if (!disableAutoFocus) {
        textareaRef.current?.focus();
      } else {
        textareaRef.current?.blur();
      }

      onSendMessage(value);
      setValue("");
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSendMessage();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") event.preventDefault();
    const { current: textarea } = textareaRef;

    if (event.key === "Enter") {
      if (OS !== "Other" || event.shiftKey) {
        setValue((value) => value + "\n");

        textarea &&
          setTimeout(() => {
            textarea.scrollTo({
              top: textarea.scrollHeight,
              behavior: "smooth",
            });
          }, 1);

        return;
      }

      handleSendMessage();
    } else {
      onStartTyping();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const handleBlur = () => {
    onEndTyping();
  };

  const handleFocus = () => {
    if (OS === "Android") {
      setTimeout(() => {
        handleScrollOnFocus();
      }, 300);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={cn(className, css.form)}>
      <div className={css.form__wrapper} ref={wrapperRef}>
        <div className={css.form__field}>
          <div className={css.form__textareaWrap}>
            <textarea
              value={value}
              placeholder="El texto de tu mensaje"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              onFocus={handleFocus}
              className={css.form__textarea}
              ref={textareaRef}
            />
          </div>

          <div className={css.form__value} ref={valueRef}>
            {value.split("\n").map((paragraph: string, index: number) => (
              <Fragment key={index}>
                {paragraph} <br />
              </Fragment>
            ))}
          </div>
        </div>

        <button
          className={cn(css.form__button, css.button, {
            [css.button_loading]: disabledSend,
          })}
          disabled={!value || disabledSend}
        >
          <Send className={css.button__icon} />
        </button>
      </div>
    </form>
  );
};

export default Form;
