import React from "react";
import cn from "classnames";
import Select, { components } from "react-select";
import { FieldHookConfig, useField } from "formik";
import { ReactComponent as DropdownSvg } from "src/assets/img/icons/Systems/arrow-drop-down-line.svg";
import css from "./FieldSelect.module.scss";

export interface FieldSelectOption<T = string> {
  label: string;
  value: T;
}

interface FieldSelectProps {
  className?: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  options: FieldSelectOption[];
  isLoading?: boolean;
}

const Placeholder = (props: any) => {
  return <components.Placeholder {...props}>Elige</components.Placeholder>;
};

const IndicatorsContainer = (props: any) => {
  return (
    <components.IndicatorsContainer {...props}>
      <DropdownSvg />
    </components.IndicatorsContainer>
  );
};

const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      No hay opciones
    </components.NoOptionsMessage>
  );
};

const LoadingMessage = (props: any) => {
  return (
    <components.LoadingMessage {...props}>Carga...</components.LoadingMessage>
  );
};

const FieldSelect: React.FC<FieldHookConfig<string> & FieldSelectProps> = (
  props
) => {
  const {
    className,
    label,
    required,
    placeholder,
    disabled,
    value,
    defaultValue,
    options,
    isLoading,
    ...config
  } = props;
  const [field, meta, { setValue, setTouched }] = useField(config);

  const defaultOption = options.find((v) => v.value === defaultValue);

  return (
    <div className={cn(className, css.select)}>
      {label && (
        <div className={css.select__label}>
          {`${label}${required ? "*" : ""}`}
        </div>
      )}

      <Select
        components={{
          Placeholder,
          IndicatorsContainer,
          NoOptionsMessage,
          LoadingMessage,
        }}
        className={cn(css.select__component, {
          [css.select__component_error]: meta.touched && !!meta.error,
        })}
        classNamePrefix="react-select"
        isDisabled={disabled}
        isSearchable={false}
        defaultValue={defaultOption}
        value={
          meta.value === ""
            ? defaultOption
            : options.find((v) => v.value === meta.value)
        }
        options={options}
        name={field.name}
        onChange={(option) => {
          setValue(typeof option === "string" ? option : option?.value || "");
        }}
        onBlur={() => {
          setTouched(true, false);
        }}
        isLoading={isLoading}
      />

      {meta.touched && !!meta.error && (
        <div className={css.select__error}>{meta.error}</div>
      )}
    </div>
  );
};

export default FieldSelect;
