import { parsePhoneNumber } from "libphonenumber-js/min";

export const phoneTransform = (phone: string, hidden?: boolean) => {
  return parsePhoneNumber(phone).formatInternational();
  /* TODO make mask for number input to avoid errors in this part */
  /*let transformed = phone.split("");
  const country = `${transformed[0]}${transformed[1]}${transformed[2]}`;
  const operator = `${transformed[3]}${transformed[4]}${transformed[5]}`;
  const firstPart = `${transformed[6]}${transformed[7]}`;
  const secondPart = `${transformed[8]}${transformed[9]}`;
  const thirdPart = `${transformed[10] || "X"}${transformed[11] || "X"}`;
  return hidden
    ? `${country} ${operator} ${firstPart} ** **`
    : `${country} ${operator} ${firstPart} ${secondPart} ${thirdPart}`;*/
};
