import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { Formik, Form, FormikProps, FormikErrors } from "formik";
import { FormTypeProps, FormTypeValues } from "./FormProps";
import FieldText from "src/components/UI-kit/FieldText/FieldText";
import Button from "src/components/UI-kit/Button/Button";
import FieldSelect from "src/components/UI-kit/FieldSelect/FieldSelect";
import FieldBirthdate from "src/components/UI-kit/FieldBirthdate/FieldBirthdate";
import { formatSexWorker, re } from "src/constants/constants";
import {
  createWorkerSignupSelector,
  sendRequestCode,
  sendFormSignupInit,
  sendFormDataSignup,
  setPhone,
  createWorkerPhoneSelector,
} from "src/store/createWorkerSlice";
import css from "./Form.module.scss";
import { Routes } from "src/constants/routes";
import FieldChange from "src/components/UI-kit/FieldChange/FieldChange";
import { workerClickRegister } from "src/helpers/metric";
import InternationalPhone from "src/components/UI-kit/InternationalPhone/InternationalPhone";
import { Pation } from "src/assets/icons";
import {
  getAllSpainData,
  ICollegium,
  referenceCollegiumSelector,
} from "src/store/referenceSlice";
import { generateReferenceOptions } from "src/helpers/generateReferenceOptions";
import { authSelector } from "src/store/authSlice";
import { workerSelector } from "src/store/workerSlice";
import Preloader from "src/components/Preloader/Preloader";

const MainForm: React.FC<FormTypeProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const initData: FormTypeValues = {
    lastName: "",
    firstName: "",
    sex: "",
    birthDate: "",
    collegiumId: "",
    collegiate_number: "",
    phone: "",
    email: "",
  };
  const [err, setErr] = useState<FormikErrors<FormTypeValues>>({});
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const { isLoading, isLoaded, error } = useSelector(
    createWorkerSignupSelector
  );
  const collegium = useSelector(referenceCollegiumSelector);
  const phone = useSelector(createWorkerPhoneSelector);

  const { tokenWorker } = useSelector(authSelector);
  const { worker } = useSelector(workerSelector);

  useEffect(() => {
    if (tokenWorker) {
      if (worker?.isStatus === 1) {
        history.push(Routes.worker.taskList);
      }
      if (worker?.isStatus === 3) {
        history.push(Routes.worker.register.onboarding);
      }
      if (worker?.isStatus === 4) {
        history.push(Routes.worker.register.callback);
      }
      if (worker?.isStatus === 5) {
        history.push(Routes.worker.register.final);
      }
    }
  }, [worker, tokenWorker, history]);

  useEffect(() => {
    dispatch(sendFormSignupInit());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllSpainData());
  }, [dispatch]);

  const collegiumOptions = useMemo(
    () => generateReferenceOptions<ICollegium>(collegium?.data || []),
    [collegium]
  );

  // Логика переадресации на страницу подтверждения телефона
  useEffect(() => {
    if (isLoaded && error === null) {
      dispatch(sendRequestCode({ phone }));
      dispatch(sendFormSignupInit());
      history.push(Routes.worker.register.verification);
    }
  }, [isLoaded, error]); // eslint-disable-line

  const handleSubmit = (values: FormTypeValues) => {
    workerClickRegister();
    dispatch(setPhone(values.phone));
    dispatch(
      sendFormDataSignup({
        ...values,
        sex: +values.sex,
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        collegiumId: values.collegiumId.trim(),
        collegiate_number: values.collegiate_number.trim(),
      })
    );
  };

  const validate = (values: FormTypeValues) => {
    dispatch(sendFormSignupInit());
    const errors: FormikErrors<FormTypeValues> = {};

    if (!values.lastName.trim()) {
      errors.lastName = "Campo obligatorio";
    }

    if (!values.firstName.trim()) {
      errors.firstName = "Campo obligatorio";
    }

    if (!values.sex) {
      errors.sex = "Campo obligatorio";
    }

    if (!values.birthDate) {
      errors.birthDate = "Campo obligatorio";
    } else {
      const [year, month, day] = values.birthDate.split("-");
      const curDate = new Date();
      let newDate = new Date(+year, +month - 1, +day);

      if (
        !year ||
        !month ||
        !day ||
        newDate.getDate() !== +day ||
        newDate.getMonth() !== +month - 1 ||
        newDate.getFullYear() !== +year
      ) {
        errors.birthDate = "Formato erróneo";
      }

      if (+newDate > +new Date().setFullYear(curDate.getFullYear() - 18)) {
        errors.birthDate = "Debes tener más de 18 años";
      }
    }

    if (!values.collegiumId.trim()) {
      errors.collegiumId = "Campo obligatorio";
    }

    if (!values.collegiate_number.trim()) {
      errors.collegiate_number = "Campo obligatorio";
    }

    if (!values.phone) {
      errors.phone = "Campo obligatorio";
    } else if (values.phone === "INCORRECT") {
      errors.phone = "Formato erróneo";
    }

    if (!values.email) {
      errors.email = "Campo obligatorio";
    } else if (!re.email.test(values.email)) {
      errors.email = "Formato erróneo";
    }

    return errors;
  };

  useEffect(() => {
    const msgErrors = {
      lastName: {
        has: !!err.lastName,
        text: "apellido",
      },
      firstName: {
        has: !!err.firstName,
        text: "nombre",
      },
      sex: {
        has: !!err.sex,
        text: "género",
      },
      birthDate: {
        has: !!err.birthDate,
        text: "fecha de nacimiento",
      },
      collegiumId: {
        has: !!err.collegiumId,
        text: "colegio oficial",
      },
      collegiate_number: {
        has: !!err.collegiate_number,
        text: "número de colegiado",
      },
      phone: {
        has: !!err.phone,
        text: "teléfono",
      },
      email: {
        has: !!err.email,
        text: "correo electrónico",
      },
    };

    const errors = Object.values(msgErrors).filter((m) => m.has);

    if (errors.length) {
      setFormErrorMessage(
        `Para registrarse completa: ` +
          errors.map((m) => m.text).join(", ") +
          "."
      );
    } else {
      setFormErrorMessage("");
    }
  }, [err]);

  if (tokenWorker) {
    return <Preloader />;
  }

  return (
    <div className={css.form}>
      <div className={cn("container", css.form__wrapper)}>
        <h1 className={cn("h2", css.form__title)}>Registrarse</h1>

        <Formik
          initialValues={initData}
          enableReinitialize
          onSubmit={handleSubmit}
          validate={validate}
        >
          {({ values, errors, dirty }: FormikProps<FormTypeValues>) => (
            <Form className={css.form__wrap}>
              <FieldText
                className={css.form__field}
                name="firstName"
                type="text"
                label="Nombre"
                placeholder={"Introduce"}
                required
              />

              <FieldText
                className={css.form__field}
                name="lastName"
                type="text"
                label="Apellido 1"
                placeholder={"Introduce"}
                required
              />

              <FieldText
                className={css.form__field}
                name="lastNameTwo"
                type="text"
                label="Apellido 2"
                placeholder={"Introduce"}
              />

              <FieldSelect
                className={css.form__field}
                label="Género"
                name="sex"
                required
                options={[formatSexWorker[2], formatSexWorker[1]]}
              />

              <FieldBirthdate
                className={css.form__field}
                label="Fecha de nacimiento"
                name="birthDate"
                required
              />

              <div className={css.form__policy}>
                <Pation />

                <div className="b-content b-content_size b-content_size_xs">
                  <p>
                    Solamente trabajamos con psicólogos colegiados en España!
                  </p>
                </div>
              </div>

              <FieldSelect
                className={css.form__field}
                label="Colegio Oficial de Psicólogos en España"
                name="collegiumId"
                required
                options={collegiumOptions}
              />

              <FieldText
                className={css.form__field}
                name="collegiate_number"
                type="text"
                label={"Número de colegiado"}
                placeholder={"Introduce"}
                required
              />

              <InternationalPhone className={css.form__field} name="phone" />

              <FieldText
                className={css.form__field}
                name="email"
                type="text"
                label="Correo electrónico"
                placeholder={"Introduce"}
                required
              />

              <Button
                className={css.form__submit}
                type="submit"
                text="Registrarse"
                disabled={
                  !!Object.keys(errors).length || isLoading || !!error || !dirty
                }
              />

              {!!formErrorMessage.length && (
                <div className={css.form__alert}>
                  <p>{formErrorMessage}</p>
                </div>
              )}

              <FieldChange<FormTypeValues> onError={setErr} />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default MainForm;
