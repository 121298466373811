import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./strore";

export interface layoutState {
  mainLayout: {
    overflow: boolean;
  };
  header: {
    visibleToCatalog: boolean;
  };
  footer: {
    visible: boolean;
  };
}

const initialState: layoutState = {
  mainLayout: {
    overflow: false,
  },
  header: {
    visibleToCatalog: false,
  },
  footer: {
    visible: true,
  },
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setOverflow: (state: layoutState, { payload }) => {
      state.mainLayout.overflow = payload;
    },
    setVisibleHeaderToCatalog: (
      state: layoutState,
      { payload }: PayloadAction<layoutState["header"]["visibleToCatalog"]>
    ) => {
      state.header.visibleToCatalog = payload;
    },
    setVisibleFooter: (
      state: layoutState,
      { payload }: PayloadAction<layoutState["footer"]["visible"]>
    ) => {
      state.footer.visible = payload;
    },
  },
});

export const { setOverflow, setVisibleHeaderToCatalog, setVisibleFooter } =
  layoutSlice.actions;
export const layoutSelector = (state: RootState) => state.layout;
export const layoutHeaderSelector = (state: RootState) => state.layout.header;
export const layoutFooterSelector = (state: RootState) => state.layout.footer;

export default layoutSlice.reducer;
