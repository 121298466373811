import { FC, useState, useEffect, useMemo, useRef } from "react";
import { IFiltersProps } from "./FiltersTypes";
import cn from "classnames";
import css from "./Filters.module.scss";
import Button from "src/components/UI-kit/Button/Button";
import { Formik, FormikProps, Form as FormikForm } from "formik";
import FieldSelect from "src/components/UI-kit/FieldSelect/FieldSelect";
import { generateReferenceOptions } from "src/helpers/generateReferenceOptions";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSpainData,
  IForWhom,
  ISpecialization,
  referenceForWhomSelector,
  referenceSpecializationSelector,
} from "src/store/referenceSlice";
import FieldChange from "src/components/UI-kit/FieldChange/FieldChange";
import {
  createTaskFiltersSelector,
  setFilters,
} from "src/store/createNewTaskSlice";
import { FILTERS_STORANGE_KEY, formatSexWorker } from "src/constants/constants";
import { IFilters } from "src/types/Task";
import { storageInteraction } from "src/helpers/storageInteraction";
import { clientFilters, naFilters, userFilters } from "src/helpers/metric";
import { transformFilters } from "src/helpers/transformFilters";
import { Information, InformationArrow } from "src/assets/icons";

const Form: FC<IFiltersProps> = ({ className, isAuth, isWorker, onChange }) => {
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<IFilters>>(null);

  const forWhom = useSelector(referenceForWhomSelector);
  const specialization = useSelector(referenceSpecializationSelector);
  const initData = useSelector(createTaskFiltersSelector);
  const filter = useSelector(createTaskFiltersSelector);

  const [data, setData] = useState<IFilters>(initData);

  const forWhomOptions = useMemo(
    () => generateReferenceOptions<IForWhom>(forWhom?.data || []),
    [forWhom]
  );

  const specializationOptions = useMemo(
    () => generateReferenceOptions<ISpecialization>(specialization?.data || []),
    [specialization]
  );

  const disabled = JSON.stringify(data) === JSON.stringify(filter);

  useEffect(() => {
    dispatch(getAllSpainData());
  }, [dispatch]);

  const handleSubmit = () => {
    if (disabled) return;

    if (!isWorker) {
      !isAuth ? naFilters() : clientFilters();

      userFilters(
        transformFilters({
          data,
          forWhomOptions,
          specializationOptions,
        })
      );
    }

    dispatch(setFilters({ ...data }));
    storageInteraction(FILTERS_STORANGE_KEY, { ...data });
    onChange();
  };

  return (
    <div className={cn(className, css.filters)}>
      <div className={cn(css.filters__info, css.info)}>
        <Information className={css.info__icon} />
        <p className={css.info__text}>
          Rellena los campos para mejorar <br /> la calidad de tu selección
          <InformationArrow className={css.info__arrow} />
        </p>
      </div>

      <Formik
        innerRef={formikRef}
        initialValues={initData}
        onSubmit={handleSubmit}
      >
        <FormikForm key="filters">
          <FieldSelect
            className={css.filters__field}
            name="forWhomId"
            label="¿Para quién es el psicólogo?"
            options={forWhomOptions}
            isLoading={forWhom.isLoading}
          />

          <FieldSelect
            className={css.filters__field}
            name="specializationId"
            label="Motivo de la consulta"
            options={specializationOptions}
            isLoading={specialization.isLoading}
          />

          <FieldSelect
            className={css.filters__field}
            label="¿Prefieres un psicólogo o una psicóloga?"
            name="sex"
            defaultValue={formatSexWorker[0].value}
            options={[
              formatSexWorker[0],
              formatSexWorker[2],
              formatSexWorker[1],
            ]}
          />

          <Button
            className={css.filters__submit}
            type="submit"
            text="Aplicar filtros"
            disabled={disabled}
          />

          <FieldChange<IFilters> onChange={setData} />
        </FormikForm>
      </Formik>
    </div>
  );
};

export default Form;
