import { FC, useRef } from "react";
import css from "./Chat.module.scss";
import Preloader from "src/components/Preloader/Preloader";
import { IChat, IFakeChat } from "./ChatTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  chatSelector,
  handleReadMessage,
  handleSendMessage,
  nextPage,
  prevPage,
  setToNewMessage,
} from "src/store/chatSlice";
import { useChatHandlers } from "src/hooks/useChatHandlers";
import { useEventListener } from "src/hooks/useEventListener";
import Dialog from "./ui/Dialog/Dialog";

const Chat: FC<IChat> = ({
  channelUrl,
  disabled,
  className,
  disableAnimateFirstUserMessage = false,
}) => {
  const dispatch = useDispatch();
  const {
    connect: { currentUserId, isLoaded, error: connectError },
    channel: { isLoading: channelLoading, error: channelError },
    sendMessages: { isLoading: messageIsSent, data: pendingMessages },
    messages: {
      data: messages,
      isLoading: messagesLoading,
      nextMessageId,
      prevMessageId,
    },
    lastMessages: { isLoading: lastMessagesLoading },
    firstUnreadMessage: { data: firstUnreadMessage, searchСompleted },
    toNewMessage,
    typingUsers,
  } = useSelector(chatSelector);

  const scrollingElementRef = useRef<HTMLDivElement>();
  const enablePaginationRef = useRef<boolean>(false);

  // Подключение
  const { channel } = useChatHandlers(channelUrl, isLoaded && !connectError);

  // Пагинация
  useEventListener(scrollingElementRef, "scroll", () => {
    const { current: scrlEl } = scrollingElementRef;

    if (scrlEl && channel && enablePaginationRef.current && !toNewMessage) {
      const { offsetHeight, scrollHeight, scrollTop } = scrlEl;

      if (scrollTop <= 0) {
        if (prevMessageId) {
          dispatch(prevPage(channel));
          scrlEl.scrollBy(0, 2);
        }
      } else if (scrollHeight - offsetHeight - 5 <= scrollTop) {
        dispatch(nextPage(channel));
      }
    }
  });

  if (connectError || channelError) {
    return (
      <div style={{ color: "#f23a20", textAlign: "center", paddingTop: 20 }}>
        <p>
          Something went wrong, please refresh the page. <br /> Error
          description: {connectError || channelError}
        </p>
      </div>
    );
  }

  if (channelLoading || !currentUserId || !channel || !searchСompleted) {
    return (
      <div className={css.preloader}>
        <Preloader className={css.preloader__item} />
      </div>
    );
  }

  return (
    <Dialog
      className={className}
      messageIsSent={messageIsSent}
      messages={messages}
      pendingMessages={pendingMessages}
      typingUsers={typingUsers}
      disabled={disabled}
      toNewMessage={toNewMessage}
      nextMessageId={nextMessageId}
      lastMessagesLoading={lastMessagesLoading}
      messagesLoading={messagesLoading}
      firstUnreadMessage={firstUnreadMessage}
      disableAnimateFirstUserMessage={disableAnimateFirstUserMessage}
      onAfterFirstScroll={() => {
        dispatch(handleReadMessage(channel));
        enablePaginationRef.current = true;
      }}
      onAfterScrollToNewMessage={() => {
        dispatch(setToNewMessage(false));
        dispatch(handleReadMessage(channel));
      }}
      onSendMessage={(value) =>
        dispatch(handleSendMessage(channel, value.trimEnd()))
      }
      onEndTyping={() => channel.endTyping()}
      onStartTyping={() => channel.startTyping()}
      getScrollElemmentNode={(scrollElemment) => {
        scrollingElementRef.current = scrollElemment;
      }}
    />
  );
};

export default Chat;

export const FakeChat: FC<IFakeChat> = ({
  className,
  messages,
  pendingMessages,
  typingUsers,
  onSendMessage,
  messageIsSent,
  disabled,
  initialValue,
}) => {
  return (
    <Dialog
      className={className}
      messages={messages}
      pendingMessages={pendingMessages}
      nextMessageId={
        pendingMessages.length
          ? pendingMessages[pendingMessages.length - 1].messageId
          : undefined
      }
      disabled={disabled}
      disableAutoFocus={true}
      typingUsers={typingUsers}
      onSendMessage={onSendMessage}
      messageIsSent={messageIsSent}
      initialValueForForm={initialValue}
      disableAnimateFirstUserMessage={false}
    />
  );
};
