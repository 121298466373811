import cn from "classnames";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import NotAvailable from "src/components/NotAvailable/NotAvailable";
import Preloader from "src/components/Preloader/Preloader";
import { createTaskFiltersSelector } from "src/store/createNewTaskSlice";
import {
  getPsychologistUnauthorized,
  taskSelector,
  getPsychologistAuthorized,
  resetCreateChannel,
} from "src/store/taskSlice";

import css from "./WorkerList.module.scss";

import Filters from "./components/Filters/Filters";
import List from "./components/List/List";
import { getTitleData, referenceTitleSelector } from "src/store/referenceSlice";
import { logSelector } from "src/store/logSlice";
import { getTaskData } from "src/store/taskSlice";
import InterviewPopup from "./components/InterviewPopup/InterviewPopup";
import { storageInteraction } from "src/helpers/storageInteraction";
import { INTERVIEW_POPUP_SHOWN } from "src/constants/constants";
import { settingsSelector } from "src/store/settingsSlice";
import { workerSelector } from "src/store/workerSlice";
import {
  naPopupInterviewOpen,
  userDiapasonCount,
  userShowPageTask,
} from "src/helpers/metric";

const WorkerList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showPopupInterview, setShowPopupInterview] = useState<boolean>(false);

  const {
    workers: { data, isLoaded },
    task,
    isLoading: taskLoading,
    isLogged: taskLogged,
  } = useSelector(taskSelector);
  const { isLogged: workerLogged } = useSelector(workerSelector);
  const { eventError } = useSelector(logSelector);
  const { data: settingsData } = useSelector(settingsSelector);
  const filter = useSelector(createTaskFiltersSelector);
  const { data: dataTitle, isLoading: titleLoading } = useSelector(
    referenceTitleSelector
  );
  const filtersChangedRef = useRef<boolean>(false);
  const query = new URLSearchParams(
    decodeURIComponent(location.search.replace(/&amp;/g, "&"))
  );
  const codeId = query.get("code");
  const [isReadyAuth, setIsReadyAuth] = useState<boolean | null>(null);

  useEffect(() => {
    dispatch(resetCreateChannel());
  }, [dispatch]);

  useLayoutEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 1);
  }, []);

  // Метрика
  useEffect(() => {
    if (task?.id) {
      userShowPageTask(task.id);
    }
  }, [task]);

  useEffect(() => {
    if (taskLogged || workerLogged) return;

    const { current: filtersChanged } = filtersChangedRef;
    const lsFirstVisit = "NSP_FIRST_VISIT_WORKERS";

    if ((filtersChanged || !storageInteraction(lsFirstVisit)) && isLoaded) {
      storageInteraction(lsFirstVisit, true);

      const orderedData = [...data].sort((a, b) => a?.order - b?.order);

      const dataForMetric = orderedData.reduce<Record<string, number>>(
        (obj, diapason, index) => {
          return {
            ...obj,
            [`diapason_${index + 1}`]: diapason.tutors.length,
          };
        },
        {}
      );

      userDiapasonCount(dataForMetric);
    }
  }, [data, taskLogged, workerLogged, isLoaded]);

  useEffect(() => {
    const popupInterviewWasOpening = storageInteraction(INTERVIEW_POPUP_SHOWN);

    if (
      taskLogged ||
      workerLogged ||
      popupInterviewWasOpening ||
      !settingsData?.popUpInterview
    )
      return;

    let timer: ReturnType<typeof setTimeout> | null = null;

    if (filter.forWhomId || filter.specializationId || filter.sex !== "0") {
      timer = setTimeout(() => {
        storageInteraction(INTERVIEW_POPUP_SHOWN, true);
        naPopupInterviewOpen();
        setShowPopupInterview(true);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
    };
  }, [filter, settingsData, taskLogged, workerLogged]);

  useEffect(() => {
    dispatch(getTitleData(codeId ?? undefined));
  }, [codeId, dispatch]);

  useEffect(() => {
    if (taskLoading) return;

    setIsReadyAuth(taskLogged);

    if (!isReadyAuth) {
      dispatch(getPsychologistUnauthorized(filter));
    } else {
      dispatch(getPsychologistAuthorized(filter));
    }
  }, [dispatch, filter, taskLogged, taskLoading, isReadyAuth]); // eslint-disable-line

  useEffect(() => {
    if (eventError) {
      dispatch(getTaskData());
    }
  }, [dispatch, eventError]);

  return (
    <div className={css.workers}>
      <div className={cn(css.workers__header, css.header)}>
        <div className={cn("container", css.header__wrapper)}>
          <div className={css.header__subtitle}>
            {!titleLoading && dataTitle ? (
              dataTitle.title || "PSICÓLOGOS ONLINE en Barcelona"
            ) : (
              <span>&nbsp;</span>
            )}
          </div>

          <h1 className={css.header__title}>
            Te garantizamos el
            <br />
            mejor terapeuta online
          </h1>
        </div>
      </div>

      <div className={cn("container", css.workers__main)}>
        <Filters
          isAuth={!!taskLogged}
          isWorker={workerLogged}
          className={css.workers__filters}
          onChange={() => (filtersChangedRef.current = true)}
        />

        {!isLoaded ? (
          <Preloader className={css.workers__preloader} />
        ) : !data.filter((cat) => cat.tutors.length).length ? (
          <NotAvailable className={css.workers__notAvailable} />
        ) : (
          <List
            className={css.workers__list}
            data={data
              .filter((cat) => cat.tutors.length)
              .sort((a, b) => a.order - b.order)}
            taskLogged={Boolean(taskLogged)}
            workerLogged={Boolean(workerLogged)}
          />
        )}
      </div>

      {showPopupInterview && settingsData && (
        <InterviewPopup
          onClose={() => setShowPopupInterview(false)}
          timeInterview={settingsData.popUpInterviewTime}
          costInterview={settingsData.popUpInterviewCost}
        />
      )}
    </div>
  );
};

export default WorkerList;
