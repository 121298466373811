import cn from "classnames";
import css from "./LowText.module.scss";
import { FC, useEffect, useRef, useState } from "react";
import { LowTextProps } from "./LowTextTypes";

const LowText: FC<LowTextProps> = ({ text, opening = false, className }) => {
  const [isActive, setActive] = useState<boolean>(true);
  const [height, setHeight] = useState<number>(0);

  const fakeTextRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const textWrapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current: wrapText } = textWrapRef;
    const { current: itemText } = textRef;
    const { current: fakeText } = fakeTextRef;

    if (wrapText && itemText && fakeText) {
      const lineHeight = Number(
        getComputedStyle(fakeText).lineHeight.replace("px", "")
      );
      const maxHeight = lineHeight * 3;

      if (maxHeight < itemText.clientHeight) {
        setActive(false);
        setHeight(maxHeight - 1);
      }
    }
  }, [fakeTextRef, textWrapRef]);

  return (
    <div className={css.text}>
      <div
        className={cn(className, css.text__fake)}
        ref={fakeTextRef}
        dangerouslySetInnerHTML={{ __html: text }}
      />

      <div
        ref={textWrapRef}
        className={cn(className, css.text__wrapper, {
          [css.text__wrapper_active]: isActive,
        })}
        style={{
          maxHeight: !isActive ? height + "px" : "none",
        }}
      >
        <div ref={textRef} dangerouslySetInnerHTML={{ __html: text }} />
      </div>

      {opening && !isActive && (
        <button
          className={cn(css.text__button)}
          onClick={() => setActive(true)}
        >
          Mostrar más...
        </button>
      )}
    </div>
  );
};

export default LowText;
