import { FC } from "react";
import css from "./PrivacyPolicy.module.scss";
import cn from "classnames";
import Modal from "src/components/Modal/Modal";
import { PrivacyPolicyProps } from "./PrivacyPolicyTypes";

const PrivacyPolicy: FC<PrivacyPolicyProps> = ({ setActive }) => {
  return (
    <Modal
      active={true}
      setActive={setActive}
      modalConfig={{
        header: "Política de cancelación y reembolso",
        submitText: "Ok",
      }}
      type={"privacyPolicy"}
    >
      <div className={css.privacyPolicy}>
        <ol className={cn(css.privacyPolicy__list, css.listLvl1)}>
          <li className={css.listLvl1__item}>
            <h2 className={css.privacyPolicy__title}>
              El reembolso del pago de la 1a sesión.
            </h2>

            <ul className={cn(css.listLvl1__listLvl2, css.listLvl2)}>
              <li className={css.listLvl2__item}>
                <p className={css.privacyPolicy__text}>
                  Usted puede devolver el pago después de la 1a sesión si no le
                  gustó y no va a seguir teniendo más sesiones. El dinero será
                  devuelto solamente si fue pagado por la plataforma
                  Necesitaria.Es. No devolveremos pagos realizados directamente
                  con el psicólogo.
                </p>
              </li>

              <li className={css.listLvl2__item}>
                <p className={css.privacyPolicy__text}>
                  Para solicitar el reembolso contáctenos usando la siguiente
                  dirección de correo electrónico soporte@necesitaria.es en
                  plazo de 3 días después de la 1a sesión. En su email cuéntenos
                  qué aspectos particulares no le gustaron de la manera en que
                  el psicólogo dirigió la sesión. Esta información será añadida
                  al perfil del psicólogo sin apuntar su identidad y puede
                  conducir al bloqueo del perfil del psicólogo (dependiendo la
                  gravedad).
                </p>
              </li>

              <li className={css.listLvl2__item}>
                <p className={css.privacyPolicy__text}>
                  La oportunidad de reembolsar se puede utilizar máximo de 1 vez
                  pot 1 cliente.
                </p>
              </li>

              <li className={css.listLvl2__item}>
                <p className={css.privacyPolicy__text}>
                  La oportunidad de reembolsar el pago es un beneficio que{" "}
                  <a
                    href="https://necesitaria.es/"
                    target="_blank"
                    rel="noreferrer"
                    className={css.privacyPolicy__link}
                  >
                    Necesitaria.Es
                  </a>{" "}
                  ofrece a los clientes que están interesados en hacer la
                  terapia a largo plazo. Esta opción no puede ser aprovechada
                  con la intención original de obtener una sesión gratuita. En
                  caso de descubrir acciones maliciosas del cliente
                  Necesitaria.Es reserva el derecho de no devolver el pago de la
                  1a sesión al cliente.
                </p>
              </li>
            </ul>
          </li>

          <li className={css.listLvl1__item}>
            <h2 className={css.privacyPolicy__title}>
              Cancelaciones, ausencias o retraso por parte del paciente
              ("usted").
            </h2>

            <ul className={cn(css.listLvl1__listLvl2, css.listLvl2)}>
              <li className={css.listLvl2__item}>
                <p className={css.privacyPolicy__text}>
                  Usted puede cancelar la cita hasta veinticuatro (24) horas
                  antes del inicio de la cita acordada con el Psicólogo en el
                  chat en esta página. Para cancelar la cita, usted debe enviar
                  un mensaje de petición en el chat en esta página. Cualquier
                  tarifa ya pagada por la cita será reembolsada de inmediato (a
                  más tardar catorce (14) días desde la cancelación), y
                  utilizando el mismo método de pago que usted utilizó para
                  pagar las tarifas (a menos que se acuerde lo contrario).
                </p>
              </li>

              <li className={css.listLvl2__item}>
                <p className={css.privacyPolicy__text}>
                  Si usted (i) desea cancelar la cita menos de veinticuatro (24)
                  horas antes de la hora de inicio acordada, (ii) no se presenta
                  a la cita, o (iii) llega al menos diez (10) minutos tarde a la
                  cita, la consulta se cancelará, e, independientemente de si la
                  cita se realiza, se reprograma o se cancela.{" "}
                  <u>
                    <b>
                      Usted no podrá recibir ningún reembolso de Tarifas ya
                      pagadas.
                    </b>
                  </u>
                </p>
              </li>
            </ul>
          </li>

          <li className={css.listLvl1__item}>
            <h2 className={css.privacyPolicy__title}>
              Cancelaciones, ausencias o retraso por parte del Profesional.
            </h2>

            <p className={css.privacyPolicy__text}>
              En caso de que el Psicólogo cancele la cita, no se presente o
              llegue al menos con sesenta (60) minutos de retraso, la tarifa ya
              pagada por la cita, será reembolsada inmediato (en un plazo no
              mayor a catorce (14) días contados desde la fecha de cancelación),
              y al mismo método de pago que utilizó usted para pagar las tarifas
              (a menos que se acuerde lo contrario). El reembolso no será
              aplicable si el motivo de la cancelación, no presentación o
              retraso del Psicólogo es atribuible a usted; por ejemplo, que
              usted haya solicitado la cancelación.
            </p>
          </li>

          <li className={css.listLvl1__item}>
            <h2 className={css.privacyPolicy__title}>Otras disposiciones.</h2>

            <ul className={cn(css.listLvl1__listLvl2, css.listLvl2)}>
              <li className={css.listLvl2__item}>
                <p className={css.privacyPolicy__text}>
                  <u>
                    <b>
                      Salvo los casos descritos en el punto 1 y el punto 2,
                      usted no tendrá derecho a reembolso por las tarifas ya
                      pagadas por la cita.
                    </b>
                  </u>
                </p>
              </li>

              <li className={css.listLvl2__item}>
                <p className={css.privacyPolicy__text}>
                  Si a su consideración, usted tuviera el derecho a recibir un
                  reembolso, puede contactar el equipo de Necesitaria.Es usando
                  la siguiente dirección de correo
                  electrónico soporte@necesitaria.es. Necesitaria.Es le ayudará
                  con su petición para el reembolso o reclamación. No obstante
                  lo anterior, usted acepta y reconoce que Necesitaria.Es (a) no
                  es el proveedor de las citas ni de los los servicios de pago
                  para el cobro de las tarifas, y (b) por lo tanto,
                  Necesitaria.Es permanecerá exenta de cualquier reclamo,
                  demanda o responsabilidad en relación con el procesamiento de
                  reembolsos, por lo que el Psicólogo es y será el único
                  responsable.
                </p>
              </li>

              <li className={css.listLvl2__item}>
                <p className={css.privacyPolicy__text}>
                  En caso de abuso, o de uso fraudulento, ilícito o injusto de
                  los derechos que le otorga esta política (por ejemplo, para
                  obtener una ventaja injusta), no tendrá derecho a ningún
                  reembolso o compensación, y el Psicólogo se reserva todas las
                  acciones disponibles según la ley aplicable y/o bajo su
                  contrato con usted.
                </p>
              </li>

              <li className={css.listLvl2__item}>
                <p className={css.privacyPolicy__text}>
                  Usted reconoce y acepta que la cita es un servicio de
                  asistencia sanitaria, y por lo tanto, no se aplica el derecho
                  de desistimiento del contrato durante catorce (14) días, que
                  normalmente se aplicaría a los contratos celebrados a
                  distancia. Usted solamente tendrá el derecho a cancelar la
                  cita, y pedir el reembolso, en los casos específicos
                  establecidos en la política de cancelación y reembolso del
                  profesional.
                </p>
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </Modal>
  );
};

export default PrivacyPolicy;
