import React, { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as Message } from "src/assets/img/icons/Modal/message.svg";
import css from "./Waiting.module.scss";
import { Routes } from "src/constants/routes";
import cn from "classnames";
import { useDispatch } from "react-redux";
import {
  //stripeResponceFailure,
  stripeResponceSuccess,
} from "src/store/taskSlice";
import { stripeResponceStatuses } from "src/types/Task";
import { clienPayEnd } from "src/helpers/metric";

const Waiting: React.FC = () => {
  const history = useHistory();
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const location = useLocation();
  const dispatch = useDispatch();

  const query = new URLSearchParams(
    decodeURIComponent(location.search.replace(/&amp;/g, "&"))
  );
  const status = query.get("status");
  const workerId = query.get("workerId");

  useEffect(() => {
    if (status === stripeResponceStatuses.SUCCESS) {
      dispatch(stripeResponceSuccess());
      clienPayEnd();
    } else if (status === stripeResponceStatuses.FAILURE) {
      //dispatch(stripeResponceFailure());
    }

    timer.current = setTimeout(() => {
      history.push(`${Routes.client.chat}/${workerId}`);
    }, 3000);

    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, [history]); // eslint-disable-line

  return (
    <section className={cn(css.section, "fullpage", "container")}>
      <div className={css.section__info}>
        <Message className={css.section__icon} />

        <h2 className={cn(css.section__title, "h2")}>espera 3 segundos</h2>

        <div className={cn(css.section__desc, "b-content")}>
          <p>Te trasladamos a la plataforma</p>
        </div>
      </div>
    </section>
  );
};

export default Waiting;
