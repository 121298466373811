import React from "react";
import css from "./Banner.module.scss";
import { MainIcon } from "src/assets/icons";
import Button from "src/components/UI-kit/Button/Button";
import { Routes } from "src/constants/routes";

const Banner: React.FC = () => {
  return (
    <div className={css.banner}>
      <div className={css.banner__wrapper}>
        <MainIcon className={css.banner__icon} />

        <div className="container">
          <h1 className={css.banner__title}>
            Encuentra Al mejor PSICÓLOGO sin intermediarios
          </h1>

          <div className={css.banner__controls}>
            <Button
              asLink
              to={`${Routes.client.create.intro}`}
              text={"Necesito un psicólogo"}
              className={css.banner__button}
            />

            <Button
              asLink
              to={`${Routes.client.login}`}
              text="Abrir mi solicitud"
              transparent={true}
              className={css.banner__button}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
