import { FC, useEffect, useRef, useState } from "react";
import { FakeChat as Chat } from "src/components/Chat/Chat";
import { IMessage, IUser } from "src/types/Chat";

interface IFakeChat {
  nickname: string;
  message: string;
  sendMessage: IMessage | null;
  initialValue: string;
  onSendMessage: (value: string) => void;
  isAuth: boolean;
  disabled?: boolean;
}

const FakeChat: FC<IFakeChat> = ({
  nickname,
  message,
  sendMessage,
  onSendMessage,
  initialValue,
  disabled,
  isAuth,
}) => {
  const [fakeMessage, setFakeMessage] = useState<IMessage | null>(null);
  const [typingUser, setTypingUser] = useState<IUser | null>(null);
  const timerTypingRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    timerTypingRef.current = setTimeout(() => {
      setTypingUser({ userId: "1", nickname });
    }, 250);

    return () => {
      timerTypingRef.current && clearInterval(timerTypingRef.current);
    };
  }, [nickname]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (typingUser) {
      timer = setTimeout(() => {
        setTypingUser(null);
        timerTypingRef.current && clearInterval(timerTypingRef.current);

        setFakeMessage({
          createdAt: Date.now(),
          data: null,
          isCurrentUser: false,
          isReading: false,
          message,
          messageId: 1,
          sender: {
            userId: "1",
          },
          sendingStatus: "pending",
          type: "user",
        });
      }, 1250);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [message, typingUser]);

  return (
    <Chat
      messages={fakeMessage ? [fakeMessage] : []}
      onSendMessage={onSendMessage}
      pendingMessages={sendMessage ? [sendMessage] : []}
      typingUsers={typingUser ? [typingUser] : []}
      messageIsSent={isAuth && Boolean(sendMessage)}
      initialValue={initialValue}
      disabled={disabled}
      //messageIsSent={false}
    />
  );
};

export default FakeChat;
