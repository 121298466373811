import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState, AsyncRequest } from "./strore";
import { api } from "../constants/api";
import axios from "axios";
import { clearToken, setTokenTask } from "./authSlice";
import { addMessage } from "./logSlice";
import {
  allUsersHideRefundBanner,
  clientPhoneVerificationSuccess,
  userTaskAuthSuccess,
} from "src/helpers/metric";
import {
  FILTERS_STORANGE_KEY,
  formatSexWorker,
  REFOUND_BANNER_SHOWN,
  REFOUND_POPUP_SHOWN,
  UTM_TAGS,
} from "src/constants/constants";
import { IFilters } from "src/types/Task";
import { storageInteraction } from "src/helpers/storageInteraction";

export interface IRegister {
  phone: string;
  name: string;
}
export interface IForm extends AsyncRequest {
  register: IRegister;
  filters: IFilters;
  taskId: string | number | null;
  workerId: string | number | null;
}

export interface IRefundInfo {
  shownPopup: boolean;
  shownBanner: boolean;
}
export interface ICreateTaskState {
  form: IForm;
  confirm: AsyncRequest;
  confirmRequest: AsyncRequest;
  refundInfo: IRefundInfo;
}

const initialState: ICreateTaskState = {
  form: {
    register: {
      phone: "",
      name: "",
    },
    filters: storageInteraction(FILTERS_STORANGE_KEY) || {
      forWhomId: "",
      specializationId: "",
      sex: formatSexWorker[0].value,
    },
    taskId: null,
    workerId: null,
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  confirm: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  confirmRequest: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  refundInfo: {
    shownPopup: !!storageInteraction(REFOUND_POPUP_SHOWN),
    shownBanner: !!storageInteraction(REFOUND_BANNER_SHOWN),
  },
};

export const createTaskSlice = createSlice({
  name: "createTask",
  initialState,
  reducers: {
    setFilters: (
      state: ICreateTaskState,
      { payload }: PayloadAction<IFilters>
    ) => {
      state.form.filters = payload;
    },
    clearFilters: (state: ICreateTaskState) => {
      state.form.filters = {
        forWhomId: "",
        specializationId: "",
        sex: formatSexWorker[0].value,
      };
    },
    setRegister: (
      state: ICreateTaskState,
      { payload }: PayloadAction<IRegister>
    ) => {
      state.form.register = payload;
    },
    setIds: (
      state: ICreateTaskState,
      {
        payload,
      }: PayloadAction<{
        taskId: string | number;
        workerId: string | number;
      }>
    ) => {
      state.form.taskId = payload.taskId;
      state.form.workerId = payload.workerId;
    },

    sendForm: (state: ICreateTaskState) => {
      state.form.isLoading = true;
      state.form.isLoaded = false;
      state.form.error = null;
    },
    sendFormSuccess: (state: ICreateTaskState) => {
      state.form.isLoading = false;
      state.form.isLoaded = true;
      state.form.error = null;
    },
    sendFormFailure: (state: ICreateTaskState, { payload }) => {
      state.form.isLoading = false;
      state.form.isLoaded = true;
      state.form.error = payload;
    },
    sendFormInit: (state: ICreateTaskState) => {
      state.form.isLoading = false;
      state.form.isLoaded = false;
      state.form.error = null;
      state.form = {
        register: initialState.form.register,
        filters: storageInteraction(FILTERS_STORANGE_KEY) || {
          forWhomId: "",
          specializationId: "",
          sex: formatSexWorker[0].value,
        },
        taskId: initialState.form.taskId,
        workerId: null,
        isLoading: false,
        isLoaded: false,
        error: null,
      };
    },

    confirmCode: (state: ICreateTaskState) => {
      state.confirm.isLoading = true;
      state.confirm.isLoaded = false;
      state.confirm.error = null;
    },
    confirmCodeSuccess: (state: ICreateTaskState) => {
      state.confirm.isLoading = false;
      state.confirm.isLoaded = true;
      state.confirm.error = null;
    },
    confirmCodeFailure: (state: ICreateTaskState, { payload }) => {
      state.confirm.isLoading = false;
      state.confirm.isLoaded = true;
      state.confirm.error = payload;
    },
    confirmCodeInit: (state: ICreateTaskState) => {
      state.confirm.isLoading = false;
      state.confirm.isLoaded = false;
      state.confirm.error = null;
    },

    confirmRequestCode: (state: ICreateTaskState) => {
      state.confirmRequest.isLoading = true;
      state.confirmRequest.isLoaded = false;
      state.confirmRequest.error = null;
    },
    confirmRequestCodeSuccess: (state: ICreateTaskState) => {
      state.confirmRequest.isLoading = false;
      state.confirmRequest.isLoaded = true;
      state.confirmRequest.error = null;
    },
    confirmRequestCodeFailure: (state: ICreateTaskState, { payload }) => {
      state.confirmRequest.isLoading = false;
      state.confirmRequest.isLoaded = true;
      state.confirmRequest.error = payload;
    },

    sendCreateTask: (state: ICreateTaskState) => {
      state.form.isLoading = true;
      state.form.isLoaded = false;
      state.form.error = null;
    },
    sendCreateTaskSuccess: (state: ICreateTaskState, { payload }) => {
      state.form.isLoading = false;
      state.form.isLoaded = true;
      state.form.taskId = payload;
    },
    sendCreateTaskFailure: (state: ICreateTaskState, { payload }) => {
      state.form.isLoading = false;
      state.form.isLoaded = true;
      state.form.error = payload;
    },

    refundPopup: (
      state: ICreateTaskState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.refundInfo.shownPopup = payload;
    },
    refundBanner: (
      state: ICreateTaskState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.refundInfo.shownBanner = payload;
    },
  },
});

export const {
  setFilters,
  clearFilters,
  setRegister,
  setIds,

  sendForm,
  sendFormSuccess,
  sendFormFailure,
  sendFormInit,

  confirmCode,
  confirmCodeSuccess,
  confirmCodeFailure,
  confirmCodeInit,

  confirmRequestCode,
  confirmRequestCodeSuccess,
  confirmRequestCodeFailure,

  sendCreateTask,
  sendCreateTaskSuccess,
  sendCreateTaskFailure,

  refundPopup,
  refundBanner,
} = createTaskSlice.actions;

export const createTaskFormSelector = (state: RootState) =>
  state.createNewTask.form;
export const createTaskConfirmSelector = (state: RootState) =>
  state.createNewTask.confirm;
export const createTaskRegisterSelector = (state: RootState) =>
  state.createNewTask.form.register;
export const createTaskFiltersSelector = (state: RootState) =>
  state.createNewTask.form.filters;
export const refundInfoSelector = (state: RootState) =>
  state.createNewTask.refundInfo;

export default createTaskSlice.reducer;

export const sendCreateTaskData = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      createNewTask: { form },
    } = getState();

    const nn = (n: string) => (!!n ? +n : null);
    const utmTags = (storageInteraction(UTM_TAGS) || {}) as Record<
      string,
      string
    >;

    const sendData = {
      forWhomId: nn(form.filters.forWhomId),
      specializationId: nn(form.filters.specializationId),
      sex: nn(form.filters.sex),
      phone: form.register.phone,
      name: form.register.name.trim(),
      ...utmTags,
    };

    dispatch(sendCreateTask());

    try {
      const { data } = await axios.post(
        `${api}/task/create-task-psychologists/`,
        sendData
      );
      // @ts-ignore
      dispatch(clearToken());
      // @ts-ignore
      dispatch(sendRequestCodeData({ id: data.data.id }));
      dispatch(sendCreateTaskSuccess(data.data.id));
    } catch (error) {
      const { errors } = error.response.data;
      dispatch(sendCreateTaskFailure(errors));
    }
  };
};

export const sendRequestCodeData = ({ id }: { id: string }) => {
  return async (dispatch: AppDispatch) => {
    dispatch(confirmRequestCode());

    try {
      await axios.post(`${api}/task/send-confirmation-phone/`, { id: id });

      dispatch(confirmRequestCodeSuccess());
    } catch (error: any) {
      dispatch(confirmRequestCodeFailure("Произошла ошибка"));
    }
  };
};

export const sendCodeData = ({ code, id }: { code: string; id: string }) => {
  return async (dispatch: AppDispatch) => {
    dispatch(confirmCode());

    try {
      const { data } = await axios.post(`${api}/task/confirmation-phone/`, {
        id,
        code,
      });
      // @ts-ignore
      dispatch(setTokenTask(data.auth.token));
      dispatch(confirmCodeSuccess());
      dispatch(
        addMessage({
          type: "success",
          message: `Tu teléfono ha sido verificado.`,
        })
      );
      userTaskAuthSuccess(data.task); // Ya.Metrika
      clientPhoneVerificationSuccess();
    } catch (error: any) {
      const { errors } = error.response.data;
      dispatch(confirmCodeFailure("Error de confirmación telefónica."));

      if (errors.length) {
        errors.forEach((err: any) =>
          dispatch(addMessage({ type: "error", message: err }))
        );
      }
    }
  };
};

export const hideRefoundPopup = () => (dispatch: AppDispatch) => {
  dispatch(refundPopup(true));
  storageInteraction(REFOUND_POPUP_SHOWN, true);
};

export const hideRefoundBanner = () => (dispatch: AppDispatch) => {
  dispatch(refundBanner(true));
  storageInteraction(REFOUND_BANNER_SHOWN, true);
  allUsersHideRefundBanner();
};
