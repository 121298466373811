import { useEffect } from "react";

export const useScript = () => {
  useEffect(() => {
    scripts.forEach((script) => {
      const scriptElement = document.createElement("script");
      scriptElement.type = "text/javascript";
      scriptElement.src = script.src;
      scriptElement.id = script.id || "";

      scriptElement.dataset.dadsa = "dsadsa";

      if (script.datasets) {
        Object.entries(script.datasets).forEach(([key, value]) => {
          scriptElement.dataset[key] = value;
        });
      }

      if (
        (script.environment === "production" &&
          process.env.NODE_ENV === "production") ||
        (script.environment === "development" &&
          process.env.NODE_ENV === "development") ||
        script.environment === "everywhere"
      ) {
        document.body.appendChild(scriptElement);
      }
    });
  }, []);
};

const scripts: {
  environment: "production" | "development" | "everywhere";
  src: string;
  id?: string;
  datasets?: DOMStringMap;
}[] = [
  {
    environment: "production",
    id: "Cookiebot",
    src: "https://consent.cookiebot.com/uc.js",
    datasets: {
      cbid: String(process.env.REACT_APP_COOKIE_BOT),
      blockingmode: "auto",
    },
  },
];
