import React, { useEffect } from "react";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { CallbackTypesProps } from "./CallbackTypes";
import { WhatsApp2 } from "src/assets/icons";

import { workerSelector } from "src/store/workerSlice";
import { authSelector } from "src/store/authSlice";
import { Routes } from "src/constants/routes";

import css from "./Callback.module.scss";
import Preloader from "src/components/Preloader/Preloader";

const Callback: React.FC<CallbackTypesProps> = () => {
  const history = useHistory();
  const { worker } = useSelector(workerSelector);
  const { isAuthWorker } = useSelector(authSelector);

  useEffect(() => {
    if (!isAuthWorker) {
      history.push(Routes.home);
    }
    if (isAuthWorker && worker?.isStatus !== 4) {
      worker?.isStatus === 1 && history.push(Routes.worker.taskList);
      worker?.isStatus === 3 && history.push(Routes.worker.register.onboarding);
      worker?.isStatus === 5 && history.push(Routes.worker.register.final);
    }
  }, [worker, isAuthWorker, history]);

  if (worker?.isStatus !== 4) {
    return <Preloader />;
  }

  return (
    <div className={cn("fullpage", "container", css.callback)}>
      <div className={css.callback__wrapper}>
        <div className={css.callback__textWrap}>
          <h2 className={cn("h2", css.callback__title)}>
            ya tenemos tu perfil
          </h2>

          <div className={cn("b-content", css.callback__descr)}>
            <p>
              El paso siguiente es planificar una breve llamada y conocernos un
              poco. Nos pondremos en contacto contigo pronto.
            </p>
          </div>
        </div>

        <div className={cn(css.callback__socialBlock, css.socialBlock)}>
          <a
            target={"_blank"}
            href="https://wa.me/message/XM5DXNP4HUKFA1"
            className={css.socialBlock__icon}
            rel="noreferrer"
          >
            <WhatsApp2 />
          </a>

          <div className={cn("b-content", css.socialBlock__text)}>
            <p>Si no quieres esperar, contactanos via Whatsapp.</p>
          </div>

          <div className={css.socialBlock__btnWrap}>
            <a
              target={"_blank"}
              href="https://wa.me/message/XM5DXNP4HUKFA1"
              className={cn(css.socialBlock__btn, css.transparent)}
              rel="noreferrer"
            >
              Abrir Whatsapp
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Callback;
