import { FieldBirthdateOption } from "./FieldBirthdateType";

export const dayOptions: FieldBirthdateOption[] = [
  { label: "1", value: "01" },
  { label: "2", value: "02" },
  { label: "3", value: "03" },
  { label: "4", value: "04" },
  { label: "5", value: "05" },
  { label: "6", value: "06" },
  { label: "7", value: "07" },
  { label: "8", value: "08" },
  { label: "9", value: "09" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
  { label: "25", value: "25" },
  { label: "26", value: "26" },
  { label: "27", value: "27" },
  { label: "28", value: "28" },
  { label: "29", value: "29" },
  { label: "30", value: "30" },
  { label: "31", value: "31" },
];

export const monthOptions: FieldBirthdateOption[] = [
  { label: "Enero", value: "01" },
  { label: "Febrero", value: "02" },
  { label: "Marzo", value: "03" },
  { label: "Abril", value: "04" },
  { label: "Mayo", value: "05" },
  { label: "Junio", value: "06" },
  { label: "Julio", value: "07" },
  { label: "Agosto", value: "08" },
  { label: "Septiembre", value: "09" },
  { label: "Octubre", value: "10" },
  { label: "Noviembre", value: "11" },
  { label: "Diciembre", value: "12" },
];

export const yearOptions: FieldBirthdateOption[] = [
  { label: "2022", value: "2022" },
  { label: "2021", value: "2021" },
  { label: "2020", value: "2020" },
  { label: "2019", value: "2019" },
  { label: "2018", value: "2018" },
  { label: "2017", value: "2017" },
  { label: "2016", value: "2016" },
  { label: "2015", value: "2015" },
  { label: "2014", value: "2014" },
  { label: "2013", value: "2013" },
  { label: "2012", value: "2012" },
  { label: "2011", value: "2011" },
  { label: "2010", value: "2010" },
  { label: "2009", value: "2009" },
  { label: "2008", value: "2008" },
  { label: "2007", value: "2007" },
  { label: "2006", value: "2006" },
  { label: "2005", value: "2005" },
  { label: "2004", value: "2004" },
  { label: "2003", value: "2003" },
  { label: "2002", value: "2002" },
  { label: "2001", value: "2001" },
  { label: "2000", value: "2000" },
  { label: "1999", value: "1999" },
  { label: "1998", value: "1998" },
  { label: "1997", value: "1997" },
  { label: "1996", value: "1996" },
  { label: "1995", value: "1995" },
  { label: "1994", value: "1994" },
  { label: "1993", value: "1993" },
  { label: "1992", value: "1992" },
  { label: "1991", value: "1991" },
  { label: "1990", value: "1990" },
  { label: "1989", value: "1989" },
  { label: "1988", value: "1988" },
  { label: "1987", value: "1987" },
  { label: "1986", value: "1986" },
  { label: "1985", value: "1985" },
  { label: "1984", value: "1984" },
  { label: "1983", value: "1983" },
  { label: "1982", value: "1982" },
  { label: "1981", value: "1981" },
  { label: "1980", value: "1980" },
  { label: "1979", value: "1979" },
  { label: "1978", value: "1978" },
  { label: "1977", value: "1977" },
  { label: "1976", value: "1976" },
  { label: "1975", value: "1975" },
  { label: "1974", value: "1974" },
  { label: "1973", value: "1973" },
  { label: "1972", value: "1972" },
  { label: "1971", value: "1971" },
  { label: "1970", value: "1970" },
  { label: "1969", value: "1969" },
  { label: "1968", value: "1968" },
  { label: "1967", value: "1967" },
  { label: "1966", value: "1966" },
  { label: "1965", value: "1965" },
  { label: "1964", value: "1964" },
  { label: "1963", value: "1963" },
  { label: "1962", value: "1962" },
  { label: "1961", value: "1961" },
  { label: "1960", value: "1960" },
  { label: "1959", value: "1959" },
  { label: "1958", value: "1958" },
  { label: "1957", value: "1957" },
  { label: "1956", value: "1956" },
  { label: "1955", value: "1955" },
  { label: "1954", value: "1954" },
  { label: "1953", value: "1953" },
  { label: "1952", value: "1952" },
  { label: "1951", value: "1951" },
  { label: "1950", value: "1950" },
  { label: "1949", value: "1949" },
  { label: "1948", value: "1948" },
  { label: "1947", value: "1947" },
  { label: "1946", value: "1946" },
  { label: "1945", value: "1945" },
  { label: "1944", value: "1944" },
  { label: "1943", value: "1943" },
  { label: "1942", value: "1942" },
  { label: "1941", value: "1941" },
  { label: "1940", value: "1940" },
];

export const yearOptionsToUp: FieldBirthdateOption[] = [
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
  { label: "2026", value: "2026" },
  { label: "2027", value: "2027" },
  { label: "2028", value: "2028" },
  { label: "2029", value: "2029" },
  { label: "2030", value: "2030" },
  { label: "2031", value: "2031" },
  { label: "2032", value: "2032" },
  { label: "2033", value: "2033" },
  { label: "2034", value: "2034" },
  { label: "2035", value: "2035" },
  { label: "2036", value: "2036" },
  { label: "2037", value: "2037" },
  { label: "2038", value: "2038" },
  { label: "2039", value: "2039" },
  { label: "2040", value: "2040" },
  { label: "2041", value: "2041" },
  { label: "2042", value: "2042" },
  { label: "2043", value: "2043" },
  { label: "2044", value: "2044" },
  { label: "2045", value: "2045" },
  { label: "2046", value: "2046" },
  { label: "2047", value: "2047" },
  { label: "2048", value: "2048" },
  { label: "2049", value: "2049" },
  { label: "2050", value: "2050" },
  { label: "2051", value: "2051" },
  { label: "2052", value: "2052" },
  { label: "2053", value: "2053" },
  { label: "2054", value: "2054" },
  { label: "2055", value: "2055" },
  { label: "2056", value: "2056" },
  { label: "2057", value: "2057" },
  { label: "2058", value: "2058" },
  { label: "2059", value: "2059" },
  { label: "2060", value: "2060" },
  { label: "2061", value: "2061" },
  { label: "2062", value: "2062" },
  { label: "2063", value: "2063" },
  { label: "2064", value: "2064" },
  { label: "2065", value: "2065" },
  { label: "2066", value: "2066" },
  { label: "2067", value: "2067" },
  { label: "2068", value: "2068" },
  { label: "2069", value: "2069" },
  { label: "2070", value: "2070" },
  { label: "2071", value: "2071" },
  { label: "2072", value: "2072" },
  { label: "2073", value: "2073" },
  { label: "2074", value: "2074" },
  { label: "2075", value: "2075" },
  { label: "2076", value: "2076" },
  { label: "2077", value: "2077" },
  { label: "2078", value: "2078" },
  { label: "2079", value: "2079" },
  { label: "2080", value: "2080" },
  { label: "2081", value: "2081" },
  { label: "2082", value: "2082" },
  { label: "2083", value: "2083" },
  { label: "2084", value: "2084" },
  { label: "2085", value: "2085" },
  { label: "2086", value: "2086" },
  { label: "2087", value: "2087" },
  { label: "2088", value: "2088" },
  { label: "2089", value: "2089" },
  { label: "2090", value: "2090" },
  { label: "2091", value: "2091" },
  { label: "2092", value: "2092" },
  { label: "2093", value: "2093" },
  { label: "2094", value: "2094" },
  { label: "2095", value: "2095" },
  { label: "2096", value: "2096" },
  { label: "2097", value: "2097" },
  { label: "2098", value: "2098" },
  { label: "2099", value: "2099" },

];
