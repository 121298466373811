import { FC } from "react";
import { Worker } from "src/types/Worker";
import Button from "src/components/UI-kit/Button/Button";
import { Routes } from "src/constants/routes";
import { IStripeResponce, replenishmentStripe } from "src/store/taskSlice";
import { stripeResponceStatuses } from "src/types/Task";
import { useDispatch } from "react-redux";

interface IPay {
  className?: string;
  stripeResponce: IStripeResponce;
  worker: Worker;
  disabled: boolean;
}

const Pay: FC<IPay> = ({ stripeResponce, worker, disabled, className }) => {
  const dispatch = useDispatch();

  const handlePay = () => {
    const returnUrl = window.location.origin + Routes.client.waiting;
    const statusSuccess = `status=${stripeResponceStatuses.SUCCESS}`;
    const statusFailure = `status=${stripeResponceStatuses.FAILURE}`;
    const workerId = `workerId=${worker.id}`;

    const urlReturnSuccess = `${returnUrl}?${statusSuccess}&${workerId}`;
    const urlReturnFailure = `${returnUrl}?${statusFailure}&${workerId}`;

    dispatch(
      replenishmentStripe(urlReturnSuccess, urlReturnFailure, worker.id)
    );
  };

  return !worker.paid ? (
    <Button
      text={`Reservar la 1a sesión por ${worker.consultation.costConsultation} €`}
      className={className}
      onClick={handlePay}
      disabled={disabled || stripeResponce.isLoading}
    />
  ) : (
    <Button className={className} text="1a sesión esta pagada" disabled />
  );
};

export default Pay;
