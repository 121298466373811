import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { sendBird } from "src";
import {
  channelConnect,
  clearChat,
  initMessages,
  updateTypingUsers,
  handleAddMessage,
  readingChannel,
} from "src/store/chatSlice";

export const useChatHandlers = (
  channelUrl: string,
  allowedСonnection: boolean
) => {
  const dispatch = useDispatch();
  const [channel, setChannel] = useState<SendBird.GroupChannel>();

  useEffect(() => {
    if (allowedСonnection) {
      dispatch(channelConnect(channelUrl, setChannel));
    }

    return () => {
      dispatch(clearChat());
    };
  }, [channelUrl, allowedСonnection, dispatch]);

  useEffect(() => {
    if (channel) {
      dispatch(initMessages(channel));

      const channelHandler = new sendBird.ChannelHandler();

      channelHandler.onMessageReceived = (cnl, message) => {
        if (
          cnl.isGroupChannel() &&
          channelUrl === cnl.url &&
          (message.messageType === "user" || message.messageType === "admin")
        ) {
          dispatch(handleAddMessage(cnl, message));
        }
      };

      channelHandler.onReadReceiptUpdated = (cnl) => {
        if (cnl.isGroupChannel() && channelUrl === cnl.url) {
          dispatch(readingChannel());

          setChannel(cnl);
        }
      };

      channelHandler.onTypingStatusUpdated = (cnl) => {
        if (cnl.isGroupChannel() && channelUrl === cnl.url) {
          dispatch(updateTypingUsers(cnl));
          setChannel(cnl);
        }
      };

      sendBird.addChannelHandler("channelHandler", channelHandler);
    }

    return () => {
      sendBird.removeChannelHandler("channelHandler");
    };
  }, [channel, dispatch]); // eslint-disable-line

  return { channel };
};
