import React from "react";
import { IHeader } from "./HeaderTypes";
import css from "./Header.module.scss";
import cn from "classnames";
import { dateAdapter } from "src/helpers/dateAdapter";

const Header: React.FC<IHeader> = ({ className, task }) => {
  return (
    <div className={cn(className, css.header)}>
      <div className={css.header__date}>{dateAdapter(task.datetime)}</div>

      <div className={css.header__title}>
        <div className={css.header__number}>
          <h3 className="h3">{`SOLICITUD #${task.id}`}</h3>
        </div>
      </div>
    </div>
  );
};

export default Header;
