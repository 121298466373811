import React, { useEffect } from "react";
import css from "./Main.module.scss";
import Banner from "./components/Banner/Banner";
import cn from "classnames";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sendAutoLogin } from "src/store/logSlice";

const Main: React.FC = () => {
  const dispatch = useDispatch();

  const { autologin } = useParams<{ autologin: string }>();

  // Авторизация по короткой ссылке
  useEffect(() => {
    if (autologin && autologin !== "task" && autologin !== "worker") {
      if (autologin.length === 8) {
        dispatch(sendAutoLogin(autologin));
      }
    }
  }, [dispatch, autologin]);

  return (
    <div className={cn("fullpage", css.main)}>
      <Banner />
    </div>
  );
};

export default Main;
