import React, { useEffect, useState } from "react";
import css from "./Modal.module.scss";
import { ModalTypes } from "./ModalTypes";
import cn from "classnames";
import Button from "../UI-kit/Button/Button";
import { ReactComponent as Close } from "src/assets/img/icons/Systems/close-line.svg";
import Portal from "src/Layout/Portal/Portal";

const Modal: React.FC<ModalTypes> = ({
  className,
  children,
  type,
  active,
  setActive,
  hideClose = false,
  freezeClose = false,
  modalConfig,
  onCancel = () => {},
  onSubmit = () => {},
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const root = document.documentElement;
    const scrollWidth = window.innerWidth - root.clientWidth;
    const scrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;

    const timer = setTimeout(() => {
      setVisible(true);

      root.style.paddingRight = scrollWidth + "px";
      root.style.overflow = "hidden";
      root.style.position = "fixed";
      root.style.top = "-" + scrollPosition + "px";
      root.style.left = "0";
      root.style.width = "100%";
    }, 14);

    function handleKeyPress(event: any) {
      if (event.keyCode === 27) !freezeClose && handleClose();
    }

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      clearTimeout(timer);
      root.style.paddingRight = "0px";
      root.style.overflow = "";
      root.style.position = "";
      root.style.top = "";
      root.style.left = "";
      root.style.width = "";

      // Прокручиваем страницу на полученное из атрибута значение
      window.scroll(0, scrollPosition);

      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancel = () => {
    handleClose(onCancel);
  };

  const handleSubmit = () => {
    handleClose(onSubmit);
  };

  const handleClose = (callback: () => void = () => {}) => {
    setVisible(false);

    setTimeout(() => {
      setActive(false);
      callback();
    }, 400);
  };

  useEffect(() => {
    if (!active) {
      handleClose();
    }
  }, [active]); // eslint-disable-line

  return (
    <Portal>
      <div
        className={cn(className, css.modal, visible && css.modal_active)}
        ref={(modal) => {
          modal?.focus();
        }}
      >
        <div className={css.modal__overflow}>
          <div
            className={css.modal__bg}
            onClick={() => !freezeClose && handleClose()}
          />
          <div
            className={cn(css.modal__wrap, css.modalContainer)}
            onSubmit={(e) => e.preventDefault()}
          >
            <div className={css.modalContainer__head}>
              {modalConfig?.header && (
                <h3 className={cn(css.modalContainer__smallTitle, "h3")}>
                  {modalConfig.header}
                </h3>
              )}

              {!hideClose && (
                <button
                  type="button"
                  className={css.modalContainer__close}
                  onClick={() => !freezeClose && handleClose()}
                >
                  <Close />
                </button>
              )}
            </div>

            <div className={css.modalContainer__content}>
              {!!children && !modalConfig?.posChildren && (
                <div className={css.modalContainer__children}>{children}</div>
              )}

              {modalConfig?.icon && (
                <div className={css.modalContainer__icon}>
                  {modalConfig.icon}
                </div>
              )}

              {modalConfig?.posChildren === "afterIcon" && (
                <div className={css.children}>{children}</div>
              )}

              {!!modalConfig?.title && (
                <h2 className={cn(css.modalContainer__title, "h2")}>
                  {modalConfig.title}
                </h2>
              )}

              {modalConfig?.posChildren === "afterTitle" && (
                <div className={css.modalContainer__children}>{children}</div>
              )}

              {!!modalConfig?.desc && (
                <div
                  className={css.modalContainer__desc}
                  dangerouslySetInnerHTML={{ __html: modalConfig.desc }}
                />
              )}

              {modalConfig?.posChildren === "afterDesc" && (
                <div className={css.modalContainer__children}>{children}</div>
              )}
            </div>

            {type !== "form" && (
              <div
                className={cn(
                  css.modalContainer__actions,
                  type !== "accept" && css.single
                )}
              >
                {type === "accept" && (
                  <Button
                    className={css.btn}
                    text={modalConfig?.cancelText || "Cancelar"}
                    onClick={() => handleCancel()}
                    transparent
                  />
                )}

                <Button
                  className={css.btn}
                  text={modalConfig?.submitText || "Продолжить"}
                  onClick={() => handleSubmit()}
                  disabled={modalConfig?.submitDisabled}
                />
              </div>
            )}

            {(!!modalConfig?.footer || type === "rules") && (
              <div
                className={cn(
                  css[`modalContainer__footer`],
                  modalConfig?.footerXs && css[`modalContainer__footer_xs`]
                )}
              >
                {type === "rules" && (
                  <div className={css.desc}>
                    <button type="button" onClick={handleCancel}>
                      {modalConfig?.cancelText || "Cancelar"}
                    </button>
                  </div>
                )}
                {!!modalConfig?.footer && (
                  <div
                    className={css.desc}
                    dangerouslySetInnerHTML={{ __html: modalConfig.footer }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
