import React, { useEffect, useState } from "react";
import css from "./Rating.module.scss";
import { RatingTypes } from "./RatingTypes";
import cn from "classnames";
import { StarEmpty, StarFull, StarHalf } from "src/assets/icons";

const Rating: React.FC<RatingTypes> = ({ className, rating, size = "lg" }) => {
  const [rate, setRate] = useState(0);

  useEffect(() => {
    if (rating) {
      const stars = Math.floor(rating * 2) / 2;
      setRate(stars > 5 ? 5 : stars);
    }
  }, [rating]);

  return (
    <div
      className={cn(className, css.rating, {
        [css.rating_sm]: size === "sm",
      })}
    >
      {Array(Math.floor(rate))
        .fill("fullStar")
        .map((_, index) => (
          <StarFull className={css[`rating-full`]} key={index} />
        ))}
      {!Number.isInteger(rate) && <StarHalf className={css[`rating-half`]} />}
      {Array(5 - Math.ceil(rate))
        .fill("emptyStar")
        .map((_, index) => (
          <StarEmpty className={css[`rating-empty`]} key={index} />
        ))}
    </div>
  );
};

export default Rating;
