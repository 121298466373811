import { useCallback, useEffect, useRef } from "react";

export const useHashFragment = (offset = 0, delay = 100, trigger = true) => {
  const delayScrollTimer = useRef<NodeJS.Timeout | null>(null);

  const scrollToHashElement = useCallback(() => {
    const { hash } = window.location;
    const elementToScroll = document.getElementById(hash?.replace("#", ""));

    if (elementToScroll) {
      delayScrollTimer.current = setTimeout(() => {
        window.scrollTo({
          top: elementToScroll.offsetTop - offset,
          behavior: "smooth",
        });
      }, delay);
    }
  }, [offset, delay]);

  useEffect(() => {
    return () => {
      if (delayScrollTimer.current) {
        clearTimeout(delayScrollTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!trigger) return;
    scrollToHashElement();
    window.addEventListener("hashchange", scrollToHashElement);
    return () => {
      window.removeEventListener("hashchange", scrollToHashElement);
    };
  }, [scrollToHashElement, trigger]);

  return scrollToHashElement;
};
