import { useEffect, FC, useRef, useState, TransitionEvent } from "react";
import css from "./Menu.module.scss";
import { Close } from "src/assets/icons";
import cn from "classnames";
import Portal from "src/Layout/Portal/Portal";
import { Link } from "react-router-dom";

interface IControlButton {
  id: number;
  order: number;
  label: string;
  onClick: () => void;
}

interface IControlLink {
  id: number;
  order: number;
  label: string;
  link: string;
}

export type TControls = (IControlButton | IControlLink)[];

interface IMenu {
  onClose: () => void;
  controls: TControls;
}

const Menu: FC<IMenu> = ({ onClose, controls }) => {
  const [visible, setVisible] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const root = document.documentElement;
    const scrollWidth = window.innerWidth - root.clientWidth;

    const timer = setTimeout(() => {
      setVisible(true);
    }, 100);

    root.style.paddingRight = scrollWidth + "px";
    root.style.overflow = "hidden";

    return () => {
      root.style.paddingRight = "0px";
      root.style.overflow = "";
      clearTimeout(timer);
    };
  }, []);

  const onTransition = (event: TransitionEvent<HTMLDivElement>) => {
    if (!visible && event.propertyName === "opacity") {
      onClose();
    }
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <Portal>
      <div
        className={cn(css.menu, {
          [css.menu_visible]: visible,
        })}
        ref={menuRef}
        onTransitionEnd={onTransition}
      >
        <div className={css.menu__wrapper}>
          <div className={cn("ym-hide-content", css.menu__header, css.header)}>
            <div className={cn("container", css.header__wrapper)}>
              <button className={css.header__cross} onClick={handleClose}>
                <Close className={css.header__crossIcon} />
              </button>
            </div>
          </div>

          <nav className={cn("container", css.menu__nav)}>
            <ul className={css.menu__list}>
              {controls
                .sort((a, b) => a.order - b.order)
                .map(({ id, label, ...params }) => (
                  <li className={css.menu__item} key={id}>
                    {"onClick" in params && (
                      <button
                        className={css.menu__link}
                        onClick={() => {
                          params.onClick();
                          handleClose();
                        }}
                      >
                        {label}
                      </button>
                    )}

                    {"link" in params && (
                      <Link
                        className={css.menu__link}
                        to={params.link}
                        onClick={handleClose}
                      >
                        {label}
                      </Link>
                    )}
                  </li>
                ))}
            </ul>
          </nav>
        </div>
      </div>
    </Portal>
  );
};

export default Menu;
