import { FC } from "react";
import { ReviewsProps } from "./ReviewsTypes";
import cn from "classnames";
import css from "./Reviews.module.scss";
import Preloader from "src/components/Preloader/Preloader";
import Card from "./Card/Card";
import Button from "src/components/UI-kit/Button/Button";
import { Routes } from "src/constants/routes";

const Reviews: FC<ReviewsProps> = ({
  className,
  count = 5,
  reviews,
  onLink,
}) => {
  const { isLoading, reviews: data } = reviews;

  return (
    <div className={cn(className, css.reviews)}>
      <div className={css.reviews__title}>opiniones de psicólogos</div>
      <div className={css.reviews__subtitle}>
        Reseñas de los especialistas que ya estan colaborando con nuestra
        plataforma
      </div>

      {isLoading && (
        <div className={css.reviews_list}>
          <Preloader />
        </div>
      )}

      {!!data?.length && (
        <div className={css.reviews__list}>
          {data.slice(0, count).map((review) => (
            <Card
              data={review}
              key={review.title}
              className={css.reviews__item}
            />
          ))}
        </div>
      )}

      <Button
        text={"Registrarse"}
        className={css.reviews__button}
        onClick={onLink}
        asLink
        to={Routes.worker.register.form}
      />
    </div>
  );
};

export default Reviews;
