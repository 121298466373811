import { monthes } from "src/constants/constants";

export const dateAdapter = (
  date: string,
  inTime?: boolean,
  withoutTime?: boolean
) => {
  const [taskDate, time] = date?.split(" ");

  const taskDateParsed = new Date(taskDate?.split(".").reverse().join("-"));

  const getTime = () => {
    if (!withoutTime) {
      const h = Number(time.split(":")[0]);
      const m = time.split(":")[1];

      if (h > 12) {
        return `${h - 12}:${m} p. m.`;
      }

      return `${h}:${m} a. m.`;
    }

    return "";
  };

  return `${taskDateParsed.getDate()} de ${
    monthes[taskDateParsed.getMonth()]
  } de ${taskDateParsed.getFullYear()} ${
    withoutTime ? "" : inTime ? "a las" : "—"
  } ${getTime()}`;
};

export const dateAdapter2 = (
  string: string,
  separator?: "-" | ".",
  enterFormat?: "DMY" | "YMD"
): string => {
  const date = string.split(separator || "-");

  if (date.length !== 3) {
    return "";
  }

  if (enterFormat === "YMD") {
    return `${date[0]} de ${monthes[Number(date[1]) - 1]} de ${date[2]}`;
  }

  return `${date[2]} de ${monthes[Number(date[1]) - 1]} de ${date[0]}`;
};

export const dateAdapter3 = (string: string) => {
  const date = new Date(string);
  if (!date) return;

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear());

  return `${day}.${month}.${year}`;
};
