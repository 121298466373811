import { FC } from "react";
import { UserProps } from "./MessageTypes";
import css from "./Message.module.scss";
import cn from "classnames";
import { ChatBot, ChatRead, ChatUnRead, MessageTail } from "src/assets/icons";
import { useMountVisible } from "src/hooks/useMountVisible";

/*const renderFirstSpaces = (string: string): string => {
  const spacesCount = string.search(/\S|$/);

  return "\u00a0".repeat(spacesCount);
};*/

const updateStr = (s: string) => {
  var urlMatch = s.match(/https?:\/\/[^\s]+/gi);
  var url = urlMatch ? urlMatch[0] : "";

  return s.replace(
    /https?:\/\/[^\s]+/gi,
    `<a href="${url}" target="_blank">${url}</a>`
  );
};

const Message: FC<UserProps> = ({ message, animate }): JSX.Element => {
  const dateMessage = new Date(message.createdAt);
  const visible = useMountVisible();

  return (
    <article
      className={cn(css.message, {
        [css.message_current]: message.isCurrentUser,
        [css.message_admin]: message.sender === "admin",
        [css.message_duration]: animate,
        [css.message_visible]: visible,
      })}
    >
      <div className={css.message__wrapper}>
        {message.sender === "admin" && (
          <h5 className={css.message__title}>
            <ChatBot /> Bot Necesitaria.Es
          </h5>
        )}

        <p
          className={css.message__message}
          dangerouslySetInnerHTML={{
            __html: !!message.message
              ? updateStr(message.message.replace(/\n/gi, "<br/>"))
              : "",
          }}
        />

        <div className={css.message__footer}>
          <div className={css.message__date}>
            {dateMessage.getHours()}:
            {String(dateMessage.getMinutes()).padStart(2, "0")}
          </div>

          {message.isCurrentUser && (
            <div className={css.message__status}>
              {message.sendingStatus === "pending" ? (
                <div className={css.pending} />
              ) : message.isReading ? (
                <ChatRead />
              ) : (
                <ChatUnRead />
              )}
            </div>
          )}
        </div>

        <MessageTail
          className={cn(css.message__tail, {
            [css.message__tail_admin]: message.sender === "admin",
            [css.message__tail_current]: message.isCurrentUser,
          })}
        />
      </div>
    </article>
  );
};

export default Message;
