import React from "react";
import { eventErrorClose } from "src/store/logSlice";
import { useDispatch } from "react-redux";
import { ReactComponent as SadFace } from "src/assets/img/icons/Modal/sad-face.svg";
import Modal from "src/components/Modal/Modal";

const ActionFailed: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <Modal
      active={true}
      setActive={() => {
        dispatch(eventErrorClose());
      }}
      onSubmit={() => {
        dispatch(eventErrorClose());
      }}
      modalConfig={{
        icon: <SadFace />,
        title: "error",
        desc: "Algunos parámetros importantes han cambiado desde que se cargó la página. Actualiza la página y vuelve a intentarlo.",
        submitText: "Ok",
      }}
      type={"info"}
    />
  );
};

export default ActionFailed;
