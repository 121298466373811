import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "./strore";
import { api } from "../constants/api";
import axios from "axios";

interface ISettings {
  data: {
    columnInterested: boolean;
    newLandingPage: boolean;
    participationInInterview: boolean;
    participationInInterviewCost: number;
    participationInInterviewTime: number;
    popUpInterview: boolean;
    popUpInterviewCost: number;
    popUpInterviewTime: number;
  } | null;
}

const initialState: ISettings = {
  data: null,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    settings: (state: ISettings) => {
      state.data = null;
    },
    settingsSuccess: (state: ISettings, { payload }) => {
      state.data = payload;
    },
    settingsFailure: (state: ISettings) => {
      state.data = null;
    },
  },
});

export const { settings, settingsSuccess, settingsFailure } =
  settingsSlice.actions;

export const settingsSelector = (state: RootState) => state.settings;
export default settingsSlice.reducer;

export const getSettings = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(settings());

    try {
      const { data } = await axios(`${api}/settings/`);

      dispatch(settingsSuccess(data.settings));
    } catch (error: any) {
      dispatch(settingsFailure());
    }
  };
};
