import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "./strore";
import { api } from "../constants/api";
import axios from "axios";
import { addMessage } from "./logSlice";
import { formStep1, formStep2, taskIdLogged } from "src/constants/constants";
import { userTaskAuthSuccess } from "src/helpers/metric";
import { setTokenTask } from "./authSlice";
import httpErrorHandler from "./httpErrorHandler";

export interface ReviewData {
  image: string;
  title: string;
  subtitle: string;
  score: number | null;
  text: string;
}

export interface CreateTaskState {
  isLoading: boolean;
  initFields: any;
  additionalFields: {
    data: any;
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
  };
  codeSent: null | {
    wasSent: boolean;
    taskId: string | number | null;
    taskPhone: string | number | null;
  };
  codeConfirmed: {
    confirmed: boolean;
    isEmail: boolean;
  };
  reviews: {
    isLoading: boolean;
    reviews: null | ReviewData[];
  };
  registerDesc: string;
  workersCount: {
    data: number | null;
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
  };
}

const initialState: CreateTaskState = {
  isLoading: false,
  initFields: null,
  additionalFields: {
    data: null,
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  codeSent: null,
  codeConfirmed: {
    confirmed: false,
    isEmail: false,
  },
  reviews: {
    isLoading: true,
    reviews: null,
  },
  registerDesc: "",
  workersCount: {
    data: null,
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

export const createTaskSlice = createSlice({
  name: "create-task",
  initialState,
  reducers: {
    getForm: (state: CreateTaskState) => {
      state.isLoading = true;
      state.codeConfirmed = initialState.codeConfirmed;
      state.codeSent = null;
    },
    getFormSuccess: (state: CreateTaskState, { payload }) => {
      state.isLoading = false;
      state.initFields = payload;
    },
    getFormFailure: (state: CreateTaskState) => {
      state.isLoading = false;
      /*state.formConfig = payload*/
    },

    getAdditionalFields: (state: CreateTaskState) => {
      state.additionalFields.data = null;
      state.additionalFields.isLoading = true;
      state.additionalFields.isLoaded = false;
      state.additionalFields.error = null;
    },
    getAdditionalFieldsSuccess: (state: CreateTaskState, { payload }) => {
      state.additionalFields.data = payload;
      state.additionalFields.isLoading = false;
      state.additionalFields.isLoaded = true;
    },
    getAdditionalFieldsFailure: (state: CreateTaskState, { payload }) => {
      state.additionalFields.data = null;
      state.additionalFields.isLoading = false;
      state.additionalFields.isLoaded = true;
      state.additionalFields.error = payload;
    },

    setWorkersCount: (state: CreateTaskState) => {
      state.workersCount.isLoading = true;
      state.workersCount.isLoaded = false;
      state.workersCount.error = null;
    },
    setWorkersCountSuccess: (state: CreateTaskState, { payload }) => {
      state.workersCount.data = payload;
      state.workersCount.isLoading = false;
      state.workersCount.isLoaded = true;
    },
    setWorkersCountFailure: (state: CreateTaskState, { payload }) => {
      state.workersCount.isLoading = false;
      state.workersCount.isLoaded = true;
      state.workersCount.error = payload;
    },

    sendForm: (state: CreateTaskState) => {
      state.isLoading = true;
    },
    sendFormSuccess: (state: CreateTaskState) => {
      state.isLoading = false;
    },
    sendFormFailure: (state: CreateTaskState) => {
      state.isLoading = false;
    },

    requestCode: (state: CreateTaskState) => {
      state.isLoading = true;
    },
    requestCodeSuccess: (state: CreateTaskState, { payload }) => {
      state.isLoading = false;
      state.codeSent = {
        wasSent: true,
        taskId: payload.id,
        taskPhone: payload.phone,
      };
    },
    requestCodeFailure: (state: CreateTaskState) => {
      state.isLoading = false;
      state.codeSent = {
        wasSent: false,
        taskId: null,
        taskPhone: null,
      };
    },

    confirmCode: (state: CreateTaskState) => {
      state.isLoading = true;
    },
    confirmCodeSuccess: (state: CreateTaskState, { payload }) => {
      state.isLoading = false;
      state.codeConfirmed = {
        confirmed: true,
        isEmail: payload.isEmail,
      };
    },
    confirmCodeFailure: (state: CreateTaskState) => {
      state.isLoading = false;
      state.codeConfirmed = {
        confirmed: false,
        isEmail: false,
      };
    },

    getRegisterReviews: (state: CreateTaskState) => {
      state.reviews.isLoading = true;
    },
    getRegisterReviewsSuccess: (state: CreateTaskState, { payload }) => {
      state.reviews.isLoading = false;
      state.reviews.reviews = payload;
    },
    getRegisterReviewsFailure: (state: CreateTaskState) => {
      state.reviews.isLoading = false;
      state.reviews.reviews = null;
    },

    setRegisterDesc: (state: CreateTaskState, { payload }) => {
      state.registerDesc = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getForm,
  getFormFailure,
  getFormSuccess,
  getAdditionalFields,
  getAdditionalFieldsFailure,
  getAdditionalFieldsSuccess,
  setWorkersCount,
  setWorkersCountSuccess,
  setWorkersCountFailure,
  sendForm,
  sendFormFailure,
  sendFormSuccess,
  requestCode,
  requestCodeSuccess,
  requestCodeFailure,
  confirmCode,
  confirmCodeSuccess,
  confirmCodeFailure,
  getRegisterReviews,
  getRegisterReviewsSuccess,
  getRegisterReviewsFailure,
  setRegisterDesc,
} = createTaskSlice.actions;
export const createTaskSelector = (state: RootState) => state.createTask;

export default createTaskSlice.reducer;

export const getDefaultList = (subjectId?: string | null, areaId?: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getForm());

    try {
      const { data } = await axios.get(
        `${api}/task/form/?subject_id=${subjectId}&area_id=${areaId}`
      );
      dispatch(getFormSuccess(data.fields));
    } catch (error: any) {
      dispatch(getFormFailure());
    }
  };
};

export const getRelatedList = (subjectId?: string, areaId?: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getAdditionalFields());

    try {
      const { data } = await axios.get(
        `${api}/task/form-relation/?subject_id=${subjectId}&area_id=${areaId}`
      );
      dispatch(getAdditionalFieldsSuccess(data.fields));
    } catch (error: any) {
      dispatch(getAdditionalFieldsFailure(error));
    }
  };
};

export const getWorkersCount = (params: any) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setWorkersCount());

    let urlParams: string[] = [];
    Object.keys(params)
      .filter((key) => !!params[key])
      .forEach((key) => {
        urlParams.push(key + "=" + params[key]);
      });

    try {
      const {
        data: { workersCount },
      } = await axios.get(`${api}/task/tutors-count/?${urlParams.join("&")}`);
      dispatch(setWorkersCountSuccess(workersCount));
    } catch (error) {
      httpErrorHandler(error, dispatch);
      dispatch(setWorkersCountFailure({ error }));
    }
  };
};

export const saveForm = (sendData: { phone: string }) => {
  return async (dispatch: AppDispatch) => {
    dispatch(sendForm());

    try {
      const { data } = await axios.post(`${api}/task/save/`, sendData);
      localStorage.removeItem(formStep1);
      localStorage.removeItem(formStep2);
      dispatch(sendFormSuccess());
      dispatch(sendRequestCode(data.data.id, sendData.phone));
    } catch (error: any) {
      const { errors } = error.response.data;

      dispatch(sendFormFailure());
      if (errors.length) {
        errors.forEach((err: any) =>
          dispatch(addMessage({ type: "error", message: err.message }))
        );
      } else {
        for (let err in errors) {
          dispatch(addMessage({ type: "error", message: errors[err] }));
        }
      }
    }
  };
};

export const sendRequestCode = (
  id: string | number,
  phone: string | number
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(requestCode());

    try {
      await axios.post(`${api}/task/send-confirmation-phone/`, { id: id });

      dispatch(requestCodeSuccess({ id, phone }));
    } catch (error: any) {
      dispatch(requestCodeFailure());
    }
  };
};

export const sendCode = (code: string, id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(confirmCode());

    try {
      const { data } = await axios.post(`${api}/task/confirmation-phone/`, {
        id,
        code,
      });

      dispatch(confirmCodeSuccess(data));
      dispatch(setTokenTask(data.auth.token));
      localStorage.setItem(taskIdLogged, data.task);
      dispatch(
        addMessage({
          type: "success",
          message: `Tu teléfono ha sido verificado.`,
        })
      );
      userTaskAuthSuccess(data.task); // Ya.Metrika
    } catch (error: any) {
      const { errors } = error.response.data;
      dispatch(confirmCodeFailure());

      if (errors.length) {
        errors.forEach((err: any) =>
          dispatch(addMessage({ type: "error", message: err }))
        );
      }
    }
  };
};

export const getIntroReviews = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(getRegisterReviews());

    try {
      const { data } = await axios.get(`${api}/reviews-main/`);

      dispatch(getRegisterReviewsSuccess(data.list));
    } catch (error: any) {
      dispatch(getRegisterReviewsFailure());
    }
  };
};

export const setRegisterDescription = (description: string) => {
  return (dispatch: AppDispatch) => {
    dispatch(setRegisterDesc(description));
  };
};
