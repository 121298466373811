import css from "./Blocks.module.scss";
import { FC } from "react";
import { aboutWorkerPickType } from "../types";
import ReactPlayer from "react-player";
import { clientPlayPerfilVideo, naPlayPerfilVideo } from "src/helpers/metric";
import LowText from "src/components/LowText/LowText";

export const About: FC<aboutWorkerPickType> = ({
  about,
  isLogged,
  videoYoutubeId,
}) => {
  return (
    <div className={css.block}>
      {videoYoutubeId && (
        <div className={css.block__videoWrapper}>
          <ReactPlayer
            url={`https://www.youtube.com/embed/${videoYoutubeId}`}
            className={css.block__video}
            width="100%"
            height="100%"
            onPlay={() => {
              if (isLogged) {
                clientPlayPerfilVideo();
              } else {
                naPlayPerfilVideo();
              }
            }}
          />
        </div>
      )}

      {about && (
        <LowText text={about} opening={true} className={css.block__desc} />
      )}
    </div>
  );
};
