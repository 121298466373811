import { FC, useEffect, useRef, useState } from "react";
import css from "./Dialog.module.scss";
import cn from "classnames";
import Preloader from "src/components/Preloader/Preloader";

import { scrollTo } from "src/helpers/chat";
import { IDialogProps } from "./DialogTypes";

import Form from "../Form/Form";
import Messages from "../Messages/Messages";
import Typing from "../Typing/Typing";
import { IMessage } from "src/types/Chat";

const Dialog: FC<IDialogProps> = ({
  className,
  pendingMessages,
  messages,
  disabled,
  toNewMessage,
  nextMessageId,
  lastMessagesLoading,
  messagesLoading,
  firstUnreadMessage,
  typingUsers,
  messageIsSent,
  disableAnimateFirstUserMessage,
  initialValueForForm,
  disableAutoFocus = false,
  getScrollElemmentNode,
  onAfterFirstScroll,
  onAfterScrollToNewMessage,
  onSendMessage,
  onEndTyping = () => "",
  onStartTyping = () => "",
}) => {
  const [lastMessageNode, setLastMessageNode] = useState<HTMLDivElement>();
  const [lastPandingMessageNode, setLastPandingMessageNode] =
    useState<HTMLDivElement>();
  const scrollingElementRef = useRef<HTMLDivElement>(null);
  const firstScrollStartRef = useRef<boolean>(true);
  const [noAnimateMessagesIds, setNoAnimateMessagesIds] = useState<
    IMessage["messageId"][]
  >([]);

  useEffect(() => {
    if (getScrollElemmentNode && scrollingElementRef.current) {
      getScrollElemmentNode(scrollingElementRef.current);
    }
  }, [scrollingElementRef.current]); // eslint-disable-line

  // Первый скролл
  useEffect(() => {
    const { current: scrollingElement } = scrollingElementRef;
    let timer: NodeJS.Timeout;

    if (scrollingElement && firstScrollStartRef.current && lastMessageNode) {
      firstScrollStartRef.current = false;

      if (lastMessageNode) {
        scrollTo(scrollingElement, lastMessageNode);
        onAfterFirstScroll && onAfterFirstScroll();
      }
    }

    return () => {
      timer && clearTimeout(timer);
    };
  }, [lastMessageNode]); // eslint-disable-line

  // Временые сообщения
  useEffect(() => {
    const { current: scrollingElement } = scrollingElementRef;

    if (!scrollingElement || !lastPandingMessageNode || !pendingMessages.length)
      return;

    setNoAnimateMessagesIds((state) => [
      ...state,
      pendingMessages[pendingMessages.length - 1].messageId,
    ]);

    scrollTo(scrollingElement, lastPandingMessageNode);
  }, [pendingMessages, lastPandingMessageNode]);

  // Новое сообщение
  useEffect(() => {
    const { current: scrollingElement } = scrollingElementRef;
    if (
      !scrollingElement ||
      !lastMessageNode ||
      !nextMessageId ||
      lastMessagesLoading
    )
      return;

    if (
      toNewMessage &&
      messages.map((m) => m.messageId).includes(nextMessageId)
    ) {
      scrollTo(scrollingElement, lastMessageNode);

      setTimeout(() => {
        onAfterScrollToNewMessage && onAfterScrollToNewMessage();
      }, 200);
    }

    /* eslint-disable */
  }, [
    toNewMessage,
    nextMessageId,
    lastMessageNode,
    lastMessagesLoading,
    messages,
  ]);
  /* eslint-enable */

  const handleWrapInputMessage = () => {
    const { current: scrollingElement } = scrollingElementRef;
    if (!scrollingElement || !lastMessageNode) return;
    const { scrollTop, scrollHeight, offsetHeight } = scrollingElement;

    const isBottom =
      scrollTop + offsetHeight + lastMessageNode.offsetHeight >= scrollHeight;

    isBottom && scrollingElement.scrollTo(0, scrollHeight);
  };

  const handleScrollOnFocus = () => {
    const { current: scrollingElement } = scrollingElementRef;
    if (!scrollingElement || !lastMessageNode) return;
    scrollingElement.scrollTo({
      top: scrollingElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className={cn(className, css.chat)}>
      <div className={css.chat__messages} ref={scrollingElementRef}>
        {messagesLoading ? (
          <Preloader className={css.preloader} />
        ) : (
          <Messages
            list={messages}
            pendingMessages={pendingMessages}
            firstUnreadMessage={firstUnreadMessage}
            lastMessageRef={setLastMessageNode}
            disableAnimateFirstUserMessage={disableAnimateFirstUserMessage}
            noAnimateMessagesIds={noAnimateMessagesIds}
            lastPandingMessageRef={setLastPandingMessageNode}
            showPandingMessage={
              !!nextMessageId &&
              messages.map((m) => m.messageId).includes(nextMessageId)
            }
          />
        )}
      </div>

      {!disabled && (
        <>
          <Form
            disabledSend={messageIsSent}
            className={css.chat__form}
            handleWrapInputMessage={handleWrapInputMessage}
            handleScrollOnFocus={handleScrollOnFocus}
            onSendMessage={onSendMessage}
            onEndTyping={onEndTyping}
            disableAutoFocus={disableAutoFocus}
            onStartTyping={onStartTyping}
            initialValue={initialValueForForm}
          />

          <div className={css.chat__typingUsersWrapper}>
            {!!typingUsers.length && (
              <Typing
                className={css.chat__typingUsers}
                typingUsers={typingUsers}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Dialog;
