const envRoute = `${process.env.PUBLIC_URL}`;

const checkSlash = (url: any) => {
  if (!!url && typeof url === "string") {
    return url.substr(-1) !== "/" ? url + "/" : url;
  }
  return undefined;
};

export const Routes = {
  home: `${checkSlash(envRoute) || "/"}`,
  interview: `${checkSlash(envRoute) || "/"}interview`,

  client: {
    create: {
      intro: `${checkSlash(envRoute) || "/"}client/create/intro`,
      verification: `${checkSlash(envRoute) || "/"}client/create/verification`,
      filters: `${checkSlash(envRoute) || "/"}client/create/filters`,
      // Old
      main: `${checkSlash(envRoute) || "/"}client/create`,
      step: `${checkSlash(envRoute) || "/"}client/create/step`,
      step1: `${checkSlash(envRoute) || "/"}client/create/step/1`,
    },
    login: `${checkSlash(envRoute) || "/"}client/auth`,
    show: `${checkSlash(envRoute) || "/"}client/show`,
    waiting: `${checkSlash(envRoute) || "/"}client/waiting`,
    workers: `${checkSlash(envRoute) || "/"}client/workers`,
    chat: `${checkSlash(envRoute) || "/"}client/chat`,
    // Old
    createStep1: `${checkSlash(envRoute) || "/"}client/create/step-1`,
    createStep2: `${checkSlash(envRoute) || "/"}client/create/step-2`,
    verification: `${checkSlash(envRoute) || "/"}client/verification`,
  },
  worker: {
    login: `${checkSlash(envRoute) || "/"}worker/auth`,
    create: `${checkSlash(envRoute) || "/"}worker/create`,
    register: {
      main: `${checkSlash(envRoute) || "/"}worker/register`,
      form: `${checkSlash(envRoute) || "/"}worker/register/form`,
      verification: `${
        checkSlash(envRoute) || "/"
      }worker/register/verification`,
      onboarding: `${checkSlash(envRoute) || "/"}worker/register/onboarding`,
      questionnaire: `${
        checkSlash(envRoute) || "/"
      }worker/register/questionnaire`,
      callback: `${checkSlash(envRoute) || "/"}worker/register/callback`,
      final: `${checkSlash(envRoute) || "/"}worker/register/final`,
      // Old
      step1: `${checkSlash(envRoute) || "/"}worker/register/step/1`,
      step2: `${checkSlash(envRoute) || "/"}worker/register/step/2`,
    },
    taskList: `${checkSlash(envRoute) || "/"}worker/tasks`,
    task: `${checkSlash(envRoute) || "/"}worker/task`,
    profile: `${checkSlash(envRoute) || "/"}worker/profile`,
    // Old
    balance: {
      home: `${checkSlash(envRoute) || "/"}worker/balance`,
      update: `${checkSlash(envRoute) || "/"}worker/balance/update`,
      details: `${checkSlash(envRoute) || "/"}worker/balance/details`,
    },
    newRules: `${checkSlash(envRoute) || "/"}worker/new-rules`,
  },

  parents: ["task", "worker"],
};
