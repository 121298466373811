import { useEffect, useRef } from "react";
import css from "./Chat.module.scss";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  disableSmsAlert,
  enableSmsAlert,
  getTaskData,
  resetTask,
  smsAlertSelector,
  workerSelector,
} from "src/store/workerSlice";
import { MessageMoney, MessageWork } from "src/assets/graphIcons";
import Preloader from "src/components/Preloader/Preloader";
import { userShowPageTask } from "src/helpers/metric";
import { Routes } from "src/constants/routes";
import Chat from "src/components/Chat/Chat";
import { eventErrorOpen } from "src/store/logSlice";
import { chatSelector } from "src/store/chatSlice";
import Button from "src/components/UI-kit/Button/Button";
import {
  setVisibleFooter,
  setVisibleHeaderToCatalog,
} from "src/store/layoutSlice";

const WorkerChat = () => {
  const dispatch = useDispatch();
  const { taskDetails, taskDetailsErrorStatus, worker, isLoading } =
    useSelector(workerSelector);
  const smsAlert = useSelector(smsAlertSelector);
  const chatRef = useRef<HTMLDivElement>(null);

  const {
    connect: { isLoading: chatLoading },
  } = useSelector(chatSelector);

  const history = useHistory();
  const { taskId } = useParams<{ taskId: string }>();

  useEffect(() => {
    dispatch(setVisibleHeaderToCatalog(true));
    dispatch(setVisibleFooter(false));

    return () => {
      dispatch(setVisibleFooter(true));
      dispatch(setVisibleHeaderToCatalog(false));
      dispatch(resetTask());
    };
  }, [dispatch]);

  useEffect(() => {
    !!worker &&
      worker?.isStatus === 3 &&
      history.push(Routes.worker.register.onboarding);
    !!worker &&
      worker?.isStatus === 4 &&
      history.push(Routes.worker.register.callback);
    !!worker &&
      worker?.isStatus === 5 &&
      history.push(Routes.worker.register.final);

    if (taskDetailsErrorStatus === 400) {
      worker?.isStatus === 1 && history.push(Routes.worker.taskList);
      worker?.isStatus === 1 && dispatch(eventErrorOpen());
    }
  }, [history, dispatch, worker, taskDetailsErrorStatus]);

  useEffect(() => {
    dispatch(getTaskData(Number(taskId)));
    userShowPageTask(taskId);
  }, [dispatch, taskId]);

  const handleDisableSmsAlert = () => {
    dispatch(disableSmsAlert(Number(taskId)));
  };

  const handleEnableSmsAlert = () => {
    dispatch(enableSmsAlert(Number(taskId)));
  };

  if (chatLoading || !taskDetails || isLoading) {
    return <Preloader />;
  }

  return (
    <div className={css.task}>
      <div className={css.task__header}>
        <div className={cn("container", css.task__title)}>
          SOLICITUD
          <span className={css.task__id}>#{taskId}</span>
          {taskDetails.status.id === 4 && (
            <span className={css.task__tag}>Cerrado</span>
          )}
        </div>
      </div>

      <div className={cn("container", css.task__main)}>
        <div className={css.task__info}>
          {!taskDetails.paid ? (
            <div className={cn(css.task__status, css.status)}>
              <div className={css.status__icon}>
                <MessageMoney />
              </div>

              <div className={css.status__desc}>
                el cliente no ha pagado la primera sesión
              </div>
            </div>
          ) : (
            <div className={cn(css.task__status, css.status)}>
              <div className={css.status__icon}>
                <MessageWork />
              </div>

              {!!taskDetails.paidDateTime && (
                <div className={css.status__desc}>
                  el cliente ha pagado
                  <br />
                  la primera sesión
                  <br />
                  {taskDetails.paidDateTime.split(" ").reverse().join(" ")}
                </div>
              )}
            </div>
          )}

          <div className={cn(css.task__smsControl, css.smsControl)}>
            {taskDetails.smsAlert ? (
              <Button
                text="Desactivar SMS-notificación"
                className={css.smsControl__button}
                onClick={handleDisableSmsAlert}
                disabled={smsAlert.isLoading}
                transparent
              />
            ) : (
              <Button
                text="Activar SMS-notificación"
                className={css.smsControl__button}
                onClick={handleEnableSmsAlert}
                disabled={smsAlert.isLoading}
              />
            )}
          </div>

          <div className="divider" />
        </div>

        {!!worker && (
          <div className={cn(css.task__chat, css.chat)} ref={chatRef}>
            <h2 className={css.chat__title}>Chat</h2>

            <Chat
              channelUrl={taskDetails.channelUrl}
              className={css.chat__item}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkerChat;
