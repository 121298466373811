import React, { useEffect, useCallback } from "react";
import cn from "classnames";
import css from "./Toast.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { removeMessage, logSelector } from "src/store/logSlice";
import { Close, ToastError, ToastSuccess } from "src/assets/icons";

const ToastItem = ({ msg, onRemove = () => {} }: any) => {
  useEffect(() => {
    let timer: any = null;
    let timer2: any = null;

    timer = setTimeout(() => {
      onRemove(msg.id);
    }, 10000);

    timer2 = setTimeout(() => {
      onRemove(msg.id);
    }, 20000);

    return () => {
      !!msg.logIn ? clearTimeout(timer2) : clearTimeout(timer);
    };
  }, [msg.id, onRemove]); // eslint-disable-line

  return (
    <div
      className={cn(css.toast, {
        [css.toast_nonAnimation]: !!msg.logIn,
      })}
    >
      <div className={css[`toast-icon`]}>
        {msg.type === "error" && <ToastError />}
        {msg.type === "success" && <ToastSuccess />}
      </div>
      <div
        className={css[`toast-msg`]}
        dangerouslySetInnerHTML={{ __html: msg.message }}
      ></div>
      <div
        className={css[`toast-close`]}
        onClick={() => {
          onRemove(msg.id);
        }}
      >
        <Close />
      </div>
    </div>
  );
};

const Toast = () => {
  const dispatch = useDispatch();
  const { messages } = useSelector(logSelector);

  const handleRemove = useCallback(
    (idx: number) => {
      dispatch(removeMessage(idx));
    },
    [messages] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div className={cn(css[`toasts-wrapper`])}>
      <div className={css.toasts}>
        {messages.map((msg) => (
          <ToastItem key={msg.id} msg={msg} onRemove={handleRemove} />
        ))}
      </div>
    </div>
  );
};

export default Toast;
