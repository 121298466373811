import React from "react";
import { CardTypes } from "./CardTypes";
import css from "./Card.module.scss";
import cn from "classnames";
import Rating from "src/components/Rating/Rating";
import avatar from "src/assets/img/user-intro.png";
import Button from "src/components/UI-kit/Button/Button";

const Card: React.FC<CardTypes> = ({
  className,
  data,
  isConversion,
  onLink,
}) => {
  const { image, subtitle, title, score, text } = data || {};

  return (
    <div className={cn(className, css.card)}>
      <div className={css.card__avatarContainer}>
        {isConversion ? (
          <img src={avatar} alt="" className={css.card__avatar} />
        ) : (
          <img
            src={image || avatar}
            alt=""
            onError={(e) => {
              (e.target as HTMLImageElement).src = avatar;
            }}
            className={css.card__avatar}
          />
        )}
      </div>

      <div className={css.card__body}>
        <div className={css.card__title}>{title}</div>

        <div className={css.card__data}>
          <div className={cn(css.card__head, css.head)}>
            {subtitle && <div className={css.head__subtitle}>{subtitle}</div>}
          </div>

          {score && <Rating rating={score} className={css.card__rating} />}

          {isConversion ? (
            <div className={cn(css.card__message, css.message)}>
              <p className={css.message__text}>
                Responde a 3 sencillas preguntas y luego podrás ver reseñas y
                perfiles detallados de diferentes psicólogos, y así escoger al
                que mejor se adapte a tus necesidades.
              </p>

              <p className={css.message__text}>
                Haz click en el botón y rellena este pequeño formulario.
              </p>
              {onLink && (
                <Button
                  text={"Mostrar los mejores psicólogos"}
                  onClick={onLink}
                  className={css.message__button}
                />
              )}
            </div>
          ) : (
            <div className={cn(css.card__message, css.message)}>
              <div
                className={css.message__text}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
