import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { ButtonTypes } from "./ButtonTypes";
import css from "./Button.module.scss";

const Button: React.FC<ButtonTypes> = ({
  className,
  text,
  staticMode = false,
  transparent,
  small,
  onClick,
  disabled,
  type,
  counter,
  asLink = false,
  to = "",
}) => {
  const cnButton = cn(
    css.button,
    className,
    transparent && css.transparent,
    small && css.small,
    staticMode && css.static
  );

  if (asLink)
    return (
      <Link to={to} className={cnButton} onClick={(e) => onClick && onClick(e)}>
        {text}
      </Link>
    );

  return (
    <button
      className={cnButton}
      onClick={(e) => onClick && onClick(e)}
      disabled={disabled}
      type={type}
    >
      {text}
      {!!counter && <span className={css.button__counter}>{counter}</span>}
    </button>
  );
};

export default Button;
