import { FC } from "react";
import css from "./SelectedCountry.module.scss";
import cn from "classnames";
import Flag from "../Flag/Flag";
import { SelectedCountryProps } from "./SelectedCountryTypes";
import { Arrow } from "src/assets/icons";

const Dropdown: FC<SelectedCountryProps> = ({
  active,
  selectedCountry,
  className,
  ...props
}) => {
  return (
    <div
      className={cn(className, css.selectedCountry, {
        [css.selectedCountry_active]: active,
      })}
      {...props}
    >
      <Flag code={selectedCountry} className={css.selectedCountry__icon} />
      <Arrow className={css.selectedCountry__arrow} />
    </div>
  );
};

export default Dropdown;
