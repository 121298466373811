import { FC } from "react";
import Preloader from "src/components/Preloader/Preloader";
import Button from "src/components/UI-kit/Button/Button";
import { IShowMore } from "./ShowMoreTypes";
import cn from "classnames";
import css from "./ShowMore.module.scss";

const ShowMore: FC<IShowMore> = ({
  onClick,
  countShow,
  showPreloader,
  className,
}) => {
  return (
    <div className={cn(className, css[`show-more`])}>
      <Button
        text={`Mostrar ${countShow} más`}
        className={css[`show-more__btn`]}
        onClick={onClick}
        transparent
      />

      {showPreloader && <Preloader className={css[`show-more__preloader`]} />}
    </div>
  );
};

export default ShowMore;
