import React from "react";
import css from "./Review.module.scss";
import { ReviewTypes } from "./ReviewTypes";
import cn from "classnames";
import Rating from "../Rating/Rating";
import { dateAdapter3 } from "src/helpers/dateAdapter";

const Review: React.FC<ReviewTypes> = ({ className, data }) => {
  const { nameClient, date, review, grade } = data;

  return (
    <div className={cn(className, css.review)}>
      <div className={css[`review-title`]}>
        <span className={css.name}>
          {nameClient} <span className={css.date}>{dateAdapter3(date)}</span>
        </span>
      </div>
      <div className={css[`review-body`]}>
        <Rating rating={Number(grade)} />
        <div className={css.comment}>{review}</div>
      </div>
    </div>
  );
};

export default Review;
