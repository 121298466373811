import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "./strore";
import { api } from "../constants/api";
import axios from "axios";
import { clearToken } from "./authSlice";
import httpErrorHandler from "./httpErrorHandler";

export interface newRulesState {
  oldPath: string;
  isShowModal: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  error: null | string;
}

const initialState: newRulesState = {
  oldPath: "/",
  isShowModal: false,
  isLoading: false,
  isLoaded: false,
  error: null,
};

export const newRulesSlice = createSlice({
  name: "newRules",
  initialState,
  reducers: {
    setOldPath: (state: newRulesState, { payload }) => {
      state.oldPath = payload;
    },
    showNewRulesModal: (state: newRulesState) => {
      state.isShowModal = true;
    },

    acceptNewRules: (state: newRulesState) => {
      state.isLoading = true;
      state.isLoaded = false;
      state.error = null;
    },
    acceptNewRulesSuccess: (state: newRulesState) => {
      state.isShowModal = false;
      state.isLoading = false;
      state.isLoaded = true;
      state.error = null;
    },
    acceptNewRulesFailure: (state: newRulesState, { payload }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = payload;
    },

    deleteNewRules: (state: newRulesState) => {
      state.isLoading = true;
      state.isLoaded = false;
      state.error = null;
    },
    deleteNewRulesSuccess: (state: newRulesState) => {
      state.isShowModal = false;
      state.isLoading = false;
      state.isLoaded = true;
      state.error = null;
    },
    deleteNewRulesFailure: (state: newRulesState, { payload }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = payload;
    },
  },
});

export const {
  setOldPath,
  showNewRulesModal,
  acceptNewRules,
  acceptNewRulesSuccess,
  acceptNewRulesFailure,
  deleteNewRules,
  deleteNewRulesSuccess,
  deleteNewRulesFailure,
} = newRulesSlice.actions;

export const sendAcceptNewRules = (cb: () => {}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      auth: { tokenWorker },
    } = getState();

    try {
      await axios.post(`${api}/tutor/accepted-new-conditions/`, null, {
        headers: {
          Authorization: `Bearer ${tokenWorker}`,
          "Content-Type": "application/json",
        },
      });
      dispatch(acceptNewRulesSuccess());
      cb && cb();
    } catch (error) {
      httpErrorHandler(error, dispatch);
      dispatch(acceptNewRulesFailure("Произошла ошибка"));
    }
  };
};

export const sendDeleteNewRules = (cb: () => {}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      auth: { tokenWorker },
    } = getState();
    dispatch(deleteNewRules());

    try {
      await axios.post(`${api}/tutor/delete-new-conditions/`, null, {
        headers: {
          Authorization: `Bearer ${tokenWorker}`,
          "Content-Type": "application/json",
        },
      });
      dispatch(deleteNewRulesSuccess());
      dispatch(clearToken());
      cb && cb();
    } catch (error) {
      httpErrorHandler(error, dispatch);
      dispatch(deleteNewRulesFailure("Произошла ошибка"));
    }
  };
};

export const isShowModalSelector = (state: RootState) =>
  state.newRules.isShowModal;
export const oldPathSelector = (state: RootState) => state.newRules.oldPath;

export default newRulesSlice.reducer;
