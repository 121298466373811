import { FC, Fragment } from "react";
import { MessagesProps } from "./MessagesTypes";
import cn from "classnames";
import Message from "./Message/Message";
import css from "./Messages.module.scss";
import { IMessage } from "src/types/Chat";
import { getDate } from "src/helpers/getDate";

const Messages: FC<MessagesProps> = ({
  className,
  list,
  pendingMessages,
  firstUnreadMessage,
  lastMessageRef,
  lastPandingMessageRef,
  showPandingMessage,
  disableAnimateFirstUserMessage,
  noAnimateMessagesIds,
  ...args
}): JSX.Element => {
  let prevDate: string | null = null;

  const renderDate = (message: IMessage) => {
    if (prevDate !== getDate(message.createdAt) || prevDate === null) {
      prevDate = getDate(message.createdAt);

      return <div className={css.messages__date}>{prevDate}</div>;
    }

    return "";
  };

  return (
    <div className={cn(className, css.messages)}>
      {list.map((message, index) => (
        <Fragment key={message.messageId}>
          {renderDate(message)}

          <div className={css.messages__item}>
            <Message
              animate={
                !(disableAnimateFirstUserMessage && index < 2) &&
                !noAnimateMessagesIds.includes(Number(message.data))
              }
              message={message}
              {...args}
            />

            {index === list.length - 1 && (
              <div ref={lastMessageRef} className={css.messages__separator} />
            )}
          </div>
        </Fragment>
      ))}

      {showPandingMessage &&
        !!pendingMessages.length &&
        pendingMessages.map((message, index) => (
          <Fragment key={message.messageId}>
            {renderDate(message)}

            <div className={css.messages__item} key={message.messageId}>
              <Message message={message} animate={true} {...args} />

              {index === pendingMessages.length - 1 && (
                <div
                  ref={lastPandingMessageRef}
                  className={css.messages__separator}
                />
              )}
            </div>
          </Fragment>
        ))}
    </div>
  );
};

export default Messages;
