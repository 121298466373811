import React, { useEffect, useState } from "react";
import { Form, Formik, FormikErrors, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { Step4TypesProps, Step4TypesValues } from "./Step4Types";
import { Routes } from "src/constants/routes";
import {
  questionnaireSelector,
  resetData,
  updateData,
} from "src/store/questionnaireSlice";
import { getAllSpainData } from "src/store/referenceSlice";
import {
  createWorkerSaveDataSelector,
  sendDataSave,
  sendSaveDataInit,
} from "src/store/createWorkerSlice";
import Button from "src/components/UI-kit/Button/Button";
import FieldChange from "src/components/UI-kit/FieldChange/FieldChange";
import FieldTextArea from "src/components/UI-kit/FieldTextArea/FieldTextArea";

import css from "../Questionnaire.module.scss";
import { getWorkerData, resetWorker } from "src/store/workerSlice";
import Preloader from "src/components/Preloader/Preloader";

const Step4: React.FC<Step4TypesProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { step } = useParams<{ step: string }>();
  const allData = useSelector(questionnaireSelector);
  const { isLoaded, error } = useSelector(createWorkerSaveDataSelector);
  const [isPreloader, setIsPreloader] = useState<boolean>(false);

  const thisData = allData.data.map(
    (item) => item.step === step && item.values
  );

  const [err, setErr] = useState<FormikErrors<Step4TypesValues>>({});
  const [formErrorMessage, setFormErrorMessage] = useState("");

  useEffect(() => {
    if (isLoaded && error === null) {
      dispatch(resetWorker());
      dispatch(getWorkerData());
      history.push(Routes.worker.register.callback);
      dispatch(sendSaveDataInit());
    }
  }, [isLoaded, error, dispatch, history]);

  useEffect(() => {
    dispatch(getAllSpainData());
  }, [dispatch]);

  const initData: /* Step4TypesValues */ any = thisData[3] || {
    about: "",
  };

  const handleSubmit = (values: Step4TypesValues) => {
    setIsPreloader(true);
    // workerClickRegister();

    let finalData = {};

    allData.data.forEach((item) => {
      Object.assign(finalData, item.values);
    });

    dispatch(resetData());
    dispatch(updateData({ values, step }));
    dispatch(
      sendDataSave({
        ...finalData,
        about: values.about
          .replace(/[^\n]+/gi, "<p>$&</p>")
          .replace(/\n/gi, ""),
      })
    );
  };

  const handleStepBack = (values: Step4TypesValues) => {
    prevStep(+step - 1);
    dispatch(updateData({ values, step }));
  };

  const validate = (values: Step4TypesValues) => {
    const errors: FormikErrors<Step4TypesValues> = {};

    if (!values.about.length) {
      errors.about = "Campo obligatorio";
    }

    return errors;
  };

  useEffect(() => {
    const msgErrors = {
      paymentMethods: {
        has: !!err.about,
        text: "descripción",
      },
    };

    const errors = Object.values(msgErrors).filter((m) => m.has);

    if (errors.length) {
      setFormErrorMessage(
        `Para registrarse completa: ` +
          errors.map((m) => m.text).join(", ") +
          "."
      );
    } else {
      setFormErrorMessage("");
    }
  }, [err]);

  const prevStep = (step: number) => {
    step !== 0
      ? history.push(`${Routes.worker.register.questionnaire}/${step}`)
      : history.push(Routes.worker.register.onboarding);
  };

  if (isPreloader) {
    return <Preloader />;
  }

  return (
    <Formik
      initialValues={initData}
      enableReinitialize
      onSubmit={handleSubmit}
      validate={validate}
      validateOnMount={true}
    >
      {({ values, errors, dirty }: FormikProps<Step4TypesValues>) => (
        <Form className={css.questionnaire__wrap}>
          <div className={css.questionnaire__checkboxWrap}>
            <FieldTextArea
              className={css.questionnaire__textarea}
              name="about"
              type="text"
              label="Descripción"
              placeholder={
                "Escribe un breve texto sobre ti para que lo mostremos en tu perfil"
              }
              required
            />
          </div>

          <div className="divider"></div>

          <Button
            transparent
            className={css.questionnaire__btn}
            type="button"
            text="Volver"
            onClick={() => handleStepBack(values)}
          />

          <Button
            className={css.questionnaire__btn}
            type="submit"
            text="Enviar"
            disabled={!!Object.keys(errors).length}
          />

          {!!formErrorMessage.length && (
            <div className={css.questionnaire__alert}>
              <p>{formErrorMessage}</p>
            </div>
          )}

          <FieldChange<Step4TypesValues> onError={setErr} />
        </Form>
      )}
    </Formik>
  );
};

export default Step4;
