import { ReactComponent as Logo } from "src/assets/img/logo.svg";
import css from "./Header.module.scss";
import cn from "classnames";
import { Routes } from "src/constants/routes";
import { Link } from "react-router-dom";
import MenuWorker from "./components/Worker/MenuWorker";
import { useSelector } from "react-redux";
import { authSelector } from "src/store/authSlice";
import MenuTask from "./components/Task/MenuTask";
import { ArrowLeft } from "src/assets/icons";
import { layoutHeaderSelector } from "src/store/layoutSlice";
import { refundInfoSelector } from "src/store/createNewTaskSlice";
import Banner from "./components/Banner/Banner";
import Unauthorized from "./components/Unauthorized/Unauthorized";
import { mvp2Link } from "src/constants/constants";

const Header = () => {
  const { isAuthTask, isAuthWorker } = useSelector(authSelector);
  const { visibleToCatalog } = useSelector(layoutHeaderSelector);
  const { shownPopup, shownBanner } = useSelector(refundInfoSelector);

  const showBanner = shownPopup && !shownBanner && !isAuthWorker;

  return (
    <header
      className={cn(css.header, {
        [css.header_withBanner]: showBanner,
      })}
    >
      <Banner className={css.header__banner} show={showBanner} />

      <div className={cn(css.header__wrapper, "container")}>
        <div className={css.header__row}>
          <div className={css.header__col}>
            {visibleToCatalog && (
              <Link
                to={
                  isAuthWorker
                    ? Routes.worker.taskList
                    : Routes.client.create.intro
                }
                className={css.prev}
              >
                <ArrowLeft className={css.prev__icon} />
              </Link>
            )}
          </div>

          <div className={css.header__col}>
            <a href={mvp2Link ?? Routes.home} className={css.header__logo}>
              <Logo />
            </a>
          </div>

          <div className={css.header__col}>
            {isAuthTask && <MenuTask />}
            {isAuthWorker && <MenuWorker />}
            {!isAuthTask && !isAuthWorker && (
              <Unauthorized className={css.header__burger} />
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
