import { FC, useEffect, useState } from "react";
import css from "./TasksList.module.scss";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { getTasksData, workerSelector } from "src/store/workerSlice";
import Preloader from "src/components/Preloader/Preloader";
import Empty from "src/components/Empty/Empty";
import Item from "./Item/Item";
import ShowMore from "./ShowMore/ShowMore";
import { useHistory } from "react-router-dom";
import { Routes } from "src/constants/routes";

const WorkerTasks: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    availableTasks: {
      data,
      count,
      isLoading: dataLoading,
      isLoaded: dataLoaded,
    },
    worker,
  } = useSelector(workerSelector);

  useEffect(() => {
    if (worker?.isStatus !== 1) {
      if (worker?.isStatus === 3) {
        history.push(Routes.worker.register.onboarding);
      }
      if (worker?.isStatus === 4) {
        history.push(Routes.worker.register.callback);
      }
      if (worker?.isStatus === 5) {
        history.push(Routes.worker.register.final);
      }
    }
  }, [history, worker]);

  const tasksLimit = 10;

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (page > 1) {
      dispatch(getTasksData(1, page, tasksLimit, true));
    } else {
      dispatch(getTasksData(1, 1, tasksLimit, false));
    }
  }, [dispatch, page]);

  if (!dataLoaded || worker?.isStatus !== 1 || !worker) {
    return <Preloader />;
  }

  return (
    <div className={css.tasks}>
      <div className={css.tasks__header}>
        <div className="container">
          <div className={css.tasks__title}>SOLICITUDES</div>
        </div>
      </div>
      {!data?.length ? (
        <Empty className={css.tasks__empty} title={"No hay solicitudes"} />
      ) : (
        <div className={cn("container", css.tasks__main)}>
          {data.map((task) => {
            return (
              <Item
                key={task.id}
                task={task}
                consultation={worker.consultation}
                className={css.tasks__item}
              />
            );
          })}

          {data.length < count && (
            <ShowMore
              onClick={() => setPage(page + 1)}
              countShow={
                count - data.length > tasksLimit
                  ? tasksLimit
                  : count - data.length
              }
              showPreloader={dataLoading}
              className={css.tasks__showMore}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default WorkerTasks;
