import { FC } from "react";
import { IHeader } from "./types";
import cn from "classnames";

import Rating from "src/components/Rating/Rating";
import css from "./Header.module.scss";

import usedExperience from "src/helpers/usedExperience";

import avatar from "src/assets/img/user.svg";
import avatarMale from "src/assets/img/user-male.svg";
import avatarFemale from "src/assets/img/user-female.svg";
import { SimpleCamera, Verified } from "src/assets/icons";

const ProfileHeader: FC<IHeader> = ({
  grade,
  photoPath,
  photoPathSquare,
  sex,
  firstName,
  lastName,
  lastNameTwo,
  experience,
  reviewsCountAll,
  consultation: {
    methodsOfConsultation,
    costConsultation,
    durationConsultation,
  },
  onClickReviews = () => "",
  className,
}) => {
  const defaultAvatar =
    sex === 1 ? avatarMale : sex === 2 ? avatarFemale : avatar;

  return (
    <div className={cn(className, css.header)}>
      <div className={cn(css.header__photo, css.photo)}>
        <div className={css.photo__wrapper}>
          <div className={css.photo__imgContainer}>
            <img
              src={photoPath || photoPathSquare || defaultAvatar}
              alt=""
              onError={(e) => {
                (e.target as HTMLImageElement).src = defaultAvatar;
              }}
              className={css.photo__img}
            />
          </div>

          <div className={cn(css.photo__name, css.name)}>
            <span className={css.name__text}>
              {firstName || ""} {lastName || ""} {lastNameTwo}
              <Verified className={css.name__status} />
            </span>
          </div>
        </div>
      </div>

      <p className={css.header__cost}>
        Desde {costConsultation} € / {durationConsultation} min
      </p>

      <div className={css.header__rating}>
        <Rating rating={reviewsCountAll === 0 ? 0 : Number(grade)} />

        {reviewsCountAll !== 0 && (
          <button onClick={onClickReviews}>
            {reviewsCountAll === 1
              ? "1 opinión"
              : `${reviewsCountAll} opiniones`}
          </button>
        )}
      </div>

      <p className={css.header__experience}>
        {usedExperience(experience)} {experience !== 0 && "de experiencia"}
      </p>

      {!!methodsOfConsultation?.length && (
        <p className={css.header__methods}>
          <SimpleCamera /> Sesiones vía{" "}
          {methodsOfConsultation.map((m) => m.name).join(", ")}
        </p>
      )}
    </div>
  );
};

export default ProfileHeader;
