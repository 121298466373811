import cn from "classnames";
import { FieldHookConfig, useField } from "formik";
import css from "./FieldText.module.scss";

interface FieldTextProps {
  className?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  patterns?: string;
  min?: number | string;
  step?: number | string;
  isBlur?: boolean;
  onChange?: (value: any) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

const FieldText = (props: FieldHookConfig<string> & FieldTextProps) => {
  const {
    className,
    label,
    required,
    type,
    placeholder,
    disabled,
    min,
    step,
    isBlur,
    ...config
  } = props;
  const [field, meta] = useField(config);
  const numericProps =
    type === "number"
      ? {
        inputMode: "numeric",
        pattern: "[^d]*",
        step: step,
        min: min,
        onWheel: (e: any) => e.target.blur(),
        onKeyDown: (e: any) => {
          if ([187, 188, 189, 190, 191, 69].includes(e.keyCode))
            e.preventDefault();
          if (e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
          }

          // для сафари
          var charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
          var charStr = String.fromCharCode(charCode);

          if (!charStr.match(/^[0-9]+$/)) {
            if (!(e.which !== 8 || e.keyCode !== 86 || e.keyCode !== 17)) {
              e.preventDefault();
            }
          }
        },
      }
      : {};

  return (
    <label
      className={cn(className, css.input, disabled && css["input-disable"])}
    >
      {label && (
        <div className={css[`input-label`]}>
          {`${label}${required ? "*" : ""}`}
        </div>
      )}
      {/* @ts-ignore */}
      <input
        className={cn({
          [css.error]: meta.touched && !!meta.error,
          [css.input__code]: !field.value && placeholder === "_ _ _ _",
        })}
        type={type || "text"}
        placeholder={placeholder}
        autoComplete={"off"}
        disabled={disabled}
        readOnly={isBlur}
        {...numericProps}
        {...field}
      />

      {meta.touched && !!meta.error ? (
        <div className={cn(css[`input-error`], css.show)}>{meta.error}</div>
      ) : null}
    </label>
  );
};

export default FieldText;
