import { FC } from "react";
import { StepProps } from "./StepTypes";
import cn from "classnames";
import css from "./Step.module.scss";

import Button from "src/components/UI-kit/Button/Button";
import { Routes } from "src/constants/routes";

const Step: FC<StepProps> = ({ className, onLink }) => {
  return (
    <div className={cn(className, css.step)}>
      <div className={cn(css.step__wrapper, "container")}>
        <h2 className={css.step__title}>Cómo funciona</h2>

        <ul className={css.step__list}>
          <li className={css.step__item}>
            Promocionamos tu perfil en nuestra plataforma
          </li>

          <li className={css.step__item}>
            Te avisamos vía SMS cuando hay un cliente potencial
          </li>

          <li className={css.step__item}>
            Acordáis la primera sesión y esperas a que se realice el pago
          </li>

          <li className={css.step__item}>
            Te cobramos el valor de la primera sesión únicamente si se ha
            iniciado la terapia
          </li>
        </ul>

        <Button
          className={css.step__button}
          text={"Registrarse"}
          onClick={onLink}
          asLink
          to={Routes.worker.register.form}
        />
      </div>
    </div>
  );
};

export default Step;
