import { FC, Fragment, useEffect, useRef, useState } from "react";
import { ListProps } from "./ListTypes";
import css from "./List.module.scss";
import cn from "classnames";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import Card from "../Card/Card";
import { statisticPsychologists } from "src/api/statisticPsychologists";
import { useDebounce } from "src/hooks/useDebounce";

const List: FC<ListProps> = ({ className, data, taskLogged, workerLogged }) => {
  const swipersRef = useRef<HTMLElement[]>([]);
  const [currentWorkerId, setCurrentWorkerId] = useState<string | null>(null);
  const debouncedWorkerId = useDebounce<string | null>(currentWorkerId, 2000);
  const listenerStatistic = !taskLogged && !workerLogged;

  useEffect(() => {
    const { current: swipers } = swipersRef;
    if (!swipers.length) return;

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentWorkerId(entry.target.getAttribute("data-activeslideid"));
        }
      });
    };

    const options = {
      rootMargin: "0px",
      threshold: 0.95,
    };

    const observer = new IntersectionObserver(observerCallback, options);
    swipers.forEach((swiper) => observer.observe(swiper));
  }, [swipersRef, listenerStatistic]);

  useEffect(() => {
    if (!debouncedWorkerId || !listenerStatistic) return;

    statisticPsychologists("minicards", debouncedWorkerId);
  }, [debouncedWorkerId, listenerStatistic]);

  return (
    <div className={className}>
      {data.map((cat, index, cats) => (
        <Fragment key={cat.id}>
          <div
            className={cn(css.slider, {
              [css.slider_full]: cat.tutors.length === 1,
            })}
          >
            <h2 className={cn("h2", css.slider__title)}>
              SESIONES {cat.frontText}
            </h2>

            <div
              className={cn(css.slider__listWrapper, {
                [css.slider__listWrapper_full]: cat.tutors.length === 1,
              })}
            >
              <Swiper
                className={css.slider__list}
                modules={[Pagination]}
                pagination={
                  cat.tutors.length !== 1
                    ? {
                        clickable: true,
                        bulletClass: css.pagination__bullet,
                        bulletActiveClass: css.pagination__bullet_active,
                        horizontalClass: css.pagination,
                        dynamicBullets: true,
                        dynamicMainBullets: 10,
                      }
                    : false
                }
                slidesPerView={cat.tutors.length !== 1 ? "auto" : 1}
                centeredSlides
                centeredSlidesBounds
                // Фикс клика по ссылкам неативного слайда (альтернатива slideToClickedSlide)
                //slideToClickedSlide
                preventClicks
                preventClicksPropagation
                slideToClickedSlide
                simulateTouch
                onClick={(swiper) => {
                  if (swiper.clickedIndex !== swiper.activeIndex) {
                    swiper.slideTo(swiper.clickedIndex);
                  }
                }}
                onSwiper={(swiper) => {
                  if (!listenerStatistic) return;
                  swipersRef.current.push(swiper.el);
                }}
                onSlideChange={(swiper) => {
                  if (!listenerStatistic) return;

                  const activeSlide = swiper.slides[swiper.activeIndex];

                  swiper.el.setAttribute(
                    "data-activeslideid",
                    activeSlide.getAttribute("data-workerid") || ""
                  );
                  setCurrentWorkerId(activeSlide.getAttribute("data-workerid"));
                }}
                data-activeslideid={cat.tutors[0].id}
              >
                {cat.tutors.map((worker) => (
                  <SwiperSlide
                    className={cn(css.slider__item, {
                      [css.slider__item_full]: cat.tutors.length === 1,
                    })}
                    key={worker.id}
                    data-workerid={worker.id}
                  >
                    <div className={css.slider__slide}>
                      <Card
                        {...worker}
                        taskLogged={taskLogged}
                        workerLogged={workerLogged}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          {index !== cats.length - 1 && <div className={"divider"} />}
        </Fragment>
      ))}
    </div>
  );
};

export default List;
