import { taskIdLogged } from "src/constants/constants";
import { addMessage } from "./logSlice";
import { AppDispatch } from "./strore";
import { clearToken } from "./authSlice";

const httpErrorHandler = (error: any, dispatch: AppDispatch) => {
  /*const dispatch = useDispatch();*/
  if (!error.response) {
    dispatch(
      addMessage({ type: "error", message: "Error de conexión del servidor" })
    );
  }

  if (error.response?.status === 401) {
    dispatch(addMessage({ type: "error", message: "Error no autorizado" }));
    dispatch(clearToken());
    localStorage.removeItem(taskIdLogged);
  }
  /*handleUnauthorized*/
  /*if (error.status === 500) {
        dispatch(addMessage({type: 'error', message: 'Произошла ошибка на сервере, попробуйте повторить позже'}))
    }*/
};

export default httpErrorHandler;
