import React from "react";
import css from "./Preloader.module.scss";
import { PreloaderTypes } from "./PreloaderTypes";
import cn from "classnames";

const Preloader: React.FC<PreloaderTypes> = ({ className, xs = false }) => {
  return (
    <div className={cn(className, css.wrapper, {[css.wrapper_xs]: xs })}>
      <div className={css.preloader}></div>
    </div>
  );
};

export default Preloader;
