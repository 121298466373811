import React, { useEffect, useState } from "react";
import css from "./TaskLogin.module.scss";
import cn from "classnames";
import { Formik, Form, FormikProps, FormikErrors } from "formik";
import { TaskLoginTypes } from "./TaskLoginTypes";
import Button from "src/components/UI-kit/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  cleareloginTask,
  LoginFormValues,
  sendAuthTask,
  sendLogoutTask,
  taskSelector,
} from "src/store/taskSlice";
import { authSelector } from "src/store/authSlice";
import FieldText from "src/components/UI-kit/FieldText/FieldText";
import { useHistory } from "react-router-dom";
import { sendLogoutWorker } from "src/store/workerSlice";
import Modal from "src/components/Modal/Modal";
import { MessageSad } from "src/assets/graphIcons";
import FieldChange from "src/components/UI-kit/FieldChange/FieldChange";
import { Routes } from "src/constants/routes";

const TaskLogin: React.FC<TaskLoginTypes> = ({ className }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, isBlocked, completed } = useSelector(taskSelector);
  const { isAuthTask, isAuthWorker } = useSelector(authSelector);
  const [data, setData] = useState<LoginFormValues>();

  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const handleSubmit = (data: LoginFormValues) => {
    if (isAuthTask) {
      setShowModalConfirm(true);
    } else {
      if (isAuthWorker) dispatch(sendLogoutWorker());
      dispatch(sendAuthTask(data));
    }
  };

  const validate = (values: LoginFormValues) => {
    const errors: FormikErrors<LoginFormValues> = {};

    if (!values.taskLoginId) {
      errors.taskLoginId = "Campo obligatorio";
    }

    if (!values.taskLoginPassword) {
      errors.taskLoginPassword = "Campo obligatorio";
    }

    return errors;
  };

  useEffect(() => {
    if (completed) {
      dispatch(cleareloginTask());
      history.push(Routes.client.create.intro);
    }
  }, [dispatch, history, completed]);

  return (
    <div className={cn(className, css[`login`], "fullpage", "container")}>
      <div className={css["login-title"]}>ABRIR SOLICITUD</div>

      <Formik
        initialValues={{
          taskLoginId: "",
          taskLoginPassword: "",
        }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ errors }: FormikProps<LoginFormValues>) => (
          <Form className={css["login-form"]}>
            <FieldText
              className={css["login-form__input"]}
              placeholder={"Introduce"}
              label={"Número de solicitud"}
              name={"taskLoginId"}
              type={"number"}
              required
            />

            <FieldText
              className={css["login-form__input"]}
              placeholder={"Introduce"}
              label={"Contraseña"}
              name={"taskLoginPassword"}
              type={"password"}
              required
            />

            <div
              className={cn(css["login-form__desc"], {
                [css["login-form__desc_red"]]: isBlocked,
              })}
            >
              {!isBlocked
                ? "El número de solicitud y la contraseña se te enviaron por SMS en el momento en el que se creó la misma."
                : "La tarea está bloqueada"}
            </div>

            <Button
              type="submit"
              text={"Abrir"}
              className={css["login-form__submit"]}
              disabled={isBlocked || isLoading || !!Object.keys(errors).length}
            />

            <FieldChange<LoginFormValues> onChange={setData} />
          </Form>
        )}
      </Formik>

      {showModalConfirm && (
        <Modal
          active={true}
          setActive={setShowModalConfirm}
          onSubmit={() => {
            if (data) {
              dispatch(sendLogoutTask());
              dispatch(sendAuthTask(data));
            }
          }}
          modalConfig={{
            icon: <MessageSad />,
            title: "Ya estás en la solicitud",
            desc: "¿Quieres cerrarla y abrir una nueva?",
            submitText: "Abrir",
          }}
          type={"accept"}
        />
      )}
    </div>
  );
};

export default TaskLogin;
