import { FC, useEffect } from "react";
import cn from "classnames";
import css from "./WorkerLogin.module.scss";
import { WorkerLoginTypes } from "./WorkerLoginTypes";
import Button from "src/components/UI-kit/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  sendAuthWorker,
  WorkerLoginFormValues,
  workerSelector,
} from "src/store/workerSlice";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "src/constants/routes";
import { Formik, Form, FormikProps, FormikErrors } from "formik";
import FieldText from "src/components/UI-kit/FieldText/FieldText";
import InternationalPhone from "src/components/UI-kit/InternationalPhone/InternationalPhone";
import { authSelector } from "src/store/authSlice";
import Preloader from "src/components/Preloader/Preloader";

const WorkerLogin: FC<WorkerLoginTypes> = ({ className }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tokenWorker } = useSelector(authSelector);
  const { worker } = useSelector(workerSelector);

  useEffect(() => {
    if (tokenWorker) {
      if (worker?.isStatus === 1) {
        history.push(Routes.worker.taskList);
      }
      if (worker?.isStatus === 3) {
        history.push(Routes.worker.register.onboarding);
      }
      if (worker?.isStatus === 4) {
        history.push(Routes.worker.register.callback);
      }
      if (worker?.isStatus === 5) {
        history.push(Routes.worker.register.final);
      }
    }
  }, [worker, tokenWorker, history]);

  const validate = (values: WorkerLoginFormValues) => {
    const errors: FormikErrors<WorkerLoginFormValues> = {};

    if (!values.workerLoginPhone) {
      errors.workerLoginPhone = "Campo obligatorio";
    } else if (values.workerLoginPhone === "INCORRECT") {
      errors.workerLoginPhone = "Formato erróneo";
    }

    if (!values.workerLoginPassword) {
      errors.workerLoginPassword = "Campo obligatorio";
    } else if (values.workerLoginPassword.trim().length < 3) {
      errors.workerLoginPassword =
        "La contraseña debe contener al menos 3 caracteres";
    }

    return errors;
  };

  if (tokenWorker) {
    return <Preloader />;
  }

  return (
    <div className={cn(className, css[`login`], "container", "fullpage")}>
      <div className={css["login-title"]}>Iniciar sesión como PSICÓLOGO</div>

      <Formik
        initialValues={{
          workerLoginPhone: "",
          workerLoginPassword: "",
        }}
        onSubmit={(data) => {
          dispatch(sendAuthWorker(data));
        }}
        validate={validate}
      >
        {({ dirty, errors }: FormikProps<WorkerLoginFormValues>) => (
          <Form className={css["login-form"]}>
            <InternationalPhone
              className={css["login-form__input"]}
              name={"workerLoginPhone"}
            />

            <FieldText
              className={css["login-form__input"]}
              name={"workerLoginPassword"}
              type={"password"}
              label={"Contraseña"}
              placeholder={"Introduce"}
              required
            />
            <Button
              className={css["login-form__submit"]}
              type={"submit"}
              text={"Entrar"}
              disabled={!dirty || !!Object.keys(errors).length}
            />
            <div className={css["login-form__desc"]}>
              Si has olvidado tu contraseña, ponte en contacto con soporte
              técnico.
            </div>

            <Link
              className={css["login-form__register"]}
              to={Routes.worker.register.main}
            >
              Registrarse
            </Link>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default WorkerLogin;
