import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "./strore";
import { api } from "../constants/api";
import axios from "axios";
import { taskIdLogged } from "src/constants/constants";
import { Task } from "../types/Task";
import { Worker } from "../types/Worker";
import httpErrorHandler from "./httpErrorHandler";
import { addMessage } from "./logSlice";
import { showNewRulesModal } from "./newRulesSlice";
import { Review } from "../types/Review";
import { userWorkerAuthSuccess } from "src/helpers/metric";
import { clearToken, setTokenWorker } from "./authSlice";
import { disconnect as disconnectChat } from "./chatSlice";

export interface WorkerLoginFormValues {
  workerLoginPhone: string;
  workerLoginPassword: string;
}

export interface WorkerState {
  isLoading: boolean;
  isLoaded: boolean;
  isLogged: boolean;
  availableTasks: {
    data: Task[] | null;
    count: number;
    isLoading: boolean;
    isLoaded: boolean;
  };
  taskDetails: Task | null;
  taskDetailsErrorStatus: null | number;
  worker: Worker | null;
  reviews: {
    isLoading: boolean;
    reviews: [] | Review[];
    count: number;
  };
  smsAlert: {
    isLoading: boolean;
    error: any;
  };
  workerNotFound: boolean;
}

const initialState: WorkerState = {
  isLoading: false,
  isLoaded: false,
  isLogged: false,
  availableTasks: {
    data: [],
    count: 0,
    isLoading: false,
    isLoaded: false,
  },
  taskDetails: null,
  taskDetailsErrorStatus: null,
  worker: null,
  reviews: {
    isLoading: true,
    reviews: [],
    count: 0,
  },
  smsAlert: {
    isLoading: false,
    error: null,
  },
  workerNotFound: false,
};

/* TODO: refactor code, move api requests to the http folder with configs */

export const workerSlice = createSlice({
  name: "worker",
  initialState,
  reducers: {
    loginWorker: (state: WorkerState) => {
      state.isLoading = true;
    },
    loginWorkerSuccess: (state: WorkerState) => {
      state.isLoading = false;
      state.isLogged = true;
    },
    loginWorkerFailure: (state: WorkerState) => {
      state.isLoading = false;
      state.isLogged = false;
    },

    logoutWorker: (state: WorkerState) => {
      state.isLoading = true;
    },
    logoutWorkerSuccess: () => {
      return { ...initialState };
    },
    logoutWorkerFailure: (state: WorkerState) => {
      state.isLoading = false;
    },

    getWorker: (state: WorkerState) => {
      state.isLoading = true;
      state.isLoaded = false;
      state.workerNotFound = initialState.workerNotFound;
    },
    getWorkerSuccess: (state: WorkerState, { payload: { data, isWorker } }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.worker = data;
      state.isLogged = isWorker;
      state.workerNotFound = false;
    },
    getWorkerFailure: (state: WorkerState) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.workerNotFound = true;
    },
    resetWorkerNotFound: (state: WorkerState) => {
      state.workerNotFound = false;
    },
    resetWorker: (state: WorkerState) => {
      state.worker = null;
      state.workerNotFound = false;
    },

    getTasks: (state: WorkerState) => {
      state.isLoading = true;
      state.availableTasks = {
        data: null,
        count: 0,
        isLoading: true,
        isLoaded: false,
      };
    },
    getTasksSuccess: (state: WorkerState, { payload }) => {
      state.isLoading = false;

      state.availableTasks = {
        data: payload.data,
        count: payload.count,
        isLoading: false,
        isLoaded: true,
      };
    },
    getMoreTasks: (state: WorkerState, { payload }) => {
      const oldTasks = state.availableTasks?.data || [];
      state.availableTasks = {
        data: [...oldTasks, ...payload.data],
        count: payload.count,
        isLoading: false,
        isLoaded: true,
      };
    },
    getTasksFailure: (state: WorkerState) => {
      state.availableTasks = {
        data: null,
        count: 0,
        isLoading: false,
        isLoaded: true,
      };
    },

    getTask: (state: WorkerState) => {
      state.isLoading = true;
    },
    getTaskSuccess: (state: WorkerState, { payload }) => {
      state.isLoading = false;
      if (!!payload?.response) {
        state.taskDetails = {
          ...payload,
          response: {
            ...payload?.response,
            showClient: !!payload?.response?.view,
            datetime: payload?.response?.datetime_response,
          },
        };
      } else {
        state.taskDetails = { ...payload };
      }
    },
    getTaskFailure: (state: WorkerState, { payload }) => {
      state.isLoading = false;
      state.taskDetails = null;
      state.taskDetailsErrorStatus = payload;
    },

    resetTask: (state: WorkerState) => {
      state.isLoading = false;
      state.taskDetails = null;
      state.taskDetailsErrorStatus = null;
    },

    getReviews: (state: WorkerState) => {
      state.reviews.isLoading = true;
    },
    getReviewsSuccess: (state: WorkerState, { payload }) => {
      state.reviews.isLoading = false;
      state.reviews.reviews = payload?.reviews;
      state.reviews.count = payload?.countAll;
    },
    getMoreReviews: (state: WorkerState, { payload }) => {
      state.reviews.isLoading = false;
      state.reviews.reviews = [...state.reviews.reviews, ...payload.reviews];
      state.reviews.count = payload.countAll;
    },
    getReviewsFailure: (state: WorkerState) => {
      state.reviews.isLoading = false;
      state.reviews.reviews = [];
    },
    resetReviews: (state: WorkerState) => {
      state.reviews = { ...initialState.reviews };
    },

    smsAlertStart: (state: WorkerState) => {
      state.smsAlert.isLoading = true;
      state.smsAlert.error = null;
    },
    smsAlertSuccess: (state: WorkerState) => {
      state.smsAlert.isLoading = false;
      state.smsAlert.error = null;

      if (state.taskDetails?.smsAlert !== undefined) {
        state.taskDetails.smsAlert = !state.taskDetails.smsAlert;
      }
    },
    smsAlertFailure: (state: WorkerState, { payload }) => {
      state.smsAlert.isLoading = true;
      state.smsAlert.error = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loginWorker,
  loginWorkerSuccess,
  loginWorkerFailure,

  getWorker,
  getWorkerSuccess,
  getWorkerFailure,
  resetWorker,

  getTasks,
  getTasksSuccess,
  getMoreTasks,
  getTasksFailure,
  resetTask,

  getTask,
  getTaskSuccess,
  getTaskFailure,

  logoutWorker,
  logoutWorkerSuccess,
  logoutWorkerFailure,

  getReviews,
  getMoreReviews,
  getReviewsSuccess,
  getReviewsFailure,
  resetReviews,

  smsAlertStart,
  smsAlertSuccess,
  smsAlertFailure,

  resetWorkerNotFound,
} = workerSlice.actions;
export const workerSelector = (state: RootState) => state.worker;
export const smsAlertSelector = (state: RootState) => state.worker.smsAlert;

export default workerSlice.reducer;

export const sendAuthWorker = (loginData: WorkerLoginFormValues) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loginWorker());
    dispatch(clearToken()); // Удаляю токены

    try {
      const { data } = await axios.post(`${api}/tutor/auth/`, {
        phone: loginData.workerLoginPhone,
        password: loginData.workerLoginPassword,
      });
      dispatch(loginWorkerSuccess());
      dispatch(setTokenWorker(data.token)); // Сохраняю токен
      userWorkerAuthSuccess(data.idTutor); // Ya.Metrika
    } catch (error: any) {
      const { errors } = error.response.data;
      httpErrorHandler(error, dispatch);
      dispatch(loginWorkerFailure());
      errors.forEach((err: any) =>
        dispatch(addMessage({ type: "error", message: err.message }))
      );
    }
  };
};

export const sendLogoutWorker = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      auth: { tokenWorker },
    } = getState();
    dispatch(logoutWorker());

    try {
      await axios.post(`${api}/tutor/logout/`, null, {
        headers: {
          Authorization: `Bearer ${tokenWorker}`,
          "Content-Type": "application/json",
        },
      });
      dispatch(logoutWorkerSuccess());
      dispatch(clearToken()); // Удаляю токены
      dispatch(disconnectChat());
      localStorage.removeItem(taskIdLogged);
    } catch (error: any) {
      httpErrorHandler(error, dispatch);
      dispatch(logoutWorkerFailure());
    }
  };
};

export const getWorkerData = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      auth: { tokenWorker },
    } = getState();

    if (!tokenWorker) return;

    dispatch(getWorker());

    try {
      const { data } = await axios.get(`${api}/tutor/info/`, {
        headers: {
          Authorization: `Bearer ${tokenWorker}`,
          "Content-Type": "application/json",
        },
      });

      dispatch(
        getWorkerSuccess({
          data: data.data,
          isWorker: true,
        })
      );

      // const idSendbird = data.data.idSendbird as string;
      // dispatch(сonnectionChat(idSendbird));
    } catch (error: any) {
      httpErrorHandler(error, dispatch);
      dispatch(getWorkerFailure());
    }
  };
};

export const getTasksData = (
  statusId: number,
  page: number,
  limit: number,
  add?: boolean
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      auth: { tokenWorker },
    } = getState();
    !add && dispatch(getTasks());

    try {
      const { data } = await axios.get(
        // TODO:
        `${api}/tutor/list-task/?status=${statusId}&page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${tokenWorker}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (
        data.success === 1 &&
        data.check === "confirmationOfNewConditionsIsRequired"
      ) {
        dispatch(showNewRulesModal());
        dispatch(getTasksFailure());
        return;
      }

      add
        ? dispatch(getMoreTasks({ data: data.data, count: data.count }))
        : dispatch(getTasksSuccess({ data: data.data, count: data.count }));
    } catch (error: any) {
      dispatch(getTasksFailure());
      httpErrorHandler(error, dispatch);
    }
  };
};

export const getTaskData = (id: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      auth: { tokenWorker },
    } = getState();
    dispatch(getTask());

    try {
      const { data } = await axios.get(`${api}/tutor/task/?id=${id}`, {
        headers: {
          Authorization: `Bearer ${tokenWorker}`,
          "Content-Type": "application/json",
        },
      });

      if (
        data.success === 1 &&
        data.check === "confirmationOfNewConditionsIsRequired"
      ) {
        dispatch(showNewRulesModal());
        dispatch(getTaskFailure(404));
        return;
      }

      dispatch(getTaskSuccess(data.data));
    } catch (error: any) {
      dispatch(getTaskFailure(error?.response?.status));
      httpErrorHandler(error, dispatch);
    }
  };
};

export const getReviewsData = (
  page?: number,
  limit?: number,
  add?: boolean
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      auth: { tokenWorker },
    } = getState();
    const addMode = page?.toString() && limit?.toString() && add;

    dispatch(getReviews());

    try {
      const { data } = await axios.get(`${api}/tutor/review/`, {
        headers: {
          Authorization: `Bearer ${tokenWorker}`,
          "Content-Type": "application/json",
        },
        params: {
          page,
          limit,
        },
      });

      if (
        data.success === 1 &&
        data.check === "confirmationOfNewConditionsIsRequired"
      ) {
        dispatch(showNewRulesModal());
        dispatch(getReviewsFailure());
        return;
      }

      addMode
        ? dispatch(getMoreReviews(data.data))
        : dispatch(getReviewsSuccess(data.data));
    } catch (error: any) {
      httpErrorHandler(error, dispatch);
      dispatch(getReviewsFailure());
    }
  };
};

export const disableSmsAlert = (id: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(smsAlertStart());

    const {
      auth: { tokenWorker },
    } = getState();

    try {
      await axios.post(
        `${api}/tutor/sms-alerts-disable/`,
        {
          task_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${tokenWorker}`,
          },
        }
      );

      dispatch(smsAlertSuccess());
      dispatch(
        addMessage({
          type: "success",
          message: "SMS-notificación está desactivada",
        })
      );
    } catch (error: any) {
      dispatch(smsAlertFailure(error.response));
      dispatch(
        addMessage({ type: "error", message: "Error de conexión del servidor" })
      );
    }
  };
};

export const enableSmsAlert = (id: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(smsAlertStart());

    const {
      auth: { tokenWorker },
    } = getState();

    try {
      await axios.post(
        `${api}/tutor/sms-alerts-enable/`,
        {
          task_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${tokenWorker}`,
          },
        }
      );

      dispatch(smsAlertSuccess());
      dispatch(
        addMessage({
          type: "success",
          message: "SMS-notificación está activada",
        })
      );
    } catch (error: any) {
      const { errors } = error.response.data;
      dispatch(smsAlertFailure(errors));
      dispatch(
        addMessage({ type: "error", message: "Error de conexión del servidor" })
      );
    }
  };
};
