export const tokenWorker = "AUTH_KEY_TUTOR";
export const tokenTask = "AUTH_KEY_TASK";
export const taskIdLogged = "TASK_ID";
export const formStep1 = "NECESITARIA_STEP_1";
export const formStep2 = "NECESITARIA_STEP_2";
export const ymClientID = "NECESITARIA_YM_CLIENT_ID";

export const defaultOption = {
  label: "No es importante", // 0 - Не важно
  value: "0",
};

// Предпочтительный пол психолога
export const formatSexWorker = [
  defaultOption,
  {
    label: "Hombre", // 1 - Мужчины
    value: "1",
  },
  {
    label: "Mujer", // 2 - Женщины
    value: "2",
  },
];
export const formatEasyAnswer = [
  {
    label: "Si",
    value: "1",
  },
  {
    label: "No",
    value: "0",
  },
];

export const isEntryInterview = [
  {
    label: "Hoy",
    value: "1",
  },
  {
    label: "Próximos días",
    value: "2",
  },
  {
    label: "Próximas semanas",
    value: "3",
  },
  {
    label: "No busco terapia",
    value: "4",
  },
];

export const sexData = [
  {
    name: "No tiene importancia",
    id: "0",
    showValue: "Psicólogos hombres y mujeres",
  },
  { name: "Masculino", id: "1", showValue: "Psicólogos masculinos" },
  { name: "Femenino", id: "2", showValue: "Psicólogos mujeres" },
];

export const formatData = [
  { name: "Онлайн", value: "is_online", id: "0" },
  {
    name: "У репетитора",
    value: "is_tutor",
    id: "1",
  },
  { name: "У меня", value: "is_client", id: "2" },
];

export const monthes = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];

export const statusColorsClient = [
  { id: 1, color: "#888173" },
  { id: 2, color: "#694FD5" },
  { id: 3, color: "#EC903C" },
  { id: 4, color: "#D72AA7" },
  { id: 5, color: "#888173" },
];

export const statusColorsWorker = [
  { id: 1, color: "#694FD5" },
  { id: 2, color: "#EC903C" },
  { id: 3, color: "#D72AA7" },
  { id: 4, color: "#888173" },
];

export const yandexMetric: string | undefined =
  process.env.REACT_APP_YA_METRIKA;
export const googleReCaptcha: string | undefined =
  process.env.REACT_APP_GOOGLE_RECAPTCHA_CODE;
export const mvp2Link = process.env.REACT_APP_MVP2;

export const re = {
  noDigit: /\D+/i,
  digit: /d+/i,
  phone: /\+34[0-9]{9}/i,
  email:
    /^[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/i, // eslint-disable-line
};

export const FILTERS_STORANGE_KEY = "NSP_TASK_FILTERS";
export const REFOUND_POPUP_SHOWN = "NSP_REFOUND_POPUP_SHOWN";
export const REFOUND_BANNER_SHOWN = "NSP_REFOUND_BANNER_SHOWN";
export const INTERVIEW_POPUP_SHOWN = "NSP_INTERVIEW_POPUP_SHOWN";
export const UTM_TAGS = "NSP_UTM_TAGS";
export const STATISTIC_PSYCHOLOGISTS = "NSP_STATISTIC_PSYCHOLOGISTS";
export const LAST_AUTH_ROLE = "NSP_LAST_AUTH_ROLE";
