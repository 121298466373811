import { useEffect } from "react";

const useOutsideClick = (ref: any, avoidRef?: any, handler?: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        ref.current &&
        !ref.current?.contains(event.target) &&
        !avoidRef?.current.contains(event.target)
      ) {
        handler && handler();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handler]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useOutsideClick;
