import { useRef, useLayoutEffect } from "react";
import { FormikErrors, useFormikContext } from "formik";

export interface IFieldChange<FormValues extends Record<string, any>> {
  onChange?: (values: FormValues) => void;
  onError?: (errors: FormikErrors<FormValues>) => void;
  prop?: string;
}

const FieldChange = <T extends Record<string, any>>({
  onChange,
  onError,
  prop,
}: IFieldChange<T>) => {
  const { values, errors } = useFormikContext<T>();

  let err = prop ? errors[prop] : errors;
  let val = prop ? values[prop] : values;

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    err && onError && onError(errors);
    val && onChange && onChange(values);
  }, [err, val]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   err && onError && onError(errors);
  // }, [err]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   val && onChange && onChange(values);
  // }, [val]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default FieldChange;
