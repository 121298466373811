import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { api } from "src/constants/api";
import {
  IInterviewPageFormData,
  IInterviewPopupFormData,
} from "src/types/interview";
import { addMessage } from "./logSlice";
import { AppDispatch, AsyncRequest, RootState } from "./strore";

export interface IInterviewState {
  page: AsyncRequest;
  popup: AsyncRequest;
}

const initialState: IInterviewState = {
  page: {
    error: null,
    isLoaded: false,
    isLoading: false,
  },
  popup: {
    error: null,
    isLoaded: false,
    isLoading: false,
  },
};

export const interviewSlice = createSlice({
  name: "interview",
  initialState,
  reducers: {
    sendInterviewPageStart: (state) => {
      state.page.error = null;
      state.page.isLoaded = false;
      state.page.isLoading = true;
    },
    sendInterviewPageSuccess: (state) => {
      state.page.isLoaded = true;
      state.page.isLoading = false;
    },
    sendInterviewPageFailure: (state, { payload }) => {
      state.page.error = payload;
      state.page.isLoaded = true;
      state.page.isLoading = false;
    },
    clearInterviewPage: (state) => {
      state.page = { ...initialState.page };
    },

    sendInterviewPopupStart: (state) => {
      state.popup.error = null;
      state.popup.isLoaded = false;
      state.popup.isLoading = true;
    },
    sendInterviewPopupSuccess: (state) => {
      state.popup.isLoaded = true;
      state.popup.isLoading = false;
    },
    sendInterviewPopupFailure: (state, { payload }) => {
      state.popup.error = payload;
      state.popup.isLoaded = true;
      state.popup.isLoading = false;
    },
    clearInterviewPopup: (state) => {
      state.popup = { ...initialState.page };
    },
  },
});

export const {
  sendInterviewPageStart,
  sendInterviewPageSuccess,
  sendInterviewPageFailure,
  clearInterviewPage,

  sendInterviewPopupStart,
  sendInterviewPopupSuccess,
  sendInterviewPopupFailure,
  clearInterviewPopup,
} = interviewSlice.actions;

export const interviewPageSelector = (state: RootState) => state.interview.page;
export const interviewPopupSelector = (state: RootState) =>
  state.interview.popup;

export const sendDataInterviewPopup =
  (data: IInterviewPopupFormData) => async (dispatch: AppDispatch) => {
    dispatch(sendInterviewPopupStart());

    try {
      await axios.post(`${api}/interview/`, {
        ...data,
        isEntry: Number(data.isEntry),
      });

      dispatch(sendInterviewPopupSuccess());

      dispatch(
        addMessage({
          type: "success",
          message: `Gracias.`,
        })
      );
    } catch (error: any) {
      dispatch(sendInterviewPopupFailure("Algo ha salido mal."));
      dispatch(
        addMessage({
          type: "error",
          message: `Algo ha salido mal.`,
        })
      );
    }
  };

export const sendDataInterviewPage =
  (data: IInterviewPageFormData) => async (dispatch: AppDispatch) => {
    dispatch(sendInterviewPageStart());

    try {
      await axios.post(`${api}/interview-applications/`, data);

      dispatch(sendInterviewPageSuccess());

      dispatch(
        addMessage({
          type: "success",
          message: `Gracias. Te contactaremos.`,
        })
      );
    } catch (error: any) {
      dispatch(sendInterviewPageFailure("Algo ha salido mal."));
      dispatch(
        addMessage({
          type: "error",
          message: `Algo ha salido mal.`,
        })
      );
    }
  };

export default interviewSlice.reducer;
