import { FC, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { PortalTypes } from "src/Layout/Portal/PortalTypes";

const Portal: FC<PortalTypes> = ({
  children,
  className = "portal",
  el = "div",
}) => {
  const [container] = useState(document.createElement(el));

  if (className) container.classList.add(className);

  useEffect(() => {
    document.body.appendChild(container);

    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  return createPortal(children, container);
};

export default Portal;
