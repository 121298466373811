import React, { useEffect, useState } from "react";
import css from "./Verification.module.scss";
import cn from "classnames";
import { MessageYes } from "src/assets/graphIcons";
import Button from "src/components/UI-kit/Button/Button";
import { useHistory } from "react-router-dom";
import { phoneTransform } from "src/helpers/phoneTransform";
import { useDispatch, useSelector } from "react-redux";
import {
  createTaskConfirmSelector,
  sendCodeData,
  sendRequestCodeData,
  confirmCodeInit,
  createTaskFormSelector,
  hideRefoundPopup,
} from "src/store/createNewTaskSlice";
import { Formik, Form, FormikProps, FormikErrors } from "formik";
import FieldText from "src/components/UI-kit/FieldText/FieldText";
import { getTaskData, createChat, taskSelector } from "src/store/taskSlice";
import { authSelector } from "src/store/authSlice";
import { clientPageInputCodeFormSms } from "src/helpers/metric";
import Preloader from "src/components/Preloader/Preloader";
import { Routes } from "src/constants/routes";
import { sendFormInit } from "src/store/createNewTaskSlice";
import { eventErrorOpen } from "src/store/logSlice";

interface FormVerification {
  id: string;
  phone: string;
  code: string;
}

const UpdateError = ({ error, setError }: any) => {
  useEffect(() => {
    if (!!error) setError("code", "Código incorrecto");
  }, [error]); // eslint-disable-line
  return null;
};

const Verification: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading, isLoaded, error } = useSelector(createTaskConfirmSelector);
  const { isAuthTask } = useSelector(authSelector);
  const {
    register: { phone },
    taskId: id,
    workerId,
  } = useSelector(createTaskFormSelector);
  const {
    channel: {
      isLoaded: createChatLoaded,
      isLoading: createChatLoading,
      error: createChatError,
    },
  } = useSelector(taskSelector);
  const [seconds, setSeconds] = useState(60);
  const [timerActive, setTimerActive] = useState(true);

  useEffect(() => {
    return () => {
      dispatch(sendFormInit());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!id || !workerId) {
      history.push(Routes.client.create.intro);
      return;
    }

    clientPageInputCodeFormSms();
  }, [history, id, workerId]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    if (seconds > 0 && timerActive) {
      timeout = setTimeout(setSeconds, 1000, seconds - 1);
    } else {
      setTimerActive(false);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [seconds, timerActive]);

  useEffect(() => {
    if (isLoaded && error === null) {
      dispatch(confirmCodeInit());
      dispatch(getTaskData());
    }
  }, [isLoaded, error]); // eslint-disable-line

  // Создание чата после подтверждения телефона
  useEffect(() => {
    if (isAuthTask && workerId) {
      dispatch(createChat(Number(workerId)));

      dispatch(hideRefoundPopup());
      // @ts-ignore
      gtag_report_conversion();
    }
  }, [isAuthTask]); // eslint-disable-line

  // Переадресация на страницу профиля после создания чата
  useEffect(() => {
    if (!createChatLoaded) return;

    if (createChatError) {
      dispatch(eventErrorOpen());
      history.push(Routes.client.create.intro);
    } else {
      history.push(`${Routes.client.chat}/${workerId}`);
    }
  }, [createChatLoaded, history, workerId, createChatError, dispatch]);

  const handleSubmit = (data: FormVerification) => {
    dispatch(sendCodeData(data));
  };

  const validate = (values: FormVerification) => {
    const errors: FormikErrors<FormVerification> = {};

    if (!values.code) {
      errors.code = "Código incorrecto";
    }

    return errors;
  };

  if (!id || !workerId || createChatLoading) {
    return <Preloader />;
  }

  return (
    <div className={cn(css.verification, "container", "fullpage")}>
      <div className={css[`verification-icon`]}>
        <MessageYes />
      </div>
      <div className={css[`verification-title`]}>Verifica tu NÚMERO</div>
      <div className={css[`verification-desc`]}>
        Hemos enviado el código al{" "}
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {phoneTransform(phone)}
        </span>
      </div>

      <Formik
        initialValues={{
          id: id.toString(),
          phone,
          code: "",
        }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ dirty, errors, setFieldError }: FormikProps<FormVerification>) => (
          <Form>
            <FieldText
              className={css[`verification-input`]}
              name={"code"}
              type={"text"}
              placeholder={"_ _ _ _"}
              required
            />
            {timerActive ? (
              <div className={css[`verification-hint`]}>
                Puedes solicitar otro código en{" "}
                <span>
                  00:
                  {String(seconds).length === 1 ? `0${seconds}` : seconds}
                </span>
              </div>
            ) : (
              <div
                className={cn(css[`verification-hint`], css.btn)}
                onClick={() => {
                  dispatch(sendRequestCodeData({ id: id.toString() }));
                  setTimerActive(true);
                  setSeconds(60);
                }}
              >
                Enviar otro código
              </div>
            )}
            <UpdateError error={error} setError={setFieldError} />
            <Button
              className={css[`verification-submit`]}
              type={"submit"}
              text={"Verificar"}
              disabled={isLoading || !dirty || !!Object.keys(errors).length}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Verification;
