import { FC } from "react";
import cn from "classnames";
import { checkValues } from "src/helpers/checkValues";
import css from "./Body.module.scss";
import { IBody } from "./types";

import Consultation from "./Blocks/Consultation";
import Spec from "./Blocks/Spec";
import Education from "./Blocks/Education";
import Reviews from "./Blocks/Reviews";
import { About } from "./Blocks/About";

const ProfileBody: FC<IBody> = ({
  about,
  reviews,
  videoYoutubeId,
  consultation,
  spec,
  education,
  languages,
  showMoreComments,
  isLogged,
  className,
}) => {
  return (
    <div className={cn(className, css.body)}>
      <div className={css.body__list}>
        <div id="video" className={cn(css.body__item, css.body__item_noPt)}>
          <About
            about={about}
            videoYoutubeId={videoYoutubeId}
            isLogged={isLogged}
          />
        </div>

        <div id="reviews">
          {!!reviews.reviews.length && (
            <>
              <div className={"divider"} />

              <div className={cn(css.body__item, css.body__item_noPd)}>
                <Reviews {...reviews} showMoreComments={showMoreComments} />
              </div>
            </>
          )}
        </div>

        {consultation && checkValues(consultation) && (
          <>
            <div className={"divider"} />

            <div className={css.body__item}>
              <Consultation {...consultation} languages={languages} />
            </div>
          </>
        )}

        {spec && checkValues(spec) && (
          <>
            <div className={"divider"} />

            <div className={css.body__item}>
              <Spec {...spec} />
            </div>
          </>
        )}

        {education && checkValues(education) && (
          <>
            <div className={"divider"} />

            <div className={css.body__item}>
              <Education {...education} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileBody;
